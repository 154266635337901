import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {isEquals} from "../../../utils";

const sizes = [
    {
        title: 'Any',
        size: null
    },
    {
        title: 'x',
        size: 'x'
    },
    {
        title: 'm',
        size: 'm'
    },
    {
        title: 'xl',
        size: 'xl'
    },
    {
        title: 'xxl',
        size: 'xxl'
    },
];

const WidgetSize = ({selected_size, sizeHandler}) => {
    return (
        <Grid className="widget widgetSize">
            <h2 className="widgetTitle">Filter by Size</h2>
            <List className="widgetList">
                {sizes.map((item, i) => {
                    return <ListItem key={i}
                                     className={isEquals(selected_size, item.size) ? 'active' : ''}
                                     onClick={sizeHandler(item.size)}>
                        <span/>
                        {item.title}
                    </ListItem>
                })}
            </List>
        </Grid>
    )
};

export default WidgetSize;