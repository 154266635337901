import React, {Fragment} from 'react';
import HeaderTop2 from "../../components/HeaderTop2";
import HeaderMiddle from "../../components/HeaderMiddle";
import HeaderBottom from "../../components/HeaderBottom";

import './style.scss';

const Header2 = () => {
    return (
        <Fragment>
            <HeaderTop2  profilename={'David Miller'}/>
            <HeaderMiddle/>
            <HeaderBottom/>
        </Fragment>
    )
};

export default Header2;