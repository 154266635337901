import React, {Fragment, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../UiStyle/FontAwesome";
import Hidden from "@material-ui/core/Hidden";

import banner from '../../images/banner.jpg';

import './style.scss';

const cat_menu = [
    {
        id: '1',
        title: 'clothing',
        mega_menu: {
            men_fashion: {
                id: '11',
                cat_title: 'mens fashion',
                menu_lists: [
                    {
                        id: '111',
                        title: 'westarn wear',
                        link: '/shop'
                    },
                    {
                        id: '112',
                        title: 'sport wear',
                        link: '/shop'
                    },
                    {
                        id: '113',
                        title: 'ethic wear',
                        link: '/shop'
                    },
                    {
                        id: '114',
                        title: 'jeans wear',
                        link: '/shop'
                    }
                ]
            },
            women_fashion: {
                id: '12',
                cat_title: 'womens fashion',
                menu_lists: [
                    {
                        id: '121',
                        title: 'dresses',
                        link: '/shop'
                    },
                    {
                        id: '122',
                        title: 'skirts',
                        link: '/shop'
                    },
                    {
                        id: '123',
                        title: 'westarn wear',
                        link: '/shop'
                    },
                    {
                        id: '124',
                        title: 'sport wear',
                        link: '/shop'
                    },
                    {
                        id: '125',
                        title: 'ethic wear',
                        link: '/shop'
                    },
                    {
                        id: '126',
                        title: 'Watch',
                        link: '/shop'
                    }
                ]
            },
            outWear: {
                id: '13',
                cat_title: 'Outwear',
                menu_lists: [
                    {
                        id: '131',
                        title: 'Watch',
                        link: '/shop'
                    },
                    {
                        id: '132',
                        title: 'Gamepad',
                        link: '/shop'
                    },
                    {
                        id: '133',
                        title: '3d',
                        link: '/shop'
                    },
                    {
                        id: '134',
                        title: 'Projector',
                        link: '/shop'
                    },
                    {
                        id: '135',
                        title: 'Speaker',
                        link: '/shop'
                    }
                ]
            }
        }
    },
    {
        id: '2',
        title: 'Accessories',
        submenu1: [
            {
                id: '21',
                title: 'Laptop',
                link: '/shop',
            },
            {
                id: '22',
                title: 'Mobile',
                link: '/shop',
                submenu2: [
                    {
                        id: '221',
                        title: 'Laptop',
                        link: '/shop',
                    },
                    {
                        id: '222',
                        title: 'Mobile',
                        link: '/shop',
                    },
                    {
                        id: '223',
                        title: 'Headphone',
                        link: '/shop',
                    },
                    {
                        id: '224',
                        title: 'Camera',
                        link: '/shop',
                    },
                    {
                        id: '225',
                        title: 'Samsung',
                        link: '/shop',
                    }
                ]
            },
            {
                id: '23',
                title: 'Headphone',
                link: '/shop',
            },
            {
                id: '24',
                title: 'Camera',
                link: '/shop',
            },
            {
                id: '25',
                title: 'Samsung',
                link: '/shop',
            }
        ]
    },
    {
        id: '3',
        title: 'mobiles, computers',
        submenu1: [
            {
                id: '31',
                title: 'Laptop',
                link: '/shop',
            },
            {
                id: '32',
                title: 'Mobile',
                link: '/shop',
            },
            {
                id: '33',
                title: 'Headphone',
                link: '/shop',
            },
            {
                id: '34',
                title: 'Camera',
                link: '/shop',
            },
            {
                id: '35',
                title: 'Samsung',
                link: '/shop',
            }
        ]
    },
    {
        id: '4',
        title: 'watches',
        link: '/shop'
    },
    {
        id: '5',
        title: 'Home, Kitchen, Pets',
        submenu1: [
            {
                id: '51',
                title: 'Laptop',
                link: '/shop',
            },
            {
                id: '52',
                title: 'Mobile',
                link: '/shop',
            },
            {
                id: '53',
                title: 'Headphone',
                link: '/shop',
            },
            {
                id: '54',
                title: 'Camera',
                link: '/shop',
            },
            {
                id: '55',
                title: 'Samsung',
                link: '/shop',
            }
        ]
    },
    {
        id: '6',
        title: 'Beauty, Health, Grocery',
        link: '/shop'
    },
    {
        id: '7',
        title: 'Sports',
        link: '/shop'
    },
    {
        id: '8',
        title: 'Electronics',
        link: '/shop'
    },
    {
        id: '9',
        title: 'Bags',
        link: '/shop'
    },
    {
        id: '10',
        title: 'Toys, Baby Products',
        link: '/shop'
    },
    {
        id: '11',
        title: 'Luggage',
        link: '/shop'
    }
];

const MegaMenu = () => {
    return (
        <Hidden mdDown>
            <Grid className="megaMenuWrapper">
                <List className="categoryMenu">
                    {cat_menu.map(item => {
                        return (
                            <ListItem key={item.id}>
                                {item.mega_menu
                                    ? <Fragment>
                                        {/*if has mega menu*/}
                                        <p>{item.title}</p>
                                        <FontAwesome name="angle-right"/>
                                        <Grid className="mega_menu">
                                            <Grid container className="mega_menuLinks">
                                                <Grid item sm={6} xs={12}>
                                                    <Grid>
                                                        <h2>{item.mega_menu.men_fashion.cat_title}</h2>
                                                        {item.mega_menu.men_fashion.menu_lists.map(m_item => {
                                                            return (
                                                                <List key={m_item.id}>
                                                                    <ListItem><Link
                                                                        to={m_item.link}>{m_item.title}</Link></ListItem>
                                                                </List>
                                                            )
                                                        })}
                                                    </Grid>
                                                    <Grid className="mt-20">
                                                        <h2>{item.mega_menu.outWear.cat_title}</h2>
                                                        {item.mega_menu.outWear.menu_lists.map(m_item => {
                                                            return (
                                                                <List key={m_item.id}>
                                                                    <ListItem><Link
                                                                        to={m_item.link}>{m_item.title}</Link></ListItem>
                                                                </List>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <h2>{item.mega_menu.women_fashion.cat_title}</h2>
                                                    {item.mega_menu.women_fashion.menu_lists.map(m_item => {
                                                        return (
                                                            <List key={m_item.id}>
                                                                <ListItem><Link
                                                                    to={m_item.link}>{m_item.title}</Link></ListItem>
                                                            </List>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid className="mega_menuBanner">
                                                <img src={banner} alt="banner"/>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                    : item.submenu1
                                        ? <Fragment>
                                            <p>{item.title}</p>
                                            <FontAwesome name="angle-right"/>
                                            <List className="subMenu">
                                                {item.submenu1.map(item => {
                                                    return (
                                                        <ListItem key={item.id}>
                                                            {item.submenu2
                                                                ? <Fragment>
                                                                    <p>{item.title}</p>
                                                                    <FontAwesome name="angle-right"/>
                                                                    <List className="subMenu">
                                                                        {item.submenu2.map(s2_item => {
                                                                            return (
                                                                                <ListItem key={s2_item.id}>
                                                                                    <Link
                                                                                        to={s2_item.link}>{s2_item.title}</Link>
                                                                                </ListItem>
                                                                            )
                                                                        })}
                                                                    </List>
                                                                </Fragment>
                                                                : <Link to={item.link}>{item.title}</Link>}
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>
                                        </Fragment>
                                        : <Link to={item.link}>{item.title}</Link>}
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
        </Hidden>
    )
};

export default MegaMenu;