import React from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import author3 from "../../../../../images/author/author3.png";
import Button from "@material-ui/core/Button";

const AccountDetails = ({onlyReadAble}) => {

    const [forms, setForms] = React.useState({
        fname: 'John',
        lname: 'Doe',
        phone: '0123456789',
        email: 'user@email.com',
        country: 0
    });

    // forms handler
    const changeHandler = e => {
        setForms({...forms, [e.target.name]: e.target.value})
    };
    return (
        <Grid className="accountDetails">
            <Grid container spacing={2}>
                <Grid item lg={3} sm={4} xs={12}>
                    <Grid className="profileThumb">
                        <img src={author3} alt="author3"/>
                    </Grid>
                </Grid>
                <Grid item lg={9} sm={8} xs={12}>
                    <form>
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    name="fname"
                                    value={forms.fname}
                                    onChange={changeHandler}
                                    type="text"
                                    InputProps={{
                                        readOnly: onlyReadAble,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="formInput radiusNone bdbNone"
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    name="lname"
                                    value={forms.lname}
                                    onChange={changeHandler}
                                    type="text"
                                    InputProps={{
                                        readOnly: onlyReadAble,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="formInput radiusNone bdbNone"
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    name="phone"
                                    value={forms.phone}
                                    onChange={changeHandler}
                                    type="text"
                                    InputProps={{
                                        readOnly: onlyReadAble,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="formInput radiusNone bdbNone"
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    value={forms.email}
                                    onChange={changeHandler}
                                    type="text"
                                    InputProps={{
                                        readOnly: onlyReadAble,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="formInput radiusNone bdbNone"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    className="formSelect grayInput">
                                    <Select
                                        inputProps={{readOnly: onlyReadAble}}
                                        disableUnderline
                                        name="country"
                                        value={forms.country}
                                        onChange={changeHandler}
                                    >
                                        <MenuItem className="selectItem" value={0} disabled>Select Country</MenuItem>
                                        <MenuItem className="selectItem" value={10}>United States</MenuItem>
                                        <MenuItem className="selectItem" value={20}>United</MenuItem>
                                        <MenuItem className="selectItem" value={30}>States</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {onlyReadAble ? null :
                                <Grid item xs={12}>
                                    <Grid className="textRight">
                                        <Button className="cBtn cBtnLarge cBtnTransparent">Cancel</Button>
                                        <Button className="cBtn cBtnLarge cBtnBlack">Save Changes</Button>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default AccountDetails;