import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";

import './style.scss';

const SectionTitle = (props) => {

    const {className = '', title, link, linkText} = props;

    return (
        <Grid className={`
            sectionTitle
            ${className}
            ${link ? 'linkRight' : ''}
         `}>
            <h4>{title}</h4>
            {props.children}
            {link ? <Link className="linkRight" to={link}>{linkText}</Link> : ''}
        </Grid>
    )
};

export default SectionTitle;