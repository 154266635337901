import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../UiStyle/FontAwesome";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import './style.scss';

const ShopFilter = (props) => {
    const {indexOfFirstRow, indexOfLastRow, totalNumberOfPrd, rowsPerPage, perPageHandler, gridHandler} = props;
    return (
        <Grid className="shopFilter">
            <Grid container alignItems="center">
                <Grid item md={4} sm={3} xs={5}>
                    <List className="gridViewList">
                        <ListItem onClick={gridHandler(1)}><FontAwesome name="th"/></ListItem>
                        <ListItem onClick={gridHandler(2)}><FontAwesome name="th-large"/><FontAwesome
                            name="th-large"/></ListItem>
                        <ListItem onClick={gridHandler(3)}><FontAwesome name="list"/></ListItem>
                    </List>
                </Grid>
                <Grid item md={4} sm={6} xs={12} className="xs-order-3">
                    <Grid className="showingStatus">
                        <p>Showing Products {indexOfFirstRow + 1} - {indexOfLastRow} Of {totalNumberOfPrd} Result</p>
                    </Grid>
                </Grid>
                <Grid item md={4} sm={3} xs={7}>
                    <Grid className="sortFilter">
                        <FormControl className="formSelect">
                            <Select
                                disableUnderline
                                name="cat"
                                value={rowsPerPage}
                                onChange={(e) => perPageHandler(e)}
                            >
                                <MenuItem className="selectItem" value={9}>Show 9 Items</MenuItem>
                                <MenuItem className="selectItem" value={16}>Show 16 Items</MenuItem>
                                <MenuItem className="selectItem" value={32}>Show 32 Items</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default ShopFilter;