import React, { Fragment, useState } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import {Link} from 'react-router-dom'

import userImg from '../../../images/author/author1.jpg';
// import Header from "../../Header";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../../../components/UiStyle/FontAwesome";
import NotFoundPage from "../../../components/NotFoundPage";
import Collapse from '@material-ui/core/Collapse';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'

import AdminDashboard from './pages/Dashboard';
import VendorManagement from './pages/Vmanagement';
import Brand from './pages/Brand';
import CustomersManage from './pages/Customer';
import AllCategory from './pages/AllCategory';
import CompanyProduct from './pages/CompanyProduct';
import VendorProduct from './pages/VendorProduct';
import ProductReview from './pages/productreview';
import FlashSale from './pages/FlashSale';
import CouponManagement from './pages/coupon';
import OrderManagement from './pages/OrderManagement';
import StockReport from './pages/StockReport';
import SellReport from './pages/SellReport';
import VendorReport from './pages/VendorReport';
import VendorSellReport from './pages/VendorSellReport';
import Newslatter from './pages/Newslatter';
import BlogCategory from './pages/BlogCategory';
import AllBlog from './pages/AllBlog';
import AdminSettings from './pages/AdminSettings';
import WebsiteSetting from './pages/WebsiteSetting';
import ShopSettings from './pages/ShopSetting';
import SeoSetting from './pages/SeoSetting';
import SupportTicket from './pages/SupportTicket';
import AddCustomers from './pages/AddCustomers';
import AddVendor from './pages/AddVendor';
import AddBrand from './pages/AddBrand';
import EditBrand from './pages/EditBrand';
import AddCategory from './pages/AddCategory';
import EditCategory from './pages/EditCategory';
import Addproduct from './pages/Addproduct';
import Editproduct from './pages/Editproduct';
import Viewproduct from './pages/Viewproduct';
import AddFlashSell from './pages/AddFlashSell';
import EditFlashSell from './pages/EditFlashSell';
import AddCoupon from './pages/AddCoupon';
import EditCoupon from './pages/EditCoupon';
import VendorOrder from './pages/VendorOrder';
import ViewOrderDetails from './pages/ViewOrderDetails';
import AddBlogCategory from './pages/AddBlogCategory';
import EditBlogCategory from './pages/EditBlogCategory';
import AddBlog from './pages/AddBlog';
import EditBlog from './pages/EditBlog';
import SupportTicketdeta from './pages/SupportTicketdeta';


import './style.scss'
const menus = [
    {
        id: 1,
        icon: 'tachometer',
        title: 'Dashboard',
        link: 'dashboard'
    }, {
        id: 2,
        icon: 'user',
        title: 'User Management',
        menus: [
            {
                name: 'Customer Mangement',
                link: 'customer',
                id: 15
            },
            {
                name: 'Vendor Mangement',
                link: 'vManagement',
                id: 16
            },
        ],
    }, {
        id: 3,
        icon: 'product-hunt',
        title: 'Product Management',
        menus: [
            {
                name: 'Brands',
                link: 'Brand',
                id: 31
            },
            {
                name: 'Categories',
                link: 'category',
                id: 32
            },
            {
                name: 'Company Products',
                link: 'company',
                id: 33
            },
            {
                name: 'Vendor Products',
                link: 'vendorproduct',
                id: 34
            },
            {
                name: 'Product Reviews',
                link: 'productreview',
                id: 35
            },
        ],
    }, {
        id: 4,
        icon: 'shopping-cart',
        title: 'Flash Sales',
        link: 'flashsale'
    }, {
        id: 5,
        icon: 'heart-o',
        title: 'Coupon Management',
        link: 'couponmanagement'
    }, {
        id: 6,
        icon: 'user',
        title: 'Order Management',
        link: 'ordermanagement'
    }, {
        id: 7,
        icon: 'file-text-o',
        title: 'Reports',
        menus: [
            {
                name: 'Stock Report',
                link: 'stockreport',
                id: 71
            },
            {
                name: 'Company Sale Report',
                link: 'sellreport',
                id: 72
            },
            {
                name: 'Vendor Report',
                link: 'vendorreport',
                id: 73
            },
            {
                name: 'Vendor Based Sale Report',
                link: 'vendorsellreport',
                id: 74
            },
        ],
    }, {
        id: 8,
        icon: 'envelope',
        title: 'Messaging',
        menus: [
            {
                name: 'Send Newsletter',
                link: 'newslatter',
                id: 81
            },
        ],
    },{
        id: 9,
        icon: 'newspaper-o',
        title: 'Blog Management',
        menus: [
            {
                name: 'Blog Categories',
                link: 'blogcategory',
                id: 91
            },
            {
                name: 'Blogs',
                link: 'allblog',
                id: 91
            },
        ],
    },
    {
        id: 10,
        icon: 'cogs',
        title: 'Settings',
        menus: [
            {
                name: 'Admin Settings',
                link: 'adminsettings',
                id: 11
            },
            {
                name: 'Website Settings',
                link: 'websitesettings',
                id: 12
            },{
                name: 'Shop Settings',
                link: 'shopsetting',
                id: 12
            },{
                name: 'SEO Settings',
                link: 'seosetting',
                id: 12
            },
        ],
    },
     {
        id: 322,
        icon: 'ticket',
        title: 'Support Ticket',
        link: 'supportticket'
    },  
];

const AdminLayout = (props) => {
    const { match } = props;

    const [menuId, setMenuId] = useState(0);
    const [isCollapse, setIsCollapse] = useState(false);
    
    return (
        <Fragment>
            {/* <Header /> */}
            <Grid className="userPanel adminPanel">
                <Grid className="container-fluid" container spacing={3}>
                    <Grid item lg={2} md={4} xs={12}>
                         <Grid className={`leftSidebar ${isCollapse ? 'show' : ''}`}>
                         <PerfectScrollbar className='SidebarWrap'>
                            <Grid >
                                    <Grid className="cloxBtn" onClick={() => setIsCollapse(!isCollapse)}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </Grid>
                                    <Grid className="profile">
                                        <Grid className="thumb">
                                            <img src={userImg} alt="userImg" />
                                        </Grid>
                                        <h2>Mr. Admin</h2>
                                    </Grid>
                                    <List className="dashboardSideMMenu">
                                        {menus.map(item => (
                                            <ListItem key={item.id}>
                                                {item.menus ? <Fragment>
                                                    <p onClick={() => setMenuId(item.id === menuId ? 0 : item.id)}><FontAwesome name={item.icon} />
                                                        {item.title}</p>
                                                    <Collapse in={menuId === item.id} timeout="auto" unmountOnExit>
                                                        <List>
                                                            {item.menus.map(submenu => (
                                                                <ListItem><NavLink exact activeClassName=""
                                                                    to={`${match.path}/${submenu.link}`}>{submenu.name}</NavLink></ListItem>
                                                            ))}
                                                        </List>
                                                    </Collapse>
                                                </Fragment>
                                                    : <NavLink exact activeClassName="active"
                                                        to={`${match.path}/${item.link}`}><FontAwesome
                                                            name={item.icon} />{item.title}</NavLink>}
                                            </ListItem>
                                        ))}

                                        <ListItem> <Link to='/login'><i className="fa fa-sign-out" aria-hidden="true"></i>Log Out</Link> </ListItem>
                                    </List>
                                </Grid>
                        </PerfectScrollbar>
                    </Grid>
                        
                    </Grid>
                    <Grid item lg={10} md={8} xs={12}>
                        <Grid className="mainBody">
                            <Grid className="toggleBtn" onClick={() => setIsCollapse(!isCollapse)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </Grid>
                            <Switch>
                                <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/dashboard`} component={AdminDashboard} />
                                <Route exact path={`${match.path}/customer`} component={CustomersManage} />
                                <Route exact path={`${match.path}/vManagement`} component={VendorManagement} />
                                <Route exact path={`${match.path}/Brand`} component={Brand} />
                                <Route exact path={`${match.path}/category`} component={AllCategory} />
                                <Route exact path={`${match.path}/company`} component={CompanyProduct} />
                                <Route exact path={`${match.path}/vendorproduct`} component={VendorProduct} />
                                <Route exact path={`${match.path}/productreview`} component={ProductReview} />
                                <Route exact path={`${match.path}/couponmanagement`} component={CouponManagement} />
                                <Route exact path={`${match.path}/flashsale`} component={FlashSale} />
                                <Route exact path={`${match.path}/ordermanagement`} component={OrderManagement} />
                                <Route exact path={`${match.path}/stockreport`} component={StockReport} />
                                <Route exact path={`${match.path}/sellreport`} component={SellReport} />
                                <Route exact path={`${match.path}/vendorreport`} component={VendorReport} />
                                <Route exact path={`${match.path}/vendorsellreport`} component={VendorSellReport} />
                                <Route exact path={`${match.path}/newslatter`} component={Newslatter} />
                                <Route exact path={`${match.path}/blogcategory`} component={BlogCategory} />
                                <Route exact path={`${match.path}/allblog`} component={AllBlog} />
                                <Route exact path={`${match.path}/adminsettings`} component={AdminSettings} />
                                <Route exact path={`${match.path}/websitesettings`} component={WebsiteSetting} />
                                <Route exact path={`${match.path}/shopsetting`} component={ShopSettings} />
                                <Route exact path={`${match.path}/seosetting`} component={SeoSetting} />
                                <Route exact path={`${match.path}/supportticket`} component={SupportTicket} />
                                <Route exact path={`${match.path}/addcustomer`} component={AddCustomers} />
                                <Route exact path={`${match.path}/addvendor`} component={AddVendor} />
                                <Route exact path={`${match.path}/addbrand`} component={AddBrand} />
                                <Route exact path={`${match.path}/editbrand`} component={EditBrand} />
                                <Route exact path={`${match.path}/addcategory`} component={AddCategory} />
                                <Route exact path={`${match.path}/editcategory`} component={EditCategory} />
                                <Route exact path={`${match.path}/addproduct`} component={Addproduct} />
                                <Route exact path={`${match.path}/editproduct`} component={Editproduct} />
                                <Route exact path={`${match.path}/viewproduct`} component={Viewproduct} />
                                <Route exact path={`${match.path}/addflash`} component={AddFlashSell} />
                                <Route exact path={`${match.path}/editflash`} component={EditFlashSell} />
                                <Route exact path={`${match.path}/addcoupon`} component={AddCoupon} />
                                <Route exact path={`${match.path}/editcoupon`} component={EditCoupon} />
                                <Route exact path={`${match.path}/vendororder`} component={VendorOrder} />
                                <Route exact path={`${match.path}/viewvendororder`} component={ViewOrderDetails} />
                                <Route exact path={`${match.path}/addblogcategory`} component={AddBlogCategory} />
                                <Route exact path={`${match.path}/editblogcategory`} component={EditBlogCategory} />
                                <Route exact path={`${match.path}/addBlog`} component={AddBlog} />
                                <Route exact path={`${match.path}/editBlog`} component={EditBlog} />
                                <Route exact path={`${match.path}/Supportticketdetails`} component={SupportTicketdeta} />
                                <Route exact component={NotFoundPage} />
                            </Switch>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Footer /> */}
        </Fragment>
    )
};

export default AdminLayout;