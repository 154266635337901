import React, {useState} from 'react';
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import SectionTitle from "../SectionTitle";
import ProductItem from "../ProductItem";
import {getFeaturedProducts} from "../../utils";
import Fade from 'react-reveal/Fade';
import Button from "@material-ui/core/Button";

import './style.scss';

const FeaturedItem = ({featuredProducts, symbol}) => {
    const [totalItems, setTotalItems] = useState(4);

    return (
        <Grid className="featuredProduct ptb-30">
            <Grid container className="container" spacing={3}>
                <Grid item xs={12}>
                    <SectionTitle
                        title="Featured Items"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {featuredProducts.slice(0, totalItems).map(item => {
                            return (
                                <Grid key={item.id} item md={3} sm={6} xs={12}>
                                    <Fade big>
                                        <ProductItem symbol={symbol} item={item}/>
                                    </Fade>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid className="shopMore">
                        {totalItems >= 12 ? '' :
                            <Button className="cBtn cBtnTheme cBtnRadius" onClick={() => setTotalItems(totalItems + 4)} color="primary">Shop More</Button>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => {
    return {
        featuredProducts: getFeaturedProducts(state.data.products),
        symbol: state.data.symbol
    }
};

export default connect(mapStateToProps)(FeaturedItem);