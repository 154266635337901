import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

const reviews = [
    {
        id: 1,
        code: 'Hallmark',
        title: '$ 13.4',
        rating: '	$ 0.92',
        comment: '	$ 12.48',
    }, {
        id: 2,
        code: 'BestBuy',
        title: '$ 13.4',
        rating: '	$ 0.92',
        comment: '	$ 12.48',
    }, {
        id: 3,
        code: 'Green Shop',
        title: '$ 13.4',
        rating: '	$ 0.92',
        comment: '	$ 12.48',
    }, {
        id: 4,
        code: 'BestMart',
        title: '$ 13.4',
        rating: '	$ 0.92',
        comment: '	$ 12.48',
    }, {
        id: 5,
        code: 'Bunny Shop',
        title: '$ 13.4',
        rating: '	$ 0.92',
        comment: '	$ 12.48',
    },
];

const CurrentBlance = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | My Reviews</title>
            </Helmet>
            <Grid className="cardWrap">
                <Grid className="cardTitle">
                    <h4 className="title">Current Balance Record</h4>
                </Grid>
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Shop Name</TableCell>
                                <TableCell>Total Income</TableCell>
                                <TableCell>Got Amount</TableCell>
                                <TableCell align="right">Due Amount To Admin</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reviews.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell><strong>{item.code}</strong></TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.rating}</TableCell>
                                    <TableCell align="right">{item.comment}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default CurrentBlance;