import React from 'react';
import {connect} from "react-redux";
import SectionTitle from "../SectionTitle";
import Grid from "@material-ui/core/Grid";
import CountDown from 'reactjs-countdown';
import Button from "@material-ui/core/Button";
import Slider from "react-slick";
import {getFlashProducts} from "../../utils";
import FontAwesome from "../UiStyle/FontAwesome";
import ProductItem from "../ProductItem";

import './style.scss';

function SampleNextArrow(props) {
    const {onClick} = props;
    return (
        <Button onClick={onClick} className="slick-arrow slick-next">
            <FontAwesome name="angle-right"/>
        </Button>
    );
}

function SamplePrevArrow(props) {
    const {onClick} = props;
    return (
        <Button onClick={onClick} className="slick-arrow slick-prev">
            <FontAwesome name="angle-left"/>
        </Button>
    );
}

const FlashSale = ({flash_products, symbol}) => {
    const settings = {
        dots: true,
        speed: 500,
        swipeToSlide: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <Grid className="flashSaleWrapper pt-60 pb-60">
            <Grid className="container" container spacing={3}>
                <Grid item xs={12}>
                    <SectionTitle
                        className="flashSaleHeader"
                        title="Flash Sale"
                    >
                        <Grid className="flashHeaderContent">
                            <h5 className="flashTitle">Offer Ends in</h5>
                            <CountDown
                                end="August 22, 2022"
                            />
                        </Grid>
                    </SectionTitle>
                </Grid>

                <Grid item xs={12}>
                    <Slider className="flashSlider" {...settings}>
                        {flash_products.map(item => {
                            return (
                                <Grid key={item.id} className="flashSliderItem">
                                    <ProductItem symbol={symbol} item={item}/>
                                </Grid>
                            )
                        })}
                    </Slider>
                </Grid>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => {
    return {
        flash_products: getFlashProducts(state.data.products),
        symbol: state.data.symbol
    }
};

export default connect(mapStateToProps)(FlashSale);