import React from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const BillingAddress = () => {
    const [forms, setForms] = React.useState({
        f_name: '',
        l_name: '',
        phone: '',
        email: '',
        country: 0,
        district: 0,
        address: '',
        post_code: '',
    });

    // forms handler
    const changeHandler = e => {
        setForms({...forms, [e.target.name]: e.target.value})
    };

    const submitHandler = (e) => {
        e.preventDefault();
        toast.success('Form Submitted!')
    };

    return (
        <Grid className="changePass">
            <form onSubmit={submitHandler}>
                <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                        <TextField
                            fullWidth
                            label="First Name"
                            placeholder="First Name"
                            name="f_name"
                            value={forms.f_name}
                            onChange={changeHandler}
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="formInput radiusNone bdbNone mb-20"
                        />
                        <TextField
                            fullWidth
                            label="Last Name"
                            placeholder="Last Name"
                            name="l_name"
                            value={forms.l_name}
                            onChange={changeHandler}
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="formInput radiusNone bdbNone mb-20"
                        />
                        <TextField
                            fullWidth
                            label="Phone"
                            placeholder="Phone"
                            name="phone"
                            value={forms.phone}
                            onChange={changeHandler}
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="formInput radiusNone bdbNone mb-20"
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            placeholder="Email"
                            name="email"
                            value={forms.email}
                            onChange={changeHandler}
                            type="email"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="formInput radiusNone bdbNone mb-20"
                        />
                        <FormControl
                            fullWidth
                            className="formSelect grayInput mb-20">
                            <Select
                                disableUnderline
                                name="country"
                                value={forms.country}
                                onChange={changeHandler}
                            >
                                <MenuItem className="selectItem" value={0} disabled>Select County</MenuItem>
                                <MenuItem className="selectItem" value={10}>United States</MenuItem>
                                <MenuItem className="selectItem" value={20}>United</MenuItem>
                                <MenuItem className="selectItem" value={30}>States</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl
                            fullWidth
                            className="formSelect grayInput mb-20">
                            <Select
                                disableUnderline
                                name="district"
                                value={forms.country}
                                onChange={changeHandler}
                            >
                                <MenuItem className="selectItem" value={0} disabled>Select District</MenuItem>
                                <MenuItem className="selectItem" value={10}>United States</MenuItem>
                                <MenuItem className="selectItem" value={20}>United</MenuItem>
                                <MenuItem className="selectItem" value={30}>States</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Address"
                            placeholder="Address"
                            name="address"
                            multiline
                            rows={4}
                            value={forms.address}
                            onChange={changeHandler}
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="formInput radiusNone bdbNone mb-20"
                        />
                        <TextField
                            fullWidth
                            label="Postal Code"
                            placeholder="Postal Code"
                            name="post_code"
                            value={forms.post_code}
                            onChange={changeHandler}
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="formInput radiusNone bdbNone mb-20"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" className="cBtn cBtnLarge cBtnBlack">Submit</Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
};

export default BillingAddress;