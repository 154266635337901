import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {isEquals} from "../../../utils";

const categories = [
    {
        title: 'All',
        category: null,
    },
    {
        title: 'Men',
        category: 'Men',
    },
    {
        title: 'Accessories',
        category: 'Accessories',
    },
    {
        title: 'women',
        category: 'women',
    },
    {
        title: 'watches',
        category: 'watches',
    },
    {
        title: 'Home, Kitchen, Pets',
        category: 'Home, Kitchen, Pets',
    },
    {
        title: 'Beauty, Health, Grocery',
        category: 'Beauty, Health, Grocery',
    },
    {
        title: 'Sports',
        category: 'Sports',
    },
    {
        title: 'Electronics',
        category: 'Electronics',
    },
    {
        title: 'Bags',
        category: 'Bags',
    },
    {
        title: 'Toys, Baby Products',
        category: 'Toys, Baby Products',
    },
    {
        title: 'Luggage',
        category: 'Luggage',
    }
];

const WidgetCategory = ({selected_category, categoryHandler}) => {

    return (
        <Grid className="widget widgetCategory">
            <h2 className="widgetTitle">Categories</h2>
            <List className="widgetList">
                {categories.map((item, i) => {
                    return <ListItem key={i}
                                     className={isEquals(selected_category, item.category) ? 'active' : ''}
                                     onClick={categoryHandler(item.category)}>
                        <span/>
                        {item.title}
                    </ListItem>
                })}
            </List>
        </Grid>
    )
};

export default WidgetCategory;