import React from 'react';
import {Route, Switch} from 'react-router-dom';

// Route Setting
import HomePage from "../HomePage";
import HomePage2 from "../HomePage2";
import ShopPage from "../ShopPage";
import WishList from "../WishList";
import CartPage from "../CartPage";
import CheckoutPage from "../CheckoutPage";
import ComparePage from "../ComparePage";
import AboutUs from "../AboutUs";
import CookiesPolicy from "../CookiesPolicy";
import PrivacyPolicy from "../PrivacyPolicy";
import TermsConditions from "../TermsConditions";
import SingleProduct from "../SingleProduct";
import NotFoundPage from "../../components/NotFoundPage";
import FaqPage from "../FaqPage";
import ContactPage from "../ContactPage";
import ShopRightSidebar from '../ShopRightSidebar';
import ShopNoSidebar from '../ShopNoSidebar';
import BlogPage from "../BlogPage";
import BlogLeftSidebar from "../BlogLeftSidebar";
import BlogNoSidebar from "../BlogNoSidebar";
import BlogDetails from "../BlogDetails";
import BlogDetailsLeftSideBar from "../BlogDetailsLeftSideBar";
import BlogDetailsNoSidebar from "../BlogDetailsNoSidebar";
import SignUpPage from "../SignUpPage";
import ForgotPassword from "../ForgotPassword";
import PurcessHistory from "../PurcessHistory";
import ProcedCheckout from "../ProcedCheckout";
import SupportTicketDetails from "../SupportTicketDetails";
import WithdrawDetails from "../WithdrawDetails";
import BankDetails from "../BankDetails";
import AddBank from "../AddBank";
import EditBank from "../EditBank";

// Normal Route
import PrivateRoute from "../_PrivateRoute";
import PublicRoute from "../_PublicRoute";
import LoginPage from "../LoginPage";

// user route
import AccountLayout from "../Account/User/AccountLayout";
import VendorLayout from "../Account/Vendor/VendorLayout";
import AdminLayout from "../Account/Admin/AdminLayout";

export default function Routes(props) {
    return (
        <Switch>
            <PrivateRoute
                path="/"
                exact
                component={HomePage}
            />
            <PrivateRoute
                path="/home"
                exact
                component={HomePage}
            />
            <PrivateRoute
                path="/home2"
                exact
                component={HomePage2}
            />
            <PrivateRoute
                exact
                path="/shop"
                title="Shop"
                bcrumb={true}
                component={ShopPage}
            />
            <PrivateRoute
                exact
                path="/shop-rightsidebar"
                title="Right Sidebar"
                bcrumb={true}
                component={ShopRightSidebar}
            />
            <PrivateRoute
                exact
                path="/shop-nosidebar"
                title="No Sidebar"
                bcrumb={true}
                component={ShopNoSidebar}
            />
            <PrivateRoute
                exact
                path="/wishlist"
                title="WishList"
                bcrumb={true}
                component={WishList}
            />
            <PrivateRoute
                exact
                path="/cart"
                title="Cart"
                bcrumb={true}
                component={CartPage}
            />
            <PrivateRoute
                exact
                path="/product/:id"
                title="Single Product"
                bcrumb={true}
                component={SingleProduct}
            />
            <PrivateRoute
                exact
                path="/checkout"
                title="Checkout"
                bcrumb={true}
                component={CheckoutPage}
            />
            <PrivateRoute
                exact
                path="/compare"
                title="Compare"
                bcrumb={true}
                component={ComparePage}
            />
            <PrivateRoute
                exact
                path="/Cookie"
                title="Cookies Policy"
                bcrumb={true}
                component={CookiesPolicy}
            />
            <PrivateRoute
                exact
                path="/privacypolicy"
                title="Privacy Policy"
                bcrumb={true}
                component={PrivacyPolicy}
            />
            <PrivateRoute
                exact
                path="/TermsConditions"
                title="Terms and Conditions"
                bcrumb={true}
                component={TermsConditions}
            />
            <PrivateRoute
                exact
                path="/about-us"
                title="About Us"
                bcrumb={true}
                component={AboutUs}
            />
            <PublicRoute
                exact
                path="/purcess"
                bcrumb={true}
                component={PurcessHistory}
            />
            <PublicRoute
                exact
                path="/order_received"
                bcrumb={true}
                component={ProcedCheckout}
            />
            <PublicRoute
                exact
                path="/Supports"
                bcrumb={true}
                component={SupportTicketDetails}
            />
            <PublicRoute
                exact
                path="/Withdrawdetails"
                bcrumb={true}
                component={WithdrawDetails}
            />
            <PublicRoute
                exact
                path="/bankdetails"
                bcrumb={true}
                component={BankDetails}
            />
            <PublicRoute
                exact
                path="/addbank"
                bcrumb={true}
                component={AddBank}
            />
            <PublicRoute
                exact
                path="/editbank"
                bcrumb={true}
                component={EditBank}
            />
            <PrivateRoute
                exact
                path="/faq"
                title="Faq"
                bcrumb={true}
                component={FaqPage}
            />
            <PrivateRoute
                exact
                path="/contact"
                title="Contact"
                bcrumb={true}
                component={ContactPage}
            />
            <PrivateRoute
                path="/blog"
                title="Blog"
                bcrumb={true}
                component={BlogPage}
            />
            <PrivateRoute
                exact
                path="/blog-leftsidebar"
                title="Left Sidebar"
                bcrumb={true}
                component={BlogLeftSidebar}
            />
            <PrivateRoute
                exact
                path="/blog-nosidebar"
                title="No Sidebar"
                bcrumb={true}
                component={BlogNoSidebar}
            />
            <PrivateRoute
                exact
                path="/blog-details/:id"
                title="Blog Details"
                bcrumb={true}
                component={BlogDetails}
            />
            <PrivateRoute
                exact
                path="/blog-details-leftsidebar/:id"
                title="Blog Details Left Sidebar"
                bcrumb={true}
                component={BlogDetailsLeftSideBar}
            />
            <PrivateRoute
                exact
                path="/blog-details-nosidebar/:id"
                title="Blog Details No Sidebar"
                bcrumb={true}
                component={BlogDetailsNoSidebar}
            />
            <PublicRoute
                exact
                path="/login"
                component={LoginPage}
            />
            <PublicRoute
                exact
                path="/signup"
                component={SignUpPage}
            />
            <PublicRoute
                exact
                path="/forgot-password"
                component={ForgotPassword}
            />
            

            {/*user route*/}
            <PublicRoute
                path="/account"
                component={AccountLayout}
            />
            {/*vendor route*/}
            <PublicRoute
                path="/vendor"
                component={VendorLayout}
            />
            {/*admin route*/}
            <PublicRoute
                path="/admin"
                component={AdminLayout}
            />

            {/*should be at last*/}
            <Route
                exact
                component={NotFoundPage}
            />
        </Switch>
    );
}
