// images
import blogImg1 from "../images/blog/blog-img1.jpg";
import blogImg2 from "../images/blog/blog-img2.jpg";
import blogImg3 from "../images/blog/blog-img3.jpg";
import blogImg4 from "../images/blog/blog-img4.jpg";
import blogImg5 from "../images/blog/blog-img5.jpg";
import blogImg6 from "../images/blog/blog-img6.jpg";
import blogImg7 from "../images/blog/blog-img7.jpg";
import blogImg8 from "../images/blog/blog-img8.jpg";
import blogImg9 from "../images/blog/blog-img9.jpg";
import blogImg10 from "../images/blog/blog-img10.jpg";
import blogImg11 from "../images/blog/blog-img11.jpg";

const blogs = [
    {
        id: 1,
        title: 'are many variations Lorem',
        screens: blogImg1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        id: 2,
        title: 'Tre are many variations oem',
        screens: blogImg2,
        description: 'orem ipsum dolor sit amet, consectetur adipisicim libero molestias odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        id: 3,
        title: 'Tare many variations oforem',
        screens: blogImg3,
        description: 'ipsum dolor sit amet, consectetur adictetur errore iure, laborum libero  odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    },
    {
        id: 4,
        title: 'Lorem many variations Lorem',
        screens: blogImg4,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        id: 5,
        title: 'There are many variations oem',
        screens: blogImg5,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicim libero molestias odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        id: 6,
        title: 'Lookin are many variations oforem',
        screens: blogImg6,
        description: 'Lorem ipsum dolor sit amet, consectetur adictetur errore iure, laborum libero  odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        id: 7,
        title: 'Bemon are many variations oforem',
        screens: blogImg7,
        description: 'Lorem ipsum dolor sit amet, consectetur adictetur errore iure, laborum libero  odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        id: 8,
        title: 'Jukac are many variations oforem',
        screens: blogImg8,
        description: 'Lorem ipsum dolor sit amet, consectetur adictetur errore iure, laborum libero  odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        id: 9,
        title: 'Tkia are many variations oforem',
        screens: blogImg9,
        description: 'Lorem ipsum dolor sit amet, consectetur adictetur errore iure, laborum libero  odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        id: 10,
        title: 'Montaj are many variations oforem',
        screens: blogImg10,
        description: 'Lorem ipsum dolor sit amet, consectetur adictetur errore iure, laborum libero  odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        id: 11,
        title: 'Hanom are many variations oforem',
        screens: blogImg11,
        description: 'Lorem ipsum dolor sit amet, consectetur adictetur errore iure, laborum libero  odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }
];
export default blogs;