import React, { Fragment } from 'react';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import { addToCart, removeFromCompareList } from "../../store/actions/action";
import { getCompareList } from "../../utils";

import './style.scss';
import { Helmet } from "react-helmet";

const ComparePage = ({ compareList, removeFromCompareList, symbol, addToCart }) => {

    let ratingBack = [];
    let i = 0;

    for (i = 0; i < 5; i++) {
        ratingBack.push(<li key={i}><i className="fa fa-star-o" /></li>)
    }

    function frontRatings(item) {
        const Li = [];
        for (i = 0; i < item.rating; i++) {
            Li.push(<li key={i}><i className="fa fa-star" /></li>)
        }
        return Li;
    }

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - Compare Products</title>
            </Helmet>
            <Grid className="compareWrapper ptb-60">
                <Grid className="container" container spacing={3}>
                    <Grid item xs={12}>
                        {compareList.length > 0 ?
                            <Table className="compareTable">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th">Image</TableCell>
                                        {compareList.map(item => (
                                            <TableCell key={item.id}>
                                                <Grid className="cmpProdThumb">
                                                    <img src={item.pictures[0]} alt="product thumb" />
                                                    <Button onClick={() => removeFromCompareList(item)}><FontAwesome
                                                        name="times" /></Button>
                                                </Grid>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">Name</TableCell>
                                        {compareList.map(item => (
                                            <TableCell key={item.id}>
                                                <h5><Link to={`/product/${item.id}`}>{item.name}</Link></h5>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">Price</TableCell>
                                        {compareList.map(item => (
                                            <TableCell key={item.id}>
                                                <h5>{symbol}{item.price}</h5>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">Category</TableCell>
                                        {compareList.map(item => (
                                            <TableCell key={item.id}>
                                                <p>{item.category}</p>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">Description</TableCell>
                                        {compareList.map(item => (
                                            <TableCell key={item.id}>
                                                <p>{item.shortDetails}</p>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">Ratings</TableCell>
                                        {compareList.map(item => (
                                            <TableCell key={item.id}>
                                                <Grid className="pro_rating pureRating">
                                                    <List className="ratingFront">
                                                        {frontRatings(item)}
                                                    </List>
                                                    <List className="ratingBack">
                                                        {ratingBack}
                                                    </List>
                                                </Grid>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">Size</TableCell>
                                        {compareList.map(item => (
                                            <TableCell key={item.id}>
                                                <p>{item.size.map(size => `${size} ${' '}`)}</p>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">Color</TableCell>
                                        {compareList.map(item => (
                                            <TableCell key={item.id}>
                                                <p>{item.colors.map(color => `${color} ${' '}`)}</p>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">Stock Status</TableCell>
                                        {compareList.map(item => (
                                            <TableCell key={item.id}>
                                                <p className="stock">{item.stock > 0
                                                    ? <>In Stock<strong> ({item.stock})</strong></>
                                                    : 'Out of Stock'}</p>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">Action</TableCell>
                                        {compareList.map(item => (
                                            <TableCell key={item.id}>
                                                <Button className="cBtn cBtnSmall cBtnBlack cBtnRadius"
                                                    onClick={() => addToCart(item, 1, item.colors[0], item.size[0])}>Add
                                                    to Cart</Button>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                            : <p className="noProdFound">Nothing to compare</p>}
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        compareList: getCompareList(state.compareList.compare_list),
        symbol: state.data.symbol
    }
};

export default connect(mapStateToProps, { removeFromCompareList, addToCart })(ComparePage);