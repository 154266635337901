import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import './style.scss';

import PurcessHistory from '../../../PurcessHistory'




const purchaseHistoryDetails = (props) => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | User Purcess History </title>
            </Helmet>
            <PurcessHistory />
        </Fragment>
    )
};

export default purchaseHistoryDetails;