import React from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";

const AddCustomers = () => {
    const [forms, setForms] = React.useState({
        firstname: '',
        Lastname: '',
        email: '',
        phone: '',
        Password: '',
        confirm_Password: '',
    });

    // forms handler
    const changeHandler = e => {
        setForms({...forms, [e.target.name]: e.target.value})
    };

    const submitHandler = (e) => {
        e.preventDefault();
        toast.success('Form Submitted!')
    };

    return (
        <Grid className="DataTableArea cardWrap">
            <Grid className="DtableHeaderArea">
                <h2 className="DtableHeader">Add Customer</h2>
            </Grid>
            <Grid className="changePass">
                <form onSubmit={submitHandler}>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="First Name"
                                name="firstname"
                                value={forms.firstname}
                                onChange={changeHandler}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                name="Lastname"
                                value={forms.Lastname}
                                onChange={changeHandler}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={forms.email}
                                onChange={changeHandler}
                                type="email"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Phone"
                                name="phone"
                                value={forms.phone}
                                onChange={changeHandler}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Password"
                                name="password"
                                value={forms.password}
                                onChange={changeHandler}
                                type="password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone"
                            />
                        </Grid> 
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Confirm Password"
                                name="confirm_password"
                                value={forms.confirm_password}
                                onChange={changeHandler}
                                type="password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone"
                            />
                        </Grid> 

                        <Grid item xs={12}>
                            <Button type="submit" className="cBtn cBtnLarge cBtnBlack">Add Customer</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
};

export default AddCustomers;