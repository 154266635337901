import React from 'react';
import {Grid} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import FontAwesome from "../../components/UiStyle/FontAwesome";

import blogs from '../../api/blogs';

import innerImg from '../../images/blog/blog-img4.jpg';

import './style.scss';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Comments from "./component/comments";
import CommentForm from "./component/commentForm";

const BlogDetails = (props) => {
    const id = props.match.params.id - 1;
    return (
        <Grid className="blogPage ptb-60">
            <Grid className="container" container spacing={3}>
                <Grid item md={9} xs={12}>
                    <Grid className="blogDetails">
                        <Grid className="thumb">
                            <img src={blogs[id].screens} alt="blogImg1"/>
                        </Grid>
                        <Grid className="content">
                            <h2>{blogs[id].title}</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur commodi consectetur
                                dignissimos distinctio, dolore fugiat libero natus, non obcaecati omnis, rerum unde
                                veniam voluptates! Itaque officia sunt tempora veniam. Corporis culpa ea eius esse hic
                                impedit itaque nobis optio rerum, saepe? Accusamus, alias autem commodi cumque deserunt
                                dolorem dolores eligendi expedita explicabo fugit illum ipsum itaque, laborum numquam
                                praesentium quibusdam quidem rerum saepe similique sunt temporibus veniam voluptates.
                                Aliquid consectetur culpa doloribus eos illum, ipsam! Aliquid amet architecto, autem
                                cumque delectus doloribus esse eveniet, exercitationem id iusto minus mollitia nobis
                                perferendis ratione reiciendis unde, voluptatem! Ad alias animi beatae corporis cum
                                dignissimos dolorem doloremque eaque eos, eveniet illum ipsa nihil odit perspiciatis
                                quia quibusdam quo rerum temporibus veniam voluptatibus? Consectetur cumque distinctio
                                fugiat nobis perspiciatis, quaerat quo ratione. Aperiam assumenda aut, consequuntur
                                culpa deleniti dignissimos eius, fugit id iure necessitatibus nihil, nisi non officia
                                optio ratione repudiandae sunt temporibus voluptate.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab doloremque iure nulla ullam
                                vel vero voluptatum. Aliquam beatae earum laudantium possimus ut? Aliquid aperiam
                                cupiditate, deserunt dolorum eveniet ex expedita in magnam minima omnis placeat quos
                                reiciendis rem velit vitae. Ab eaque reiciendis sint. A adipisci aliquid beatae
                                blanditiis distinctio dolore dolorem eos fuga, inventore maiores, nobis porro quia
                                rerum!</p>
                            <ul className="list">
                                <li>Ever since the 1500s, when an unknown</li>
                                <li>Remaining essentially unchanged.</li>
                                <li>Ipsum has been the industry</li>
                                <li>It was popularised in the 1960s with</li>
                                <li>Printer took a galley of type and scrambled</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aliquid consequuntur
                                debitis, delectus deleniti dignissimos dolores est fugiat hic impedit itaque nam,
                                necessitatibus nostrum numquam obcaecati officiis omnis pariatur qui quis tempore
                                temporibus veritatis voluptatem. At autem consequatur consequuntur delectus deleniti
                                exercitationem fuga fugiat fugit hic, incidunt ipsam laboriosam laudantium libero minima
                                nemo nesciunt quas repellendus rerum, sed, voluptas voluptates.</p>
                            <img className="inner_image" src={innerImg} alt="imgg"/>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores aut consequatur
                                earum exercitationem nemo nihil suscipit vitae voluptates? Accusamus adipisci
                                assumenda
                                dicta dolor earum, eos est excepturi maiores maxime natus, nemo possimus provident
                                quisquam quod reiciendis reprehenderit tempora. Blanditiis fuga harum ipsam magnam
                                numquam obcaecati omnis perspiciatis quibusdam quo repellendus! Amet asperiores,
                                dolorum
                                expedita inventore itaque laboriosam, natus necessitatibus nobis praesentium,
                                quaerat
                                soluta suscipit ut vero? Beatae blanditiis corporis cupiditate deleniti eveniet
                                laboriosam mollitia odio perferendis sequi! Animi consequatur dolores ducimus et
                                exercitationem, expedita fugit harum iusto laudantium magnam nam non nostrum odit,
                                porro
                                praesentium quam quo recusandae tenetur totam.</p>
                        </Grid>
                    </Grid>
                    <Grid className="commentsArea">
                        <Comments/>
                        <CommentForm/>
                    </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Grid className="sidebarArea">
                        {/*widget category*/}
                        <Grid className="widget widgetCategory">
                            <h3 className="widgetTitle">Categories</h3>
                            <ul className="widgetCatList">
                                <li><Link to="/blog">Women<span>0</span></Link></li>
                                <li><Link to="/blog">Men<span>03</span></Link></li>
                                <li><Link to="/blog">Accessories<span>03</span></Link></li>
                            </ul>
                        </Grid>
                        {/*widget recent post*/}
                        <Grid className="widget widgetRecentPost">
                            <h3 className="widgetTitle">Recent Post</h3>
                            {blogs !== undefined ? blogs.slice(0, 4).map((item, i) => {
                                return (
                                    <Grid key={i} className="rcSinglePost">
                                        <div className="thumb">
                                            <img src={item.screens} alt="thumb"/>
                                        </div>
                                        <div className="content">
                                            <h5><Link to={`/blog-details/${item.id}`}>{item.title}</Link></h5>
                                            <p className="date"><FontAwesome name="calendar"/>01 Jun 2020</p>
                                        </div>
                                    </Grid>
                                )
                            }) : ''}
                        </Grid>
                        {/*widget keyword*/}
                        <Grid className="widget widgetKeywords">
                            <h3 className="widgetTitle">Keywords</h3>
                            <Link to="/">business</Link>
                            <Link to="/">Design</Link>
                            <Link to="/">Agency</Link>
                            <Link to="/">Infography</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRouter(BlogDetails);