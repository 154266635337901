import React from 'react';
import {Grid} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import {Link} from "react-router-dom";
const Activation = (props) => {


  return (
      <div className="DataTableArea">
        <div className="DtableHeaderArea">
            <h2 className="DtableHeader">Payment Related</h2>
        </div>

        <Grid container spacing={3}>
            <Grid item lg={4}>
                <Grid className="payment-card">
                  <Grid>
                     <h2>Cash On Delivery Activation</h2>
                     <Switch
                          color="primary"
                          inputProps={{'aria-label': 'primary checkbox'}}
                      />
                     <Link to={'/admin/adminsettings'}>Configure Now</Link>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item lg={4}>
                <Grid className="payment-card">
                  <Grid>
                     <h2>Card(Stripe) Activation</h2>
                     <Switch
                          color="primary"
                          inputProps={{'aria-label': 'primary checkbox'}}
                      />
                     <Link to={'/admin/adminsettings'}>Configure Now</Link>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item lg={4}>
                <Grid className="payment-card">
                  <Grid>
                     <h2>PayPal(Braintree) Activation</h2>
                     <Switch
                          color="primary"
                          inputProps={{'aria-label': 'primary checkbox'}}
                      />
                     <Link to={'/admin/adminsettings'}>Configure Now</Link>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item lg={4}>
                <Grid className="payment-card">
                  <Grid>
                     <h2>Card(Braintree) Activation</h2>
                     <Switch
                          color="primary"
                          inputProps={{'aria-label': 'primary checkbox'}}
                      />
                     <Link to={'/admin/adminsettings'}>Configure Now</Link>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item lg={4}>
                <Grid className="payment-card">
                  <Grid>
                     <h2>GooglePay(Braintree) Activation</h2>
                     <Switch
                          color="primary"
                          inputProps={{'aria-label': 'primary checkbox'}}
                      />
                     <Link to={'/admin/adminsettings'}>Configure Now</Link>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item lg={4}>
                <Grid className="payment-card">
                  <Grid>
                     <h2>Bank Activation</h2>
                     <Switch
                          color="primary"
                          inputProps={{'aria-label': 'primary checkbox'}}
                      />
                     <Link to={'/admin/adminsettings'}>Configure Now</Link>
                  </Grid>
                </Grid>
            </Grid>
        </Grid>

      </div>
    
  );
}

export default Activation;