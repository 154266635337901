import React from 'react';
import {Tooltip, Line, LineChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend} from 'recharts';
import {Grid} from '@material-ui/core'

const line = [
    {name: 'Jan', Earnings: 4000, Expense: 2401},
    {name: 'Feb', Earnings: 3000, Expense: 2564},
    {name: 'Mar', Earnings: 2780, Expense: 1354},
    {name: 'Apr', Earnings: 1890, Expense: 2800},
    {name: 'May', Earnings: 2390, Expense: 1687},
    {name: 'Jun', Earnings: 4000, Expense: 2564},
    {name: 'Jul', Earnings: 3000, Expense: 3564},
    {name: 'Aug', Earnings: 2780, Expense: 1684},
    {name: 'Sep', Earnings: 4000, Expense: 3654},
    {name: 'Oct', Earnings: 3000, Expense: 2432},
    {name: 'Nov', Earnings: 2780, Expense: 1264},
    {name: 'Dec', Earnings: 1890, Expense: 1065},
];

const CustomTooltip = ({active, payload, label}) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <h4 className="label">$ {payload[0].value}</h4>
                <h4 className="label">$ {payload[1].value}</h4>
                <p>{label}</p>
            </div>
        );
    }
    return null;
};

const RevenueChart = () => {
    return (
        <Grid className="lineRechart">
            <ResponsiveContainer>
                <LineChart
                    data={line}
                >
                    {/* verticalFill={['#555555', '#444444']} */}
                    <CartesianGrid vertical horizontal={false} fillOpacity={1} stroke="#f9f9f9" strokeDasharray="0"/>
                    <XAxis axisLine={false} tickMargin={10} tickLine={false} fontSize={12} stroke="#708093"
                           dataKey="name"/>
                    <YAxis axisLine={false} tickMargin={10} tickLine={false} fontSize={12} stroke="#708093"/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Line type="monotone"
                          name="Earnings"
                          dataKey="Earnings"
                          stroke="#BD20D3"
                          strokeWidth={4}
                          strokeDasharray="1"
                          dot={{strokeWidth: 0, strokeWidth: 0, r: 0}}
                          activeDot={{stroke: "#ffffff", strokeWidth: 4, r: 7}}
                    />
                    <Legend align="right" verticalAlign="top"/>
                    <Line type="monotone"
                          name="Expense"
                          dataKey="Expense"
                          stroke="#F1682C"
                          strokeWidth={4}
                          strokeDasharray="1"
                          dot={{strokeWidth: 0, strokeWidth: 0, r: 0}}
                          activeDot={{stroke: "#ffffff", strokeWidth: 4, r: 7}}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Grid>
    );
};

export default RevenueChart;