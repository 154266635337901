import React from 'react';
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AccountDetails from "../components/AccountDetails";
import SocialAdress from "../components/SocialSetting";
import FormUpload from "../components/dropzone";
import { Helmet } from "react-helmet";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const submitHandler = (e) => {
    e.preventDefault();
};

const ShopSetting = () => {
    const [tab, setTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Grid className="accountProfile">
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | My Profile</title>
            </Helmet>
            <Tabs
                className="accountTabs"
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
                onChange={handleChange}
                aria-label="simple tabs example">
                <Tab label="Primary Setting" {...a11yProps(0)} />
                <Tab label="Slider Setting" {...a11yProps(1)} />
                <Tab label="Social Setting" {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={tab} index={0}>
                <Grid className="changePass">
                    <form onSubmit={submitHandler}>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Shop Name"
                                    name="Shop-Name"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="formInput radiusNone bdbNone mb-20"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    name="new_password"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="formInput radiusNone bdbNone mb-20"
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid className="drop-zone-wrap">
                                    <span>Upload Logo</span>
                                    <FormUpload />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" className="cBtn cBtnLarge cBtnBlack">Submit</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Grid className="changePass">
                    <form onSubmit={submitHandler}>
                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <Grid className="drop-zone-wrap">
                                    <span>Upload Slider Image</span>
                                    <FormUpload />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" className="cBtn cBtnLarge cBtnBlack">Upload</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <SocialAdress />
            </TabPanel>
        </Grid>
    )
};

export default ShopSetting;