import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";



import './style.scss';

const PurcessHistory = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - Purcess History Details</title>
            </Helmet>
            <Grid className="">
                <Grid className="container" container spacing={3} >
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper purcessWrapper-v">
                            <h2>Order Tracking</h2>
                            <Grid className="">
                                <Grid container spacing={3}>
                                    <Grid item md={3} sm={3} xs={3}>
                                        <div className="trucking-single active">
                                            <div className="trucking-img ">
                                                <div className="normal-img">
                                                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="trucking-content">
                                                <h2>Order Received</h2>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={3}>
                                        <div className="trucking-single">
                                            <div className="trucking-img ">
                                                <div className="normal-img">
                                                    <i className="fa fa-circle-o-notch" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="trucking-content">
                                                <h2>Order Processing</h2>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={3}>
                                        <div className="trucking-single">
                                            <div className="trucking-img ">
                                                <div className="normal-img">
                                                    <i className="fa fa-male" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="trucking-content">
                                                <h2>Order Delivered</h2>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={3}>
                                        <div className="trucking-single">
                                            <div className="trucking-img ">
                                                <div className="normal-img">
                                                    <i className="fa fa-ban" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="trucking-content">
                                                <h2>Cancelled</h2>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <h2>Order History</h2>
                            <Grid className="defaultTable">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Product</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>	Unit Price</TableCell>
                                            <TableCell align='right'>Total Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>1</TableCell>
                                            <TableCell>Beet Root - (100% organic) - Freeze Dried Powder</TableCell>
                                            <TableCell>$ 3.25</TableCell>
                                            <TableCell>$ 3.25</TableCell>
                                            <TableCell align='right'>rdgtesrgh</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>2</TableCell>
                                            <TableCell>Beet Root - (100% organic) - Freeze Dried Powder</TableCell>
                                            <TableCell>$ 4.25</TableCell>
                                            <TableCell>$ 8.50</TableCell>
                                            <TableCell align='right'>rdgtesrgh</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <h2>Order Summary</h2>
                            <Grid className="defaultTable">
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Order Code:</TableCell>
                                            <TableCell align='right'>#10040</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Billing address:</TableCell>
                                            <TableCell align='right'>luxemburg</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Total order amount:</TableCell>
                                            <TableCell align='right'>$ 11.65</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Payment method:</TableCell>
                                            <TableCell align='right'>Cash On Delivery</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <h2>Order Amount</h2>
                            <Grid className="defaultTable">
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Subtotal:</TableCell>
                                            <TableCell align='right'>$ 11.65</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Shipping Cost:</TableCell>
                                            <TableCell align='right'>$ 0</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Tax:</TableCell>
                                            <TableCell align='right'>$ 0</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Discount:</TableCell>
                                            <TableCell align='right'>$ 0</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Grand Total:</TableCell>
                                            <TableCell align='right'>$ 11.65</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Fragment>
    )
};

export default PurcessHistory;