import React, { Fragment } from 'react';
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import FontAwesome from "../../../../components/UiStyle/FontAwesome";
import Switch from "@material-ui/core/Switch";

const products = [
    {
        id: 1,
        name: 'Technologies',
        publication: true,
    }, {
        id: 2,
        name: '	Shopping in rCommerce',
        publication: true,
    }, {
        id: 3,
        name: 'Foods',
        publication: true,
    }, {
        id: 4,
        name: '	Man Fashion',
        publication: true,
    }, {
        id: 5,
        name: 'Woman Fashion',
        publication: true,
    }, {
        id: 6,
        name: 'Toys',
        publication: true,
    }, {
        id: 7,
        name: 'Cars',
        publication: true,
    },
];

const BlogCategory = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div className="DataTableArea cardWrap">
            <div className="DtableHeaderArea">
                <h2 className="DtableHeader">All Blog Category</h2>
                <Link to='/admin/addblogcategory' className="cBtn cBtnTheme">Add New Category</Link>
            </div>
            <Grid className="mt-30">
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Publication Status</TableCell>
                                <TableCell align="right">Option</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.length <= 0 ? <TableRow>
                                <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                            </TableRow> : <Fragment>
                                    {products.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell>{item.id}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                                    name="ellipsis-v" /></span>
                                                <Popover
                                                    className="optionsMenu"
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handlePopoverClose}
                                                    disableRestoreFocus
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <Link to='/admin/editblogcategory'>Edit Category</Link>
                                                    <Link to={props.match.path}>Delete Category</Link>
                                                </Popover>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Fragment>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>
    )
};

export default BlogCategory;