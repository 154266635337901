import React from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import FontAwesome from "../UiStyle/FontAwesome";

// image
import banner1 from '../../images/banner/1.jpg';
import banner2 from '../../images/banner/3.jpg';
import banner3 from '../../images/banner/2.jpg';

import './style.scss';

const Banner2 = () => {
    return (
        <Grid container className="container mt-20" spacing={3}>
            <Grid item md={7} xs={12}>
                <Grid className="bannerWrapper2 mb-30">
                    <Grid className="bannerThumb">
                        <Link to="/shop"><img src={banner1} alt="banner1"/></Link>
                    </Grid>
                </Grid>
                <Grid className="bannerWrapper2">
                    <Grid className="bannerThumb">
                        <Link to="/shop"><img src={banner2} alt="banner2"/></Link>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={5} xs={12}>
                <Grid className="bannerWrapper2">
                    <Grid className="bannerThumb">
                        <Link to="/shop"><img src={banner3} alt="banner3"/></Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default Banner2;