import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";
import Service from "../../components/Service";
import BlogSection from "../../components/BlogSection";
import Subscription from "../../components/Subscription";

// images
import abtImg from '../../images/privacy.jpg';

import './style.scss';

const PrivacyPolicy = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - Privacy Policy</title>
            </Helmet>
            <Grid className="aboutUsWrapper pt-40 pb-30">
                <Grid className="container" container spacing={3}>
                    <Grid item xs={12}>
                        <div className="aboutContent">
                            <h2><strong>Privacy Policy</strong></h2>
                            <h5>More than to make your day. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea
                                ex in labore natus nostrum qui.</h5>
                            <img src={abtImg} alt="abtImg" />
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aperiam at dolorum,
                            illum ipsam ipsum nemo nihil sapiente. Alias aliquid autem dicta doloremque dolorum
                            praesentium sed! Culpa ducimus excepturi fuga inventore molestiae officiis ratione.
                            Alias, animi commodi, ducimus ea fugiat illum ipsum iste laudantium maxime quam ratione
                                ullam vitae voluptates.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aperiam at dolorum,
                            illum ipsam ipsum nemo nihil sapiente. Alias aliquid autem dicta doloremque dolorum
                            praesentium sed! Culpa ducimus exciis ratione. Alias, animi commodi, ducimus ea fugiat
                                illum ipsum iste laudantium maxime quam ratione ullam vitae voluptates.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aperiam at dolorum,
                            illum ipsam ipsum nemo nihil sapiente. Alias aliquid autem dicta doloremque dolorum
                            praesentium sed! Culpa ducimus excepturi fuga inventore molestsum iste laudantium maxime
                                quam ratione ullam vitae voluptates.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aperiam at dolorum,
                            illum ipsam ipsum nemo nihil sapiente. Alias aliquid autem dicta doloremque dolorum
                            praesentium sed! Culpa ducimus excepturi fuga inventoreatione. Alias, animi commodi,
                            ducimus ea fugiat illum ipsum iste laudantium maxime quam ratione ullam vitae
                                voluptates.</p>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Service />
            <BlogSection />
            <Subscription />
        </Fragment>
    )
};

export default PrivacyPolicy;