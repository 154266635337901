import React from 'react';
import {Grid} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";

// images
import image1 from '../../../images/author/author1.jpg';
import image2 from '../../../images/author/author2.jpg';
import FontAwesome from "../../../components/UiStyle/FontAwesome";

const Comments = () => {
    return (
        <Grid className="commentMain">
            <h3 className="blogTitle"><span>(03)</span>Comments:</h3>
            <List className="commentList">
                <ListItem className="singleComment">
                    <Grid className="commentWrap">
                        <Grid className="commentImage">
                            <img src={image1} alt="Jhon"/>
                        </Grid>
                        <Grid className="commentContent">
                            <Grid className="commentsMeta">
                                <h4>Lily Justin </h4>
                                <span>19 JAN 2018  at 2:30pm</span>
                            </Grid>
                            <Grid className="commentBody">
                                <p>simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the
                                    1500s, when</p>
                                <Button className="replyButton"><FontAwesome name="reply"/> Reply</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <List className="commentReply">
                        <ListItem className="singleComment">
                            <Grid className="commentWrap">
                                <Grid className="commentImage">
                                    <img src={image2} alt="Jhon"/>
                                </Grid>
                                <Grid className="commentContent">
                                    <Grid className="commentsMeta">
                                        <h4>Lily Justin </h4>
                                        <span>19 JAN 2018  at 2:30pm</span>
                                    </Grid>
                                    <Grid className="commentBody">
                                        <p>simply dummy text of the printing and typesetting industry. Lorem
                                            Ipsum has been the industry's standard dummy text ever since the
                                            1500s, when</p>
                                        <Button className="replyButton"><FontAwesome name="reply"/> Reply</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem className="singleComment">
                    <Grid className="commentWrap">
                        <Grid className="commentImage">
                            <img src={image1} alt="Jhon"/>
                        </Grid>
                        <Grid className="commentContent">
                            <Grid className="commentsMeta">
                                <h4>Lily Justin </h4>
                                <span>19 JAN 2018  at 2:30pm</span>
                            </Grid>
                            <Grid className="commentBody">
                                <p>simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the
                                    1500s, when</p>
                                <Button className="replyButton"><FontAwesome name="reply"/> Reply</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ListItem>
            </List>
        </Grid>
    )
};

export default Comments;