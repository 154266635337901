import React from 'react';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {Link} from "react-router-dom";

import './style.scss';

const BreadCrumb = ({title}) => {
    return (
        <Grid className="breadCrumb">
            <Grid className="container" container spacing={3}>
                <Grid item xs={12}>
                    <List className="crumbList">
                        <ListItem><Link to="/">Home /</Link></ListItem>
                        <ListItem>{title}</ListItem>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default BreadCrumb;