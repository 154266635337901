import React from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../UiStyle/FontAwesome";

const BlogItem = ({item, md}) => {
    return (
        <Grid item lg={4} md={md ? md : 6} sm={6} xs={12}>
            <Grid className="blogItem">
                <Grid className="thumb">
                    <Link to={`/blog-details/${item.id}`}><img src={item.screens} alt="blogImg1"/></Link>
                </Grid>
                <Grid className="content">
                    <h2><Link to={`/blog-details/${item.id}`}>{item.title}</Link></h2>
                    <p>{item.description}</p>
                    <Grid className="blogMeta">
                        <List>
                            <ListItem><FontAwesome
                                name="comment-o"/>{item.comments} comments</ListItem>
                            <ListItem><Link to={`/blog-details/${item.id}`}>Read More</Link></ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default BlogItem;