import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import SingleProductDetails from "../../components/SingleProductDetails";
import { addToCart, addToWishList } from "../../store/actions/action";
import ProductTabMenu from "../../components/ProductTabMenu";
import { Helmet } from "react-helmet";

const SingleProduct = (props) => {
    const { products, addToWishList, addToCart, symbol } = props;
    const [product, setProduct] = useState({});

    useEffect(() => {
        setProduct(products.filter(product => product.id === Number(props.match.params.id)))
    }, []);

    const item = product[0];

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - Product Details</title>
            </Helmet>
            <Grid className="singleProduct ptb-60">
                {item ? <SingleProductDetails
                    symbol={symbol}
                    item={item}
                    addToWishList={addToWishList}
                    addToCart={addToCart}
                /> : null}
                <Grid className="container" container spacing={3}>
                    <Grid item xs={12}>
                        <ProductTabMenu item={item} />
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        products: state.data.products,
        symbol: state.data.symbol
    }
};

export default connect(mapStateToProps, { addToCart, addToWishList })(SingleProduct);