import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Fade from 'react-reveal/Fade';
import Grid from "@material-ui/core/Grid";
import WidgetCategory from "../../components/Widgets/WidgetCategory";
import WidgetPrice from "../../components/Widgets/WidgetPrice";
import WidgetColor from "../../components/Widgets/WidgetColor";
import WidgetSize from "../../components/Widgets/WidgetSize";
import ShopFilter from "../../components/ShopFilter";
import ProductItem from "../../components/ProductItem"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { filterProductByCategory, filterProductByColor, filterProductByPrice, filterProductBySize } from "../../utils";

import './style.scss';
import { Helmet } from "react-helmet";

class ShopPage extends Component {

    state = {
        currentPage: 1,
        rowsPerPage: 9,
        girdCollumnType: 1,
        selected_category: null,
        selected_price: [50, 150],
        selected_color: null,
        selected_size: null,
    };

    paginateHandler = number => () => {
        this.setState({
            currentPage: number,
        })
    };

    perPageHandler = e => {
        this.setState({
            rowsPerPage: e.target.value
        })
    };

    gridHandler = number => () => {
        this.setState({
            girdCollumnType: number
        })
    };

    categoryHandler = name => () => {
        this.setState({
            selected_category: name,
            currentPage: 1,
        })
    };

    priceHandler = (event, newPrice) => {
        this.setState({
            selected_price: newPrice,
            currentPage: 1,
        })
    };

    colorHandler = color => () => {
        this.setState({
            selected_color: color,
            currentPage: 1,
        })
    };

    sizeHandler = size => () => {
        this.setState({
            selected_size: size,
            currentPage: 1,
        })
    };

    render() {
        // get all the state
        const { currentPage, rowsPerPage, girdCollumnType, selected_category, selected_price, selected_color, selected_size } = this.state;
        // get all the data from the props
        const { all_products, symbol } = this.props;
        // filtering here
        const filterdProducts = all_products.filter(product => filterProductByCategory(product, selected_category))
            .filter(product => filterProductByPrice(product, selected_price))
            .filter(product => filterProductByColor(product, selected_color))
            .filter(product => filterProductBySize(product, selected_size));

        const totalNumberOfPrd = filterdProducts.length;
        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        const currentRows = filterdProducts.slice(indexOfFirstRow, indexOfLastRow);
        const numberOfLastFinalRow = indexOfLastRow - (rowsPerPage - currentRows.length);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(filterdProducts.length / rowsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => (
            <ListItem
                key={number}
                id={number}
                className={currentPage === number ? 'active' : null}
                onClick={this.paginateHandler(number)}
            >
                {number}
            </ListItem>
        ));

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>rCommerce - Shops</title>
                </Helmet>
                <Grid className="shopWrapper ptb-60">
                    <Grid className="container" container spacing={3}>
                        <Grid item md={3} xs={12} className="pl-12">
                            <Grid className="sidebar">
                                <WidgetCategory
                                    categoryHandler={this.categoryHandler}
                                    selected_category={selected_category} />
                                <WidgetPrice
                                    priceHandler={this.priceHandler}
                                    price={selected_price} />
                                <WidgetColor
                                    selected_color={selected_color}
                                    colorHandler={this.colorHandler}
                                />
                                <WidgetSize
                                    selected_size={selected_size}
                                    sizeHandler={this.sizeHandler}
                                />
                            </Grid>
                        </Grid>
                        <Grid item md={9} xs={12} className="pr-12">
                            <ShopFilter
                                perPageHandler={this.perPageHandler}
                                gridHandler={this.gridHandler}
                                rowsPerPage={rowsPerPage}
                                indexOfLastRow={numberOfLastFinalRow}
                                indexOfFirstRow={indexOfFirstRow}
                                totalNumberOfPrd={totalNumberOfPrd} />
                            <Grid className="allProducts">
                                <Grid container spacing={3}>
                                    {currentRows.length <= 0 ? <p className="noProdFound">No Products Found</p> :
                                        <Fragment>
                                            {currentRows.map(item => {
                                                return (
                                                    <Grid key={item.id} item
                                                        lg={girdCollumnType === 1 ? 4 : girdCollumnType === 2 ? 3 : girdCollumnType === 3 ? 12 : 12}
                                                        md={girdCollumnType === 1 ? 4 : girdCollumnType === 2 ? 4 : girdCollumnType === 3 ? 12 : 12}
                                                        sm={girdCollumnType === 1 ? 6 : girdCollumnType === 2 ? 6 : girdCollumnType === 3 ? 12 : 12}
                                                        xs={12}>
                                                        <Fade big>
                                                            <ProductItem
                                                                girdCollumnType={girdCollumnType}
                                                                symbol={symbol}
                                                                item={item}
                                                            />
                                                        </Fade>
                                                    </Grid>
                                                )
                                            })}
                                        </Fragment>
                                    }
                                </Grid>
                            </Grid>
                            {filterdProducts.length <= rowsPerPage ? null :
                                <List className="paginationWrapper">
                                    {renderPageNumbers}
                                </List>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        all_products: state.data.products,
        symbol: state.data.symbol
    }
};

export default connect(mapStateToProps)(ShopPage);