import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SeoSetting = () =>{
  const classes = useStyles();
  const [age, setAge] = React.useState('');

  const inputLabel = React.useRef(null);


  const handleChange = event => {
    setAge(event.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
};


  return (
    <div className="newslatter cardWrap">
        <div className="DtableHeaderArea">
            <h2 className="DtableHeader">SEO Setting</h2>
        </div>
        <form onSubmit={submitHandler}>
        <Grid container spacing={3}>
            <Grid item lg={12}>
                <FormControl variant="filled" className={classes.formControl}>
                    <TextField
                            fullWidth
                            label="Keys"
                            name="Keys"
                            placeholder="Keys"
                            type="text"
                            className="formInput radiusNone bdbNone mb-20"
                        />
                </FormControl>
            </Grid>
            <Grid item lg={12} xs={12}>
                  <TextareaAutosize aria-label="empty textarea" placeholder="Meta Description" />
            </Grid>
            <Grid item lg={12} xs={12}>
                <Button type="submit" className="cBtn cBtnLarge cBtnBlack">Submit</Button>
            </Grid>
        </Grid>
            
        </form>
      
    </div>
  );
}

export default SeoSetting;