import React from 'react';
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import {Link} from "react-router-dom";
import invoice from "../invoice.pdf";
import FontAwesome from "../../../../components/UiStyle/FontAwesome";
import DefaultModal from './modal'

import CurrentBlance from "./CrBlance"

const reviews = [
    {
        id: 1,
        code: '#10001',
        title: '2020-01-02 13:04:41	',
        rating: '$ 0.92',
        comment: 'Done',
        publish_approval: 'Approved'
    }, {
        id: 2,
        code: '#10002',
        title: '2020-01-02 13:04:41',
        rating: '$ 0.92',
        comment: 'Done',
        publish_approval: 'Approved'
    }, {
        id: 3,
        code: '#10003',
        title: '2020-01-02 13:04:41',
        rating: '$ 0.92',
        comment: 'Done',
        publish_approval: 'Approved'
    }, {
        id: 4,
        code: '#10004',
        title: '2020-01-02 13:04:41',
        rating: '$ 0.92',
        comment: 'Done',
        publish_approval: 'Pending'
    }, {
        id: 5,
        code: '#10005',
        title: '2020-01-02 13:04:41',
        rating: '$ 0.92',
        comment: 'Done',
        publish_approval: 'Approved'
    },
];



const PaymentWp = ( props ) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <Grid>
            <Grid className="cardWrapb">
                <CurrentBlance/>
            </Grid>
            <Grid className="widrwal-modal">
                <DefaultModal
                    button="Make Withdraw Request"
                    buttonClass="bg-default"
                />
            </Grid>
            
            <Grid className="cardWrap">
                <Grid className="cardTitle">
                    <h4 className="title"> Withdraw Requests</h4>
                </Grid>
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Request Code</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell > Payment Receive Confirmation</TableCell>
                                <TableCell align="right">Option</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reviews.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell><strong>{item.code}</strong></TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.rating}</TableCell>
                                    <TableCell><p className="done-btn">{item.comment}</p></TableCell>
                                    <TableCell ><span
                                        className={`badge ${item.publish_approval.toLowerCase()}`}>{item.publish_approval}</span></TableCell>

                                    <TableCell align="right">
                                            <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                                name="ellipsis-v"/></span>
                                            <Popover
                                                className="optionsMenu"
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Link to='/vendor/WithdrawDetails'>View Details</Link>
                                                <a href={invoice} download>Download Invoice</a>
                                            </Popover>
                                        </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
        
    )
};

export default PaymentWp;