import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Fade from 'react-reveal/Fade';
import Grid from "@material-ui/core/Grid";
import WidgetCategory from "../../components/Widgets/WidgetCategory";
import WidgetPrice from "../../components/Widgets/WidgetPrice";
import WidgetColor from "../../components/Widgets/WidgetColor";
import WidgetSize from "../../components/Widgets/WidgetSize";
import ShopFilter from "../../components/ShopFilter";
import ProductItem from "../../components/ProductItem"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { filterProductByCategory, filterProductByColor, filterProductByPrice, filterProductBySize } from "../../utils";

import './style.scss';
import { Helmet } from "react-helmet";

class ShopNoSidebar extends Component {

    state = {
        currentPage: 1,
        rowsPerPage: 9,
        girdCollumnType: 1,
    };

    paginateHandler = number => () => {
        this.setState({
            currentPage: number,
        })
    };

    perPageHandler = e => {
        this.setState({
            rowsPerPage: e.target.value
        })
    };

    gridHandler = number => () => {
        this.setState({
            girdCollumnType: number
        })
    };

    render() {
        // get all the state
        const { currentPage, rowsPerPage, girdCollumnType } = this.state;
        // get all the data from the props
        const { all_products, symbol } = this.props;

        const totalNumberOfPrd = all_products.length;
        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        const currentRows = all_products.slice(indexOfFirstRow, indexOfLastRow);
        const numberOfLastFinalRow = indexOfLastRow - (rowsPerPage - currentRows.length);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(all_products.length / rowsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => (
            <ListItem
                key={number}
                id={number}
                className={currentPage === number ? 'active' : null}
                onClick={this.paginateHandler(number)}
            >
                {number}
            </ListItem>
        ));

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>rCommerce - Shops</title>
                </Helmet>
                <Grid className="shopWrapper ptb-60">
                    <Grid className="container" container spacing={3}>
                        <Grid item xs={12} className="pr-12">
                            <ShopFilter
                                perPageHandler={this.perPageHandler}
                                gridHandler={this.gridHandler}
                                rowsPerPage={rowsPerPage}
                                indexOfLastRow={numberOfLastFinalRow}
                                indexOfFirstRow={indexOfFirstRow}
                                totalNumberOfPrd={totalNumberOfPrd} />
                            <Grid className="allProducts">
                                <Grid container spacing={3}>
                                    {currentRows.length <= 0 ? <p className="noProdFound">No Products Found</p> : <>
                                        {currentRows.map(item => {
                                            return (
                                                <Grid key={item.id} item
                                                    lg={girdCollumnType === 1 ? 4 : girdCollumnType === 2 ? 3 : girdCollumnType === 3 ? 12 : 12}
                                                    md={girdCollumnType === 1 ? 4 : girdCollumnType === 2 ? 4 : girdCollumnType === 3 ? 12 : 12}
                                                    sm={girdCollumnType === 1 ? 6 : girdCollumnType === 2 ? 6 : girdCollumnType === 3 ? 12 : 12}
                                                >
                                                    <Fade big>
                                                        <ProductItem girdCollumnType={girdCollumnType} symbol={symbol}
                                                            item={item} />
                                                    </Fade>
                                                </Grid>
                                            )
                                        })}</>}
                                </Grid>
                            </Grid>
                            {all_products.length <= rowsPerPage ? null :
                                <List className="paginationWrapper">
                                    {renderPageNumbers}
                                </List>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        all_products: state.data.products,
        symbol: state.data.symbol
    }
};

export default connect(mapStateToProps)(ShopNoSidebar);