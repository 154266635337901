import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import Service from "../../components/Service";
import Subscription from "../../components/Subscription";

import './style.scss';
import Button from "@material-ui/core/Button";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import Collapse from "@material-ui/core/Collapse";
import { Helmet } from "react-helmet";

const faq = [
    {
        id: 1,
        title: 'General Inquiries ?',
        body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, debitis distinctio doloremque, earum explicabo fuga impedit iusto natus nulla obcaecati omnis placeat quod reiciendis repudiandae, rerum suscipit velit. Eos esse eum excepturi explicabo molestias ratione suscipit ullam voluptates.'
    },
    {
        id: 2,
        title: 'How To Use ?',
        body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, debitis distinctio doloremque, earum explicabo fuga impedit iusto natus nulla obcaecati omnis placeat quod reiciendis repudiandae, rerum suscipit velit. Eos esse eum excepturi explicabo molestias ratione suscipit ullam voluptates.'
    },
    {
        id: 3,
        title: 'Shipping & Delivery ?',
        body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, debitis distinctio doloremque, earum explicabo fuga impedit iusto natus nulla obcaecati omnis placeat quod reiciendis repudiandae, rerum suscipit velit. Eos esse eum excepturi explicabo molestias ratione suscipit ullam voluptates.'
    },
    {
        id: 4,
        title: 'Additional Information ?',
        body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, debitis distinctio doloremque, earum explicabo fuga impedit iusto natus nulla obcaecati omnis placeat quod reiciendis repudiandae, rerum suscipit velit. Eos esse eum excepturi explicabo molestias ratione suscipit ullam voluptates.'
    },
    {
        id: 5,
        title: 'Return Policy ?',
        body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, debitis distinctio doloremque, earum explicabo fuga impedit iusto natus nulla obcaecati omnis placeat quod reiciendis repudiandae, rerum suscipit velit. Eos esse eum excepturi explicabo molestias ratione suscipit ullam voluptates.'
    },
];

const FaqPage = () => {
    const [expanded, setExpanded] = React.useState(0);

    function faqHandler(number) {
        setExpanded(number);
    }

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | FAQ</title>
            </Helmet>
            <Grid className="faqPageWrapper ptb-60">
                <Grid className="container" container spacing={3}>
                    <Grid item xs={12}>
                        <Grid className="prdTabContent faqContent">
                            {faq.map(item => (
                                <div key={item.id}>
                                    <Button fullWidth onClick={() => faqHandler(item.id)}>{item.title}
                                        <FontAwesome
                                            name={expanded === item.id ? 'minus' : 'plus'} /></Button>
                                    <Collapse className="faqBodyText" in={expanded === item.id} timeout="auto"
                                        unmountOnExit>
                                        <p>{item.body}</p>
                                    </Collapse>
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Service className="pb-60" />
            <Subscription />
        </Fragment>
    )
};

export default FaqPage;