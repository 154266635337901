import React from 'react';
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const reviews = [
    {
        id: 1,
        code: '#5845',
        title: 'Beet Root - (100% organic) - Freeze Dried Powder',
        rating: '3',
        comment: '	this product is good',
        publish_approval: 'Approved'
    }, {
        id: 2,
        code: '#5845',
        title: 'Freeze Dried Powder',
        rating: '7',
        comment: '	this product is good',
        publish_approval: 'Approved'
    }, {
        id: 3,
        code: '#5845',
        title: 'Beet Root - (100% organic) - Freeze Dried Powder',
        rating: '2',
        comment: '	this product is good',
        publish_approval: 'Approved'
    }, {
        id: 4,
        code: '#5845',
        title: 'Beet Root - Freeze Dried Powder',
        rating: '0',
        comment: '	this product is not good',
        publish_approval: 'Pending'
    }, {
        id: 5,
        code: '#5845',
        title: 'tBeet Root Dried Powder',
        rating: '3',
        comment: '	this product is good',
        publish_approval: 'Approved'
    },
];

const ProductReview = () => {
    return (
        <Grid className="cardWrap">
            <Grid className="cardTitle">
                <h4 className="title">Product Reviews</h4>
            </Grid>
            <Grid className="defaultTable">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Review Code</TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>Comment</TableCell>
                            <TableCell align="right"> Publish Approval</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reviews.map(item => (
                            <TableRow key={item.id}>
                                <TableCell><strong>{item.code}</strong></TableCell>
                                <TableCell>{item.title}</TableCell>
                                <TableCell>{item.rating}</TableCell>
                                <TableCell>{item.comment}</TableCell>
                                <TableCell align="right"><span
                                    className={`badge ${item.publish_approval.toLowerCase()}`}>{item.publish_approval}</span></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
};

export default ProductReview;