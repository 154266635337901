import React from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  
const EditBlogCategory = () => {
    const [forms, setForms] = React.useState({
        firstname: 'News feed',
        Lastname: '',
        email: '',
        phone: '',
        Password: '',
        confirm_Password: '',
    });

    // forms handler
    const changeHandler = e => {
        setForms({...forms, [e.target.name]: e.target.value})
    };

    const submitHandler = (e) => {
        e.preventDefault();
        toast.success('Form Submitted!')
    };

    const classes = useStyles();
    const [age, setAge] = React.useState('');
  
    const inputLabel = React.useRef(null);
  
  
    const handleChange = event => {
      setAge(event.target.value);
    };
    return (
        <Grid className="DataTableArea cardWrap">
            <Grid className="DtableHeaderArea">
                <h2 className="DtableHeader">Edit Blog Category</h2>
            </Grid>
            <Grid className="changePass cbt-wrap">
                <form onSubmit={submitHandler}>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <TextField
                                fullWidth
                                label="Blog Category Name"
                                name="firstname"
                                value={forms.firstname}
                                onChange={changeHandler}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 

                        <Grid item xs={12}>
                            <Button type="submit" className="cBtn cBtnLarge cBtnBlack">Edit Category</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
};

export default EditBlogCategory;