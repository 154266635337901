import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import HeroArea2 from "../../components/HeroArea2";
import DealArea from "../../components/DealArea";
import TrandingItem from "../../components/TrandingItem";
import Banner2 from "../../components/Banner2";
import Service2 from "../../components/Service2";
import BlogSection from "../../components/BlogSection";
import Subscription from "../../components/Subscription";
import CategoryArea from "../../components/Category";


import './style.scss';

const HomePage2 = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce ecommerce</title>
            </Helmet>
            <HeroArea2 />
            <CategoryArea />
            <DealArea />
            <Service2 />
            <Banner2 />
            <TrandingItem />
            <Subscription />
        </Fragment>
    )
};

export default HomePage2;