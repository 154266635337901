import React from 'react';
import {Grid} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import {Link} from "react-router-dom";

import Braintree from '../Paymentmethod/Braintree'
import Googlepay from '../Paymentmethod/Googlepay'
import Stripe from '../Paymentmethod/Stripe'

const PaymentMethod = (props) => {
  return (
      <div className="DataTableArea">
        <div className="DtableHeaderArea">
            <h2 className="DtableHeader">Payment Method Settings</h2>
        </div>

        <Grid container spacing={3}>
            <Grid item lg={6}>
                <Grid className="payment-card payment-card-b">
                  <Grid>
                     <h2>Braintree Credentials</h2>
                     <Braintree/>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item lg={6}>
                <Grid className="payment-card payment-card-b">
                  <Grid>
                     <h2>Google Pay Credentials</h2>
                     <Googlepay/>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item lg={6}>
                <Grid className="payment-card payment-card-b">
                  <Grid>
                     <h2>Stripe Credential</h2>
                     <Stripe/>
                  </Grid>
                </Grid>
            </Grid>
        </Grid>

      </div>
    
  );
}

export default PaymentMethod;