import React, { Fragment, useState } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import Header2 from "../../Header2";
import Footer from "../../../components/Footer";
import AccountDashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";

import userImg from '../../../images/author/author1.jpg';

import './style.scss';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../../../components/UiStyle/FontAwesome";
import NotFoundPage from "../../../components/NotFoundPage";
import PurchaseHistory from "./pages/PurchaseHistory";
import MyReviews from "./pages/MyReviews";
import SupportTicket from "./pages/SupportTicket";
import ProductReview from "./pages/ProductReview";
import AllProducts from "./pages/AllProducts";
import Orders from "./pages/Orders";
import ShopSetting from "./pages/ShopSetting";
import Collapse from '@material-ui/core/Collapse';
import PaymentWp from "./pages/payment";
import PaymentSetting from "./pages/PaymentSetting";
import IncomeHistory from "./pages/IncomeHistory";
import StockReport from "./pages/StockReport";
import OrderHistory from "./pages/OrdersHistory";
import WishlistReport from "./pages/WishlistReport";
import CartReport from "./pages/CartReport";
import purchaseHistoryDetails from "./pages/PurcessHistory";
import SupportDetails from "./pages/SupportDetails";
import CartSection from "./pages/CartSection";
import WishlistSection from "./pages/WishlistSection";
import WithdrawRDetails from "./pages/WithdrawRDetails";
import BankSettings from "./pages/BankSettings";
import AddBanks from "./pages/AddBanks";
import EditBanks from "./pages/EditBanks";

const menus = [
    {
        id: 1,
        icon: 'tachometer',
        title: 'Dashboard',
        link: 'dashboard'
    }, {
        id: 2,
        icon: 'file-text-o',
        title: 'Purchase History',
        link: 'purchase-history'
    }, {
        id: 3,
        icon: 'shopping-cart',
        title: 'Cart',
        link: 'CartSection'
    }, {
        id: 4,
        icon: 'heart-o',
        title: 'Wishlist',
        link: 'wishlistSection'
    }, {
        id: 5,
        icon: 'user',
        title: 'My Profile',
        link: 'profile'
    }, {
        id: 6,
        icon: 'star',
        title: 'My Reviews',
        link: 'my-reviews'
    }, {
        id: 7,
        icon: 'life-ring',
        title: 'Support Ticket',
        link: 'support-ticket'
    }, {
        id: 8,
        icon: 'diamond',
        title: 'Products',
        link: 'all-products'
    }, {
        id: 9,
        icon: 'file-text-o',
        title: 'Orders',
        link: 'orders'
    }, {
        id: 10,
        icon: 'star-o',
        title: 'Product Reviews',
        link: 'product-reviews'
    }, {
        id: 11,
        icon: 'cog',
        title: 'Shop Settings',
        link: 'shop-settings'
    }, {
        id: 12,
        icon: 'credit-card-alt',
        title: 'Payments',
        link: 'payments'
    }, {
        id: 13,
        icon: 'cc-mastercard',
        title: 'Payments Setting',
        link: 'paymentSetting'
    }, {
        id: 14,
        icon: 'life-ring',
        title: 'Report',
        menus: [
            {
                name: 'Income History',
                link: 'income',
                id: 15
            },
            {
                name: 'Stock Report',
                link: 'stock',
                id: 16
            },
            {
                name: 'Order History',
                link: 'order',
                id: 17
            },
            {
                name: 'Wishlist Products Report',
                link: 'wishlist',
                id: 18
            },
            {
                name: 'Cart Products Report',
                link: 'CartProducts',
                id: 19
            },
            {
                name: 'Product Reviews',
                link: 'product-reviews',
                id: 20
            },
        ],
    },
];

const VendorLayout = (props) => {
    const { match } = props;

    const [menuId, setMenuId] = useState(0);
    return (
        <Fragment>
            <Header2/>
            <Grid className="userPanel">
                <Grid className="container" container spacing={3}>
                    <Grid item md={3} sm={4} xs={12}>
                        <Grid className="leftSidebar">
                            <Grid className="profile">
                                <Grid className="thumb">
                                    <img src={userImg} alt="userImg" />
                                </Grid>
                                <h2>Mr. seller</h2>
                            </Grid>
                            <List className="dashboardSideMMenu">
                                {menus.map(item => (
                                    <ListItem key={item.id}>
                                        {(item.link === 'cart' || item.link === 'wishlist') ?
                                            <NavLink exact activeClassName="active"
                                                to={`/${item.link}`}><FontAwesome
                                                    name={item.icon} />{item.title}</NavLink>
                                            : <Fragment>
                                                {item.menus ? <Fragment>
                                                    <p onClick={() => setMenuId(item.id === menuId ? 0 : item.id)}><FontAwesome name={item.icon} />
                                                        {item.title}</p>
                                                    <Collapse in={menuId === item.id} timeout="auto" unmountOnExit>
                                                        <List>
                                                            {item.menus.map(submenu => (
                                                                <ListItem><NavLink exact activeClassName=""
                                                                    to={`${match.path}/${submenu.link}`}>{submenu.name}</NavLink></ListItem>
                                                            ))}
                                                        </List>
                                                    </Collapse>
                                                </Fragment>
                                                    : <NavLink exact activeClassName="active"
                                                        to={`${match.path}/${item.link}`}><FontAwesome
                                                            name={item.icon} />{item.title}</NavLink>}
                                            </Fragment>}
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item md={9} sm={8} xs={12}>
                        <Grid className="mainBody">
                            <Switch>
                                <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/dashboard`} component={AccountDashboard} />
                                <Route exact path={`${match.path}/purchase-history`} component={PurchaseHistory} />
                                <Route exact path={`${match.path}/profile`} component={Profile} />
                                <Route exact path={`${match.path}/my-reviews`} component={MyReviews} />
                                <Route exact path={`${match.path}/support-ticket`} component={SupportTicket} />
                                <Route exact path={`${match.path}/product-reviews`} component={ProductReview} />
                                <Route exact path={`${match.path}/all-products`} component={AllProducts} />
                                <Route exact path={`${match.path}/orders`} component={Orders} />
                                <Route exact path={`${match.path}/shop-settings`} component={ShopSetting} />
                                <Route exact path={`${match.path}/payments`} component={PaymentWp} />
                                <Route exact path={`${match.path}/paymentSetting`} component={PaymentSetting} />
                                <Route exact path={`${match.path}/income`} component={IncomeHistory} />
                                <Route exact path={`${match.path}/stock`} component={StockReport} />
                                <Route exact path={`${match.path}/order`} component={OrderHistory} />
                                <Route exact path={`${match.path}/wishlist`} component={WishlistReport} />
                                <Route exact path={`${match.path}/CartProducts`} component={CartReport} />
                                <Route exact path={`${match.path}/Pursessdetails`} component={purchaseHistoryDetails}/>
                                <Route exact path={`${match.path}/supportdetails`} component={SupportDetails}/>
                                <Route exact path={`${match.path}/CartSection`} component={CartSection}/>
                                <Route exact path={`${match.path}/wishlistSection`} component={WishlistSection}/>
                                <Route exact path={`${match.path}/WithdrawDetails`} component={WithdrawRDetails}/>
                                <Route exact path={`${match.path}/banksettings`} component={BankSettings}/>
                                <Route exact path={`${match.path}/addbank`} component={AddBanks}/>
                                <Route exact path={`${match.path}/editbank`} component={EditBanks}/>
                                <Route exact component={NotFoundPage} />
                            </Switch>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Fragment>
    )
};

export default VendorLayout;