import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

class ChangePassword extends Component {
    state = {
        old_pass: '',
        new_pass: '',
        confirm_pass: '',
        events: '',
        notes: '',
        error: {}
    }


    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { old_pass,
            new_pass,
            confirm_pass,
            events,
            notes, error } = this.state;

        if (old_pass === '') {
            error.old_pass = "Please enter your old password";
        }
        if (new_pass === '') {
            error.new_pass = "Please enter your new password";
        }
        if (confirm_pass === '') {
            error.confirm_pass = "Please enter your confirm password";
        }
        if (events === '') {
            error.events = "Select your event list";
        }
        if (notes === '') {
            error.notes = "Please enter your note";
        }


        if (error) {
            this.setState({
                error
            })
        }
        if (error.old_pass === ''  && error.email === '' && error.confirm_pass === '' && error.new_pass === '' && error.events === '' && error.notes === '') {
            this.setState({
                old_pass: '',
                new_pass: '',
                events: '',
                notes: '',
                error: {}
            })
        }
    }
    
    render(){
        
        const { old_pass,
            new_pass,
            confirm_pass,
            error } = this.state;

    return (
        <Grid className="changePass changePasswp">
            <form onSubmit={this.subimtHandler}>
                <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                        <div className="form-field mb-20">
                            <label htmlFor="">Old Password</label>
                            <TextField
                                fullWidth
                                name="old_pass"
                                value={old_pass}
                                onChange={this.changeHandler}
                                type="password"
                                className="formInput mt-0 radiusNone bdbNone"
                            />
                            <p>{error.old_pass ? error.old_pass : ''}</p>
                        </div>
                        <div className="form-field  mb-20">
                            <label htmlFor="">New Password</label>
                            <TextField
                                fullWidth
                                name="new_pass"
                                value={new_pass}
                                onChange={this.changeHandler}
                                type="password"
                                className="formInput mt-0 radiusNone bdbNone"
                            />
                            <p>{error.new_pass ? error.new_pass : ''}</p>
                        </div>

                        <div className="form-field">
                            <label htmlFor="">Confirm Password</label>
                            <TextField
                                fullWidth
                                name="confirm_pass"
                                value={confirm_pass}
                                onChange={this.changeHandler}
                                type="password"
                                className="formInput mt-0 radiusNone bdbNone"
                            />
                            <p>{error.confirm_pass ? error.confirm_pass : ''}</p>
                        </div>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <div className="form-submit">
                            <button type="submit" className="cBtn cBtnLarge cBtnBlack">Send Message</button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
  }
};

export default ChangePassword;