import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {
    decrementQuantity,
    incrementQuantity,
    removeFromCart
} from "../../store/actions/action";
import { totalPrice } from "../../utils";

import closeIcon from '../../images/icon/close-red-icon.png';

import './style.scss';
import { Helmet } from "react-helmet";

const CartPage = ({
    cartList,
    symbol,
    incrementQuantity,
    decrementQuantity,
    removeFromCart
}) => {

    const [currentPage, SetCurrentPage] = useState(1);
    const [rowPerPage, SetRowPerPage] = useState(5);
    const [discount, SetDiscount] = useState(0);

    const IndexOfLastRow = currentPage * rowPerPage;
    const IndexOfFirstRow = IndexOfLastRow - rowPerPage;
    const currentRow = cartList.slice(IndexOfFirstRow, IndexOfLastRow);
    // for pagination
    const numbers = [];
    for (var i = 1; i <= Math.ceil(cartList.length / rowPerPage); i++) {
        numbers.push(i);
    }
    const renderPageNumbers = numbers.map(item => (
        <ListItem key={item} className={item === currentPage ? 'active' : null}
            onClick={() => SetCurrentPage(item)}>{item}</ListItem>
    ));

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - Cart</title>
            </Helmet>
            <Grid className="cartListWrapper ptb-60">
                <Grid className="container" container spacing={3}>
                    <Grid item xs={12}>
                        <Grid className="productTable table-responsive">
                            <Grid className="productTbHead">
                                <Grid className="prdTh">Image</Grid>
                                <Grid className="prdTh prdTitle">Product Name</Grid>
                                <Grid className="prdTh">Quantity</Grid>
                                <Grid className="prdTh">Price</Grid>
                                <Grid className="prdTh">Total</Grid>
                                <Grid className="prdTh prdActions">Action</Grid>
                            </Grid>
                            <Grid className="productTbBody">
                                {currentRow.length > 0 ? currentRow.map(item => (
                                    <Grid className="prdTbRow" key={item.id}>
                                        <Grid className="prdTh prdThumb">
                                            <img src={item.pictures[0]} alt="preview" />
                                        </Grid>
                                        <Grid className="prdTh prdTitle">
                                            <h4>{item.name}</h4>
                                            <p>Category : <span>{item.category}</span></p>
                                        </Grid>
                                        <Grid className="prdTh">
                                            <Grid className="selectQty">
                                                <Button onClick={() => decrementQuantity(item)}>-</Button>
                                                <input
                                                    value={item.qty}
                                                    onChange={() => console.log()}
                                                    type="text" />
                                                <Button onClick={() => incrementQuantity(item)}>+</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid className="prdTh"><strong>{symbol}{item.price}</strong></Grid>
                                        <Grid
                                            className="prdTh prdStock"><strong>{symbol}{item.price * item.qty}</strong></Grid>
                                        <Grid className="prdTh prdActions">
                                            <List>
                                                <ListItem onClick={() => removeFromCart(item)}>
                                                    <img src={closeIcon} alt="close icon" />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                )) : <p className="noProdFound">No Results Found</p>}
                            </Grid>
                        </Grid>
                    </Grid>
                    {cartList.length <= rowPerPage ? null : <Grid item xs={12}>
                        <List className="paginationWrapper">
                            {renderPageNumbers}
                        </List>
                    </Grid>}
                    <Grid item xs={12}>
                        <Button component={Link} to="/shop" className="cBtn cBtnBlack mt-20 mb-20">Continue Shopping</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid className="cartStatus">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid className="cartTotals">
                                        <h4>Cart Total</h4>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Total product</TableCell>
                                                    <TableCell align="right">{cartList.length}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Sub Price</TableCell>
                                                    <TableCell align="right">{symbol}{totalPrice(cartList)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Total Price</TableCell>
                                                    <TableCell
                                                        align="right">{symbol}{totalPrice(cartList)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="right" colSpan={2}>
                                                        <Button component={Link} to="/checkout"
                                                            className="cBtn cBtnTheme mt-20">Proceed to
                                                            checkout</Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        cartList: state.cartList.cart,
        symbol: state.data.symbol
    }
};

export default connect(mapStateToProps, { incrementQuantity, decrementQuantity, removeFromCart })(CartPage);