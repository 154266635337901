import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";

import SupportTicketDetails from '../components/SupportTicketDetails'


const SupportTicketdeta = (props) => {

    return (
        <div className="DataTableArea cardWrap">
            <div className="DtableHeaderArea">
                <h2 className="DtableHeader">Support Ticket Details</h2>
            </div>
            <SupportTicketDetails/>
        </div>
    )
};

export default SupportTicketdeta;