import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import './style.scss';

import SupportTicketDetails from '../../Admin/components/SupportTicketDetails'



const SupportDetails = (props) => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | User Support Details</title>
            </Helmet>
            <SupportTicketDetails />
        </Fragment>
    )
};

export default SupportDetails;