import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import './style.scss';

import WithdrawDetails from '../../../WithdrawDetails'




const WithdrawRDetails = (props) => {
    return (
        <Fragment class="add-cart">
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | Withdraw Details</title>
            </Helmet>
            <WithdrawDetails />
        </Fragment>
    )
};

export default WithdrawRDetails;