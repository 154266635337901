import React, {Fragment} from 'react';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import Header2 from "../../Header2";
import Footer from "../../../components/Footer";
import AccountDashboard from "./pages/AccountDashboard";
import AccountProfile from "./pages/AccountProfile";

import userImg from '../../../images/author/author1.jpg';

import './style.scss';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../../../components/UiStyle/FontAwesome";
import NotFoundPage from "../../../components/NotFoundPage";
import AccountPurchaseHistory from "./pages/AccountPurchaseHistory";
import AccountMyReviews from "./pages/AccountMyReviews";
import AccountSupportTicket from "./pages/AccountSupportTicket";
import purchaseHistoryDetails from "./pages/PurcessHistory";
import SupportDetails from "./pages/SupportDetails";
import CartSection from "./pages/CartSection";
import WishlistSection from "./pages/WishlistSection";

const menus = [
    {
        id: 1,
        icon: 'tachometer',
        title: 'Dashboard',
        link: 'dashboard'
    }, {
        id: 2,
        icon: 'file-text-o',
        title: 'Purchase History',
        link: 'purchase-history'
    }, {
        id: 3,
        icon: 'shopping-cart',
        title: 'Cart',
        link: 'CartSection'
    }, {
        id: 4,
        icon: 'heart-o',
        title: 'Wishlist',
        link: 'wishlistSection'
    }, {
        id: 5,
        icon: 'user',
        title: 'My Profile',
        link: 'profile'
    }, {
        id: 6,
        icon: 'star',
        title: 'My Reviews',
        link: 'my-reviews'
    }, {
        id: 7,
        icon: 'life-ring',
        title: 'Support Ticket',
        link: 'support-ticket'
    },
];

const AccountLayout = (props) => {
    const {match} = props;
    return (
        <Fragment>
            <Header2/>
            <Grid className="userPanel">
                <Grid className="container" container spacing={3}>
                    <Grid item md={3} sm={4} xs={12}>
                        <Grid className="leftSidebar">
                            <Grid className="profile">
                                <Grid className="thumb">
                                    <img src={userImg} alt="userImg"/>
                                </Grid>
                                <h2>Mr. user</h2>
                            </Grid>
                            <List className="dashboardSideMMenu">
                                {menus.map(item => (
                                    <ListItem key={item.id}>
                                        {(item.link === 'cart' || item.link === 'wishlist') ?
                                            <NavLink exact activeClassName="active"
                                                     to={`/${item.link}`}><FontAwesome
                                                name={item.icon}/>{item.title}</NavLink>
                                            : <NavLink exact activeClassName="active"
                                                       to={`${match.path}/${item.link}`}><FontAwesome
                                                name={item.icon}/>{item.title}</NavLink>}
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item md={9} sm={8} xs={12}>
                        <Grid className="mainBody">
                            <Switch>
                                <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`}/>
                                <Route exact path={`${match.path}/dashboard`} component={AccountDashboard}/>
                                <Route exact path={`${match.path}/purchase-history`}
                                       component={AccountPurchaseHistory}/>
                                <Route exact path={`${match.path}/profile`} component={AccountProfile}/>
                                <Route exact path={`${match.path}/my-reviews`} component={AccountMyReviews}/>
                                <Route exact path={`${match.path}/support-ticket`} component={AccountSupportTicket}/>
                                <Route exact path={`${match.path}/Pursessdetails`} component={purchaseHistoryDetails}/>
                                <Route exact path={`${match.path}/supportdetails`} component={SupportDetails}/>
                                <Route exact path={`${match.path}/CartSection`} component={CartSection}/>
                                <Route exact path={`${match.path}/wishlistSection`} component={WishlistSection}/>
                                <Route exact component={NotFoundPage}/>
                            </Switch>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer/>
        </Fragment>
    )
};

export default AccountLayout;