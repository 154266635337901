import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

const reviews = [
    {
        id: 1,
        code: '#5845',
        title: 'tBeet Root - (100% organic) - Freeze Dried Powder',
        rating: '3',
        comment: '	this product is good',
        publish_approval: 'Approved'
    }, {
        id: 2,
        code: '#5845',
        title: 'tBeet Root - (100% organic) - Freeze Dried Powder',
        rating: '3',
        comment: '	this product is good',
        publish_approval: 'Pending'
    }, {
        id: 3,
        code: '#5845',
        title: 'tBeet Root - (100% organic) - Freeze Dried Powder',
        rating: '3',
        comment: '	this product is good',
        publish_approval: 'Approved'
    }, {
        id: 4,
        code: '#5845',
        title: 'tBeet Root - (100% organic) - Freeze Dried Powder',
        rating: '3',
        comment: '	this product is good',
        publish_approval: 'Approved'
    }, {
        id: 5,
        code: '#5845',
        title: 'tBeet Root - (100% organic) - Freeze Dried Powder',
        rating: '3',
        comment: '	this product is good',
        publish_approval: 'Approved'
    },
];

const MyReviews = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | My Reviews</title>
            </Helmet>
            <Grid className="cardWrap">
                <Grid className="cardTitle">
                    <h4 className="title">My Reviews</h4>
                </Grid>
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Review Code</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell>Rating</TableCell>
                                <TableCell>Comment</TableCell>
                                <TableCell align="right"> Publish Approval</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reviews.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell><strong>{item.code}</strong></TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.rating}</TableCell>
                                    <TableCell>{item.comment}</TableCell>
                                    <TableCell align="right"><span
                                        className={`badge ${item.publish_approval.toLowerCase()}`}>{item.publish_approval}</span></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default MyReviews;