import React from 'react';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";

// images
import logo from '../../images/icon/logo.png';
import FontAwesome from "../UiStyle/FontAwesome";

import './style.scss';

const Footer = () => {
    return (
        <footer>
            <Grid className="footerTop">
                <Grid className="container" container spacing={3}>
                    <Grid item md={3} xs={12}>
                        <Grid className="fWidget widgetCompany">
                            <Grid className="fLogo">
                                <img src={logo} alt="footer logo" />
                            </Grid>
                            <p>rCommerce is one of the most popular online marketplaces used by both individual as well as
                                businesses, and the site is available in many different countries and languages.</p>
                        </Grid>
                    </Grid>
                    <Grid item md={3} sm={4} xs={12}>
                        <Grid className="fWidget widgetMenus">
                            <h3 className="footerTitle">Get to know us</h3>
                            <List className="fList">
                                <ListItem><Link to="/">Home</Link></ListItem>
                                <ListItem><Link to="/about-us">About</Link></ListItem>
                                <ListItem><Link to="/shop">Shop</Link></ListItem>
                                <ListItem><Link to="/blog">Blog</Link></ListItem>
                                <ListItem><Link to="/contact">Contact</Link></ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item md={3} sm={4} xs={12}>
                        <Grid className="fWidget widgetSpecialLinks">
                            <h3 className="footerTitle">Special Links</h3>
                            <List className="fList">
                                <ListItem><Link to="/">Home</Link></ListItem>
                                <ListItem><Link to="/about-us">About</Link></ListItem>
                                <ListItem><Link to="/shop">Shop</Link></ListItem>
                                <ListItem><Link to="/blog">Blog</Link></ListItem>
                                <ListItem><Link to="/contact">Contact</Link></ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item md={3} sm={4} xs={12}>
                        <Grid className="fWidget widgetContact">
                            <h3 className="footerTitle">Get in Touch</h3>
                            <List>
                                <ListItem><FontAwesome name="map-marker" />Estrada Abrantes 22, Lison,
                                    Portugal</ListItem>
                                <ListItem><FontAwesome name="envelope" />hello@rCommerceer.com</ListItem>
                                <ListItem><FontAwesome name="phone" />+12 123 456 789</ListItem>
                            </List>

                            <Grid className="socialLinks">
                                <Link to="/"><FontAwesome name="facebook" /></Link>
                                <Link to="/"><FontAwesome name="twitter" /></Link>
                                <Link to="/"><FontAwesome name="linkedin" /></Link>
                                <Link to="/"><FontAwesome name="instagram" /></Link>
                                <Link to="/"><FontAwesome name="youtube-play" /></Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="footerBottom">
                <Grid className="container" container spacing={3}>
                    <Grid item md={6} sm={4} xs={12}>
                        <p className="copyright">© rCommerce - All Rights Reserved {new Date().getFullYear()}</p>
                    </Grid>
                    <Grid item md={6} sm={8} xs={12}>
                        <List className="fbLinks">
                            <ListItem><Link to="/Cookie">Cookies Policy</Link></ListItem>
                            <ListItem><Link to="/privacypolicy">Privacy Policy</Link></ListItem>
                            <ListItem><Link to="/TermsConditions">Terms and Conditions</Link></ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </footer>
    )
};

export default Footer;