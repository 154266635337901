import React from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  
const Viewproduct = () => {
    const [forms, setForms] = React.useState({
        firstname: '',
        Lastname: '',
        email: '',
        phone: '',
        Password: '',
        confirm_Password: '',
    });

    // forms handler
    const changeHandler = e => {
        setForms({...forms, [e.target.name]: e.target.value})
    };

    const submitHandler = (e) => {
        e.preventDefault();
        toast.success('Form Submitted!')
    };

    const classes = useStyles();
    const [age, setAge] = React.useState('');
  
    const inputLabel = React.useRef(null);
  
  
    const handleChange = event => {
      setAge(event.target.value);
    };
    return (
        <Grid className="DataTableArea cardWrap">
            <Grid className="DtableHeaderArea">
                <h2 className="DtableHeader">View Product</h2>
            </Grid>
            <Grid className="changePass cbt-wrap">
                <form onSubmit={submitHandler}>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <TextField
                                fullWidth
                                label="Product Name *"
                                name="firstname"
                                disabled
                                value={'abc9903429138'}
                                onChange={changeHandler}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                        <Grid item md={12} xs={12}>
                            <TextField
                                fullWidth
                                label="Select Category *"
                                name="firstname"
                                value={'Fashion'}
                                onChange={changeHandler}
                                type="text"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
 
                        <Grid item md={12} xs={12}>
                            <TextField
                                fullWidth
                                label="Brand Name*"
                                name="brand"
                                disabled
                                value={'Easy'}
                                onChange={changeHandler}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                        <Grid item md={12} xs={12}>
                            <TextField
                                fullWidth
                                label="Unit *"
                                name="email"
                                disabled
                                value={'10g'}
                                onChange={changeHandler}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                        <Grid item lg={12} xs={12}>
                            <TextareaAutosize 
                            label="Short Description *"
                            aria-label="empty textarea" 
                            disabled
                            placeholder="Short Description *" 
                            value={'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, enim!'}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
};

export default Viewproduct;