import React, {Fragment, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SimpleReactValidator from 'simple-react-validator';
import GoogleMap from "./components/google_map";

import './style.scss';

const ContactPage = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({...forms, [e.target.name]: e.target.value})
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            setForms({
                name: '',
                email: '',
                subject: '',
                message: ''
            })
        } else {
            validator.showMessages();
        }
    };

    return (
        <Fragment>
            <Grid className="contactWrapper ptb-60">
                <Grid className="container" container spacing={3}>
                    <Grid item md={4} xs={12}>
                        <Grid className="contactContent">
                            <List>
                                <ListItem>
                                    <Grid className="icon">
                                        <FontAwesome name="facebook"/>
                                    </Grid>
                                    <Grid className="content">
                                        <h4>ADDRESS:</h4>
                                        <p>1234, Contrary To Popular Sed Ut Perspiciatis Unde 1234</p>
                                    </Grid>
                                </ListItem>
                                <ListItem>
                                    <Grid className="icon">
                                        <FontAwesome name="phone"/>
                                    </Grid>
                                    <Grid className="content">
                                        <h4>EMAIL ADDRESS:</h4>
                                        <p>Info@Yoursite.Com</p>
                                        <p>Info@Yoursite.Com</p>
                                    </Grid>
                                </ListItem>
                                <ListItem>
                                    <Grid className="icon">
                                        <FontAwesome name="envelope"/>
                                    </Grid>
                                    <Grid className="content">
                                        <h4>PHONE NUMBER:</h4>
                                        <p>+0123456789</p>
                                        <p>+1234567890</p>
                                    </Grid>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Grid className="contactForm">
                            <form onSubmit={(e) => submitHandler(e)}>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className="formDefault"
                                            fullWidth
                                            value={forms.name}
                                            variant="outlined"
                                            name="name"
                                            label="Name"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                        />
                                        {validator.message('name', forms.name, 'required|alpha_space')}
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className="formDefault"
                                            fullWidth
                                            value={forms.email}
                                            variant="outlined"
                                            name="email"
                                            label="Email"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                        />
                                        {validator.message('email', forms.email, 'required|email')}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className="formDefault"
                                            fullWidth
                                            value={forms.subject}
                                            variant="outlined"
                                            name="subject"
                                            label="Subject"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                        />
                                        {validator.message('subject', forms.subject, 'required')}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className="formDefault"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={forms.message}
                                            variant="outlined"
                                            name="message"
                                            label="Message"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                        />
                                        {validator.message('message', forms.message, 'required')}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" className="cBtn cBtnTheme">Send Message</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <GoogleMap/>
        </Fragment>
    )
};

export default ContactPage;