import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SimpleReactValidator from 'simple-react-validator';

// images
import icon from '../../images/icon/small-logofav.png';
import grayLine from '../../images/icon/grayline.png';

import './style.scss';
import {toast} from "react-toastify";
import Hidden from "@material-ui/core/Hidden";

class Subscription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: ''
        };
        this.validator = new SimpleReactValidator({
            messages: {
                email: 'That is not an email.',
                // OR
                default: 'Validation has failed!'  // will override all messages
            },
        });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    submitHandler = (e) => {
        e.preventDefault();

        if (this.validator.allValid()) {
            toast.success('Successfully subscribe!');
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    render() {

        const {email} = this.state;

        return (
            <Grid className="subscriptionWrapper" style={{background: `url(${grayLine}) left top repeat`}}>
                <Grid className="container" container spacing={3}>
                    <Hidden smDown><Grid item md={1} xs={12}/></Hidden>
                    <Grid item md={10} xs={12}>
                        <Grid className="subscTitle">
                            <Grid className="icon">
                                <img src={icon} alt="icon"/>
                            </Grid>
                            <h2>Subscribe to our newsletter</h2>
                        </Grid>
                        <form className="subscForm" onSubmit={this.submitHandler}>
                            <TextField
                                fullWidth
                                name="email"
                                className="inputField"
                                placeholder="Enter your email"
                                value={email}
                                onChange={this.changeHandler}
                            />
                            <Button type="submit" className="cBtn cBtnRadius">Subscribe</Button>
                        </form>
                        {this.validator.message('title', this.state.email, 'required|email')}
                        <p>There are many variations of available, but the majority have suffered.</p>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default Subscription;