import React, {Component, Fragment} from 'react';
import {Route} from "react-router-dom";
import Header from "../Header";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";
import ScrollToTop from "../../components/ScrollToTop";

export class PrivateRoute extends Component {
    render() {
        const Component = this.props.component;
        return (
            <Fragment>
                <Header/>
                {this.props.bcrumb ? <BreadCrumb title={this.props.title}/> : ''}
                <Route {...this.props} render={props => <Component {...props} />}/>
                <Footer/>
                <ScrollToTop/>
            </Fragment>
        );
    }
}

export default PrivateRoute;
