import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import { Link } from "react-router-dom";
import { addToCart, removeFromWishList } from "../../store/actions/action";

import closeIcon from '../../images/icon/close-red-icon.png';

import './style.scss';
import { Helmet } from "react-helmet";

const WishList = ({ w_list, addToCart, removeFromWishList, symbol }) => {
    const [currentPage, SetCurrentPage] = useState(1);
    const [rowPerPage, SetRowPerPage] = useState(5);

    const IndexOfLastRow = currentPage * rowPerPage;
    const IndexOfFirstRow = IndexOfLastRow - rowPerPage;
    const currentRow = w_list.slice(IndexOfFirstRow, IndexOfLastRow);
    // for pagination
    const numbers = [];
    for (var i = 1; i <= Math.ceil(w_list.length / rowPerPage); i++) {
        numbers.push(i);
    }
    const renderPageNumbers = numbers.map(item => (
        <ListItem key={item} className={item === currentPage ? 'active' : null}
            onClick={() => SetCurrentPage(item)}>{item}</ListItem>
    ));

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - WishList</title>
            </Helmet>
            <Grid className="wishListWrapper ptb-60">
                <Grid className="container" container spacing={3}>
                    <Grid item xs={12}>
                        <Grid className="productTable table-responsive">
                            <Grid className="productTbHead">
                                <Grid className="prdTh">Image</Grid>
                                <Grid className="prdTh prdTitle">Product Name</Grid>
                                <Grid className="prdTh">Price</Grid>
                                <Grid className="prdTh">Stock</Grid>
                                <Grid className="prdTh prdActions">Action</Grid>
                            </Grid>
                            <Grid className="productTbBody">
                                {currentRow.length > 0 ? currentRow.map(item => (
                                    <Grid className="prdTbRow" key={item.id}>
                                        <Grid className="prdTh prdThumb">
                                            <img src={item.pictures[0]} alt="preview" />
                                        </Grid>
                                        <Grid className="prdTh prdTitle">
                                            <h4><Link to={`/product/${item.id}`}>{item.name}</Link></h4>
                                            <p>Category : <span>{item.category}</span></p>
                                        </Grid>
                                        <Grid className="prdTh"><strong>{symbol}{item.price}</strong></Grid>
                                        <Grid
                                            className="prdTh prdStock">{item.stock > 0
                                                ? <>In Stock<strong>({item.stock})</strong></>
                                                : 'Out of Stock'}</Grid>
                                        <Grid className="prdTh prdActions">
                                            <List>
                                                <ListItem
                                                    onClick={() => addToCart(item, 1, item.colors[0], item.size[0]) + removeFromWishList(item)}><FontAwesome
                                                        name="cart-plus" /></ListItem>
                                                <ListItem onClick={() => removeFromWishList(item)}><img src={closeIcon}
                                                    alt="close icon" /></ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                )) : <p className="noProdFound">No Results Found</p>}
                            </Grid>
                        </Grid>
                    </Grid>
                    {w_list.length <= rowPerPage ? null : <Grid item xs={12}>
                        <List className="paginationWrapper">
                            {renderPageNumbers}
                        </List>
                    </Grid>}
                </Grid>
            </Grid>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        w_list: state.wishList.w_list,
        symbol: state.data.symbol
    }
};

export default connect(mapStateToProps, { addToCart, removeFromWishList })(WishList);