import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

const reviews = [
    {
        id: 1,
        code: 'Alovera jel',
        title: 'Hair Care',
        rating: '	Simplee',
        comment: '	83',
    }, {
        id: 2,
        code: 'Brazil Nuts - (100% organic) - Whole Nut',
        title: 'Super Foods',
        rating: 'We are amazonians',
        comment: '	78',
    }, {
        id: 3,
        code: 'Beet Root - (100% organic) - Freeze Dried Powder',
        title: 'Super Foods',
        rating: 'We are amazonians',
        comment: '	57',
    }, {
        id: 4,
        code: 'Samsung Galaxy A20 Smartphone 6.4"',
        title: 'Mobiles',
        rating: '	Samsung',
        comment: '54',
    }, {
        id: 5,
        code: 'Mini MP3 Player',
        title: '	Home Audio',
        rating: '	Sony',
        comment: '	98',
    }, {
        id: 6,
        code: 'Samsung Galaxy J7 Pro Smartphone',
        title: 'Mobiles',
        rating: '	Samsung',
        comment: '	65',
    },
];

const CartReport = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | My Reviews</title>
            </Helmet>
            <Grid className="cardWrap">
                <Grid className="cardTitle">
                    <h4 className="title">Cart Products Report</h4>
                </Grid>
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Product Name</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Brand</TableCell>
                                <TableCell align="right">Stock</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reviews.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell><strong>{item.code}</strong></TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.rating}</TableCell>
                                    <TableCell align="right">{item.comment}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default CartReport;