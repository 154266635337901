import React from 'react';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../UiStyle/FontAwesome";
import {Link} from "react-router-dom";

// images
import blogImg1 from '../../images/blog/blog-img1.jpg';
import blogImg2 from '../../images/blog/blog-img2.jpg';
import blogImg3 from '../../images/blog/blog-img3.jpg';

import './style.scss';
import SectionTitle from "../SectionTitle";

const blogs = [
    {
        title: 'There are many variations Lorem',
        screens: blogImg1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        title: 'There are many variations oem',
        screens: blogImg2,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicim libero molestias odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    }, {
        title: 'There are many variations oforem',
        screens: blogImg3,
        description: 'Lorem ipsum dolor sit amet, consectetur adictetur errore iure, laborum libero  odio optio.',
        author: 'admin',
        comments: 2,
        create_at: '18 jul 2020'
    },
];

const BlogSection = () => {
    return (
        <Grid className="blogSection pt-10 pb-60">
            <Grid className="container" container spacing={3}>
                <Grid item xs={12}>
                    <SectionTitle
                        title="Latest Blog"
                        link="/"
                        linkText="See All"
                    />
                </Grid>
                {blogs.map((item, i) => {
                    return (
                        <Grid key={i} item md={4} sm={6} xs={12}>
                            <Grid className="blogItem">
                                <Grid className="thumb">
                                    <Link to="/blog-details-leftsidebar/1"><img src={item.screens} alt="blogImg1"/></Link>
                                </Grid>
                                <Grid className="content">
                                    <h2><Link to="/blog-details-leftsidebar/1">{item.title}</Link></h2>
                                    <p>{item.description}</p>
                                    <Grid className="blogMeta">
                                        <List>
                                            <ListItem><FontAwesome name="comment-o"/>{item.comments} comments</ListItem>
                                            <ListItem><FontAwesome name="clock-o"/>{item.create_at}</ListItem>
                                            <ListItem><Link to="/blog-details-leftsidebar/1">Read More</Link></ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
};
export default BlogSection;