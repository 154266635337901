import React from 'react';
import {Grid} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import {Link} from "react-router-dom";

import FileDrop from './dropzone/index'


const Primary = (props) => {
  return (
      <div className="DataTableArea">
        <div className="DtableHeaderArea">
            <h2 className="DtableHeader">Primary Settings</h2>
        </div>

        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid className="file-d">
                  <span>Change Header Logo</span>
                      <FileDrop/>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid className="file-d">
                    <span>Change Header Logo</span>
                      <FileDrop/>
                </Grid>
            </Grid>
        </Grid>
      </div>
    
  );
}

export default Primary;