import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {isEquals} from "../../../utils";

const colors = [
    {
        title: 'Any',
        color: null
    },
    {
        title: 'white',
        color: 'white'
    },
    {
        title: 'gray',
        color: 'gray'
    },
    {
        title: 'black',
        color: 'black'
    },
    {
        title: 'blue',
        color: 'blue'
    },
    {
        title: 'green',
        color: 'green'
    },
    {
        title: 'yellow',
        color: 'yellow'
    },
    {
        title: 'red',
        color: 'red'
    },
    {
        title: 'purple',
        color: 'purple'
    },
];

const WidgetColor = ({selected_color, colorHandler}) => {
    return (
        <Grid className="widget widgetColor">
            <h2 className="widgetTitle">Filter by Color</h2>
            <List className="widgetList">
                {colors.map((item, i) => {
                    return <ListItem key={i}
                                     className={isEquals(selected_color, item.color) ? 'active' : ''}
                                     onClick={colorHandler(item.color)}>
                        <span/>
                        {item.title}
                    </ListItem>
                })}
            </List>
        </Grid>
    )
};

export default WidgetColor;