import React from 'react';
import Slider from "react-slick";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import FontAwesome from "../UiStyle/FontAwesome";
import Button from "@material-ui/core/Button";

// images
import heroBg1 from '../../images/bg/hero2.jpg';

import './style.scss';


function SampleNextArrow(props) {
    const {onClick} = props;
    return (
        <Button onClick={onClick} className="slick-arrow slick-next">
            <FontAwesome name="angle-right"/>
        </Button>
    );
}

function SamplePrevArrow(props) {
    const {onClick} = props;
    return (
        <Button onClick={onClick} className="slick-arrow slick-prev">
            <FontAwesome name="angle-left"/>
        </Button>
    );
}

const HeroArea2 = () => {
    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>
    };

    return (
        <div className="hero_area2">
            <div className="container">
                <Slider className="heroSlider">
                    <div>
                        <Grid className="heroItem" style={{background: `url(${heroBg1}) right center /cover no-repeat`}}>
                            <Grid className="container">
                                <Grid className="col-md-6 offset-md-6">
                                    <Grid className="heroContent">
                                        <h2>GRAB OFFER</h2>
                                        <p>10% OFF ON SUMMER COLLECTION</p>
                                        <div className="btn-area">
                                            <Link className="cBtn cBtnWhite cBtnRadius" to="/shop">CHECK THE COLLECTION</Link>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Slider>
            </div>
        </div>
        
    )
};

export default HeroArea2;