import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Header2 from "../Header2";
import userImg from '../../images/author/author1.jpg';
import userImg2 from '../../images/author/author2.jpg';


import './style.scss';

const SupportTicketDetails = () => {
    return (
        <Fragment>
            <Header2 />
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - Purcess History Details</title>
            </Helmet>
            <Grid className="userPanel userPanel-2">
                <Grid className="container" container spacing={3} >
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <div className="support-wrap">
                                <div className="support-top">
                                    <h2># 10001 Lorem Ipsum</h2>
                                    <p>Lima isa     2019-12-08 18:16:36 +0000 UTC   <span>Solved</span></p>
                                </div>
                                <div className="support-bottom">
                                    <h2>Conversations</h2>
                                    <div className="support-d">
                                        <div className="support-img">
                                            <img src={userImg} alt="" />
                                        </div>
                                        <div className="support-text">
                                            <h5>Mr. Admin</h5>
                                            <span>2020-01-02 13:57:23</span>
                                            <p>Point to be noted</p>
                                        </div>
                                    </div>
                                    <div className="support-d support-d-2">
                                        <div className="support-img">
                                            <img src={userImg2} alt="" />
                                        </div>
                                        <div className="support-text">
                                            <h5>Mr. Admin</h5>
                                            <span>2020-01-02 10:15:46</span>
                                            <p>okay i will fix it</p>
                                        </div>
                                    </div>
                                    <div className="support-d">
                                        <div className="support-img">
                                            <img src={userImg} alt="" />
                                        </div>
                                        <div className="support-text">
                                            <h5>Aliza Anny</h5>
                                            <span>2020-01-02 10:15:46</span>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </div>
                                    <div className="support-d support-d-2">
                                        <div className="support-img">
                                            <img src={userImg2} alt="" />
                                        </div>
                                        <div className="support-text">
                                            <h5>Lima isa</h5>
                                            <span>2020-01-02 10:15:46</span>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Fragment>
    )
};

export default SupportTicketDetails;