import React, {Fragment, useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FontAwesome from "../UiStyle/FontAwesome";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {addToCart, addToCompareList, addToWishList} from "../../store/actions/action";
import ProductQuickView from "../ProductQuickView";
import {isWishListed} from "../../utils";

import './style.scss';

const ProductItem = ({symbol, item, addToCart, addToWishList, addToCompareList, wish_list, girdCollumnType}) => {
    const [preview, setPreview] = useState(0);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Fragment>
            <Grid className={`productStyleOne ${girdCollumnType === 3 ? 'fullWideProduct' : ''}`}>
                <Grid className="thumb">
                    <Link to={`/product/${item.id}`}><img src={item.pictures[preview]} alt="preview"/></Link>
                    {item.discount > 0 ? <span className="discount">-{item.discount}%</span> : ''}
                    {item.new ? <span className="new">new</span> : ''}
                    <List className="productActions">
                        <ListItem title="View Details" onClick={handleOpen} className="quickViewBtn"><FontAwesome
                            name="search"/></ListItem>
                        <ListItem title="Add to Wishlist" onClick={() => addToWishList(item)} className="wishlistBtn">
                            <FontAwesome name={isWishListed(item.id, wish_list) ? 'heart' : 'heart-o'}/>
                        </ListItem>
                        <ListItem title="Compare" onClick={() => addToCompareList(item)}><Link
                            to="/compare"><FontAwesome
                            name="retweet"/></Link></ListItem>
                    </List>

                    <List className="previewProduct">
                        {item.pictures.map((img, i) => {
                            return (
                                <ListItem key={i} className={preview === i ? 'active' : ''}
                                          onClick={() => setPreview(i)}>
                                    <img src={img} alt="preview"/>
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
                {girdCollumnType === 3 ?
                    <Grid className="content">
                        <h3><Link to={`/product/${item.id}`}>{item.name}</Link></h3>
                        <Grid className="productFooter">
                            <p className="price">
                                {symbol}{item.price}
                                {item.salePrice !== item.price ? <del className="oldPrice">{item.salePrice}</del> : ''}
                            </p>
                            <Button onClick={() => addToCart(item, 1, item.colors[preview])}>Add to Cart <FontAwesome
                                name="cart-plus"/></Button>
                        </Grid>
                        <p>{item.shortDetails}</p>
                        <p>{item.shortDetails}</p>
                    </Grid>
                    :
                    <Grid className="content">
                        <h3><Link to={`/product/${item.id}`}>{item.name}</Link></h3>
                        <Grid className="productFooter">
                            <p className="price">
                                {symbol}{item.price}
                                {item.salePrice !== item.price ? <del className="oldPrice">{item.salePrice}</del> : ''}
                            </p>
                            <Button onClick={() => addToCart(item, 1, item.colors[preview], item.size[0])}>Add to
                                Cart <FontAwesome
                                    name="cart-plus"/></Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
           
            <ProductQuickView isWishListed={isWishListed(item.id, wish_list)} addToCart={addToCart}
                              addToWishList={addToWishList} item={item} handleClose={handleClose}
                              open={open}/>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        wish_list: state.wishList.w_list
    }
};

export default connect(mapStateToProps, {addToCart, addToWishList, addToCompareList})(ProductItem);