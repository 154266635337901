import React from 'react';
import { MDBDataTable } from 'mdbreact';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import PurcessHistory from '../components/PurcessHistory'

import {Link} from 'react-router-dom'
 
const ViewOrderDetails = (props) => {

  return (
      <div className="DataTableArea cardWrap">
        <div className="DtableHeaderArea">
            <h2 className="DtableHeader">Vendor Orders</h2>
        </div>
        <PurcessHistory/>
      </div>
    
  );
}

export default ViewOrderDetails;