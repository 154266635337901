import React, {Fragment, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import FontAwesome from "../UiStyle/FontAwesome";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {NavLink} from "react-router-dom";

import './style.scss';
import MegaMenu from "../MegaMenu";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import LeftSideMenu from "../LeftSideMenu";

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/',
        submenu: [
            {
                id: 11,
                title: 'Home-Style-1',
                link: '/'
            },
            {
                id: 12,
                title: 'Home-Style-2',
                link: '/home2'
            },
        ],
    }, {
        id: 2,
        title: 'About Us',
        link: '/about-us',
    }, {
        id: 3,
        title: 'Shop',
        link: '/shop',
        submenu: [
            {
                id: 31,
                title: 'Left Sidebar',
                link: '/shop'
            },
            {
                id: 32,
                title: 'Right Sidebar',
                link: '/shop-rightsidebar'
            },
            {
                id: 32,
                title: 'No Sidebar',
                link: '/shop-nosidebar'
            },
        ],
    }, {
        id: 4,
        title: 'FAQ',
        link: '/faq',
    }, {
        id: 5,
        title: 'Blog',
        link: '/blog',
        submenu: [
            {
                id: 51,
                title: 'Right Sidebar',
                link: '/blog'
            },
            {
                id: 52,
                title: 'Left Sidebar',
                link: '/blog-leftsidebar'
            },
            {
                id: 53,
                title: 'No Sidebar',
                link: '/blog-nosidebar'
            },
            {
                id: 54,
                title: 'Blog Details left Sidebar',
                link: '/blog-details-leftsidebar/1'
            },
            {
                id: 55,
                title: 'Blog Details No Sidebar',
                link: '/blog-details-nosidebar/1'
            },
        ],
    }, {
        id: 6,
        title: 'Contact',
        link: '/contact',
    },
];

const HeaderBottom = () => {
    const [openId, setOpenId] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);
    const [openMega, setOpenMega] = useState(false);
    return (
        <Grid className="headerBottom">
            <Grid className="container" alignItems="center" container spacing={3}>
                <Grid item md={2} xs={8}>
                    <Button disableRipple className="allCatBtn" onClick={() => setOpenMega(true)}>
                        All Categories <FontAwesome name="angle-down"/>
                        <MegaMenu/>
                    </Button>
                    <LeftSideMenu setOpenMega={setOpenMega} openMega={openMega}/>
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={4}>
                        <Button onClick={() => setOpenMenu(!openMenu) + setOpenId(0)}
                                className="hamburgerBtn"><FontAwesome
                            name="bars"/></Button>
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item md={10} xs={12}>
                        <List className="mainMenu">
                            {menus.map((item, i) => {
                                return (
                                    <ListItem key={i}>
                                        {item.submenu ?
                                            <Fragment>
                                                <p>{item.title}</p>
                                                <List className="subMenu">
                                                    <Fragment>
                                                        {item.submenu.map((submenu, i) => {
                                                            return (
                                                                <ListItem key={i}>
                                                                    <NavLink exact activeClassName="active"
                                                                             to={submenu.link}>{submenu.title}</NavLink>
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </Fragment>
                                                </List>
                                            </Fragment>
                                            : <NavLink exact activeClassName="active"
                                                       to={item.link}>{item.title}</NavLink>
                                        }
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Grid>
                </Hidden>
            </Grid>
            <Hidden mdUp>
                <Grid className="px-15">
                    <Collapse in={openMenu} timeout="auto" unmountOnExit>
                        <List className="mobileMenu">
                            {menus.map((item, i) => {
                                return (
                                    <ListItem className={item.id === openId ? 'active' : null} key={i}>
                                        {item.submenu ?
                                            <Fragment>
                                                <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                                    <FontAwesome
                                                        name={item.id === openId ? 'angle-up' : 'angle-down'}/></p>
                                                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                                    <List className="subMenu">
                                                        <Fragment>
                                                            {item.submenu.map((submenu, i) => {
                                                                return (
                                                                    <ListItem key={i}>
                                                                        <NavLink exact activeClassName="active"
                                                                                 to={submenu.link}>{submenu.title}</NavLink>
                                                                    </ListItem>
                                                                )
                                                            })}
                                                        </Fragment>
                                                    </List>
                                                </Collapse>
                                            </Fragment>
                                            : <NavLink exact activeClassName="active"
                                                       to={item.link}>{item.title}</NavLink>
                                        }
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Collapse>
                </Grid>
            </Hidden>
        </Grid>
    )
};

export default HeaderBottom;