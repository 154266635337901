import React, { Fragment } from 'react';
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import Switch from "@material-ui/core/Switch";
import { Helmet } from "react-helmet";

const products = [
    {
        id: 1,
        name: 'Shonali Bank',
        qty: 'XYZ 1321320211321320',
        price: 150,
        sell_price: 160,
        featured: false,
        publication: true,
    }, {
        id: 2,
        name: 'NRB Bank',
        qty: 'DBC xxxxxxxxxxxxxxxxxxx541',
        price: 150,
        sell_price: 160,
        featured: false,
        publication: true,
    }, {
        id: 3,
        name: 'Trust Bank',
        qty: 'ABC xxxxxxxxxxxxxxxxxxx21',
        price: 150,
        sell_price: 160,
        featured: false,
        publication: true,
    },
];

const BankDetails = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - Bank Settings</title>
            </Helmet>
            <Grid className="userPanel userPanel-2">
                <Grid className="container" container spacing={3} >
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="cardWrap mt-30">
                            <Grid className="cardTitle">
                                <h4 className="title">Bank Settings</h4>
                                <Grid className="viewAll"><Link to="/vendor/addbank">Add Bank</Link></Grid>
                            </Grid>
                            <Grid className="defaultTable">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>	Bank Name</TableCell>
                                            <TableCell>Details</TableCell>
                                            <TableCell>	Status</TableCell>
                                            <TableCell align="right">Option</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {products.length <= 0 ? <TableRow>
                                            <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                                        </TableRow> : <Fragment>
                                                {products.map(item => (
                                                    <TableRow key={item.id}>
                                                        <TableCell>{item.id}</TableCell>
                                                        <TableCell>{item.name}</TableCell>
                                                        <TableCell>{item.qty}</TableCell>
                                                        <TableCell>
                                                            <Switch
                                                                color="primary"
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                                                name="ellipsis-v" /></span>
                                                            <Popover
                                                                className="optionsMenu"
                                                                open={open}
                                                                anchorEl={anchorEl}
                                                                onClose={handlePopoverClose}
                                                                disableRestoreFocus
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                            >
                                                                <Link to='/vendor/editbank'>Edit</Link>
                                                                <Link to='/vendor/banksettings'>Delete</Link>
                                                            </Popover>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </Fragment>}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>


        </Fragment>


    )
};

export default BankDetails;