import React from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import FontAwesome from "../UiStyle/FontAwesome";

// image
import banner1 from '../../images/banner1.jpg';
import banner2 from '../../images/banner2.jpg';
import banner3 from '../../images/banner3.jpg';

import './style.scss';

const Banner = () => {
    return (
        <Grid container className="container" spacing={3}>
            <Grid item md={6} xs={12}>
                <Grid className="bannerWrapper">
                    <span className="bannerLine"/>
                    <Grid className="bannerThumb">
                        <Link to="/shop"><img src={banner1} alt="banner1"/></Link>
                    </Grid>
                    <Grid className="bannerContent">
                        <h2>Trending now</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        <Button component={Link} to="/shop">Shop now <FontAwesome name="angle-right"/> </Button>
                    </Grid>
                </Grid>
                <Grid className="bannerWrapper">
                    <span className="bannerLine"/>
                    <Grid className="bannerThumb">
                        <Link to="/shop"><img src={banner2} alt="banner2"/></Link>
                    </Grid>
                    <Grid className="bannerContent textBlack">
                        <h2>Best collections for women</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        <Button component={Link} to="/shop">Shop now <FontAwesome name="angle-right"/> </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
                <Grid className="bannerWrapper lastBanner">
                    <span className="bannerLine"/>
                    <Grid className="bannerThumb">
                        <Link to="/shop"><img src={banner3} alt="banner3"/></Link>
                    </Grid>
                    <Grid className="bannerContent">
                        <h2>2020 collections for women</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias assumenda distinctio
                            dolor
                            doloremque eligendi ipsa.</p>
                        <Button component={Link} to="/shop">Shop now <FontAwesome name="angle-right"/> </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default Banner;