import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";


const AddBank = (props) => {
    const [forms, setForms] = React.useState({
        old_password: '',
        new_password: '',
        confirm_password: '',
    });

    // forms handler
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    };

    const submitHandler = (e) => {
        e.preventDefault();
        toast.success('Form Submitted!')
    };
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - Bank Settings</title>
            </Helmet>
            <Grid className="userPanel userPanel-2">
                <Grid className="container" container spacing={3} >
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="changePass purcessWrapper">
                            <h2>Add Bank</h2>
                            <form onSubmit={submitHandler}>
                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Bank Name"
                                            name="old_password"
                                            value={forms.old_password}
                                            onChange={changeHandler}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className="formInput radiusNone bdbNone mb-20"
                                        />
                                        <TextField
                                            fullWidth
                                            label="Details"
                                            multiline
                                            rows={4}
                                            name="new_password"
                                            value={forms.new_password}
                                            onChange={changeHandler}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className="formInput radiusNone text-b-wrap bdbNone mb-20"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" className="cBtn cBtnLarge cBtnBlack">Submit</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>


        </Fragment>


    )
};

export default AddBank;