import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Newslatter = () =>{
  const classes = useStyles();
  const [age, setAge] = React.useState('');

  const inputLabel = React.useRef(null);


  const handleChange = event => {
    setAge(event.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
};


  return (
    <div className="newslatter cardWrap">
        <div className="DtableHeaderArea">
            <h2 className="DtableHeader">Send Newsletter</h2>
        </div>
        <form onSubmit={submitHandler}>
        <Grid container spacing={3}>
            <Grid item lg={6}>
                <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-filled-label">Email (Users)</InputLabel>
                    <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={age}
                    onChange={handleChange}
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>max133@yopmail.com</MenuItem>
                    <MenuItem value={20}>max13@yopmail.com</MenuItem>
                    <MenuItem value={30}>ujjwalm112@gmail.com</MenuItem>
                    <MenuItem value={40}>rashedvendor@yopmail.com</MenuItem>
                    <MenuItem value={50}>adamsmiththree@yopmail.com</MenuItem>
                    <MenuItem value={60}>shaplaitech@gmail.com</MenuItem>
                    <MenuItem value={70}>shaplacse.ku@gmail.com</MenuItem>
                    <MenuItem value={80}>itech.soft.solution16+4@gmail.com</MenuItem>
                    <MenuItem value={90}>md.shahinfb24@gmail.com</MenuItem>
                    <MenuItem value={100}>murilo@yopmail.com</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item lg={6}>
                <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-filled-label">Email (Subscribers)</InputLabel>
                    <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={age}
                    onChange={handleChange}
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={40}>rashedvendor@yopmail.com</MenuItem>
                    <MenuItem value={90}>md.shahinfb24@gmail.com</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item lg={12} xs={12}>
                  <TextareaAutosize aria-label="empty textarea" placeholder="Newsletter Content" />
            </Grid>
            <Grid item lg={12} xs={12}>
                <Button type="submit" className="cBtn cBtnLarge cBtnBlack">Submit</Button>
            </Grid>
        </Grid>
            
        </form>
      
    </div>
  );
}

export default Newslatter;