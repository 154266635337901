import React from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";

const GeneralChange = () => {
    const [forms, setForms] = React.useState({
        facebook: '',
        youtube: '',
        Google: '',
        phone: '',
    });

    // forms handler
    const changeHandler = e => {
        setForms({...forms, [e.target.name]: e.target.value})
    };

    const submitHandler = (e) => {
        e.preventDefault();
        toast.success('Form Submitted!')
    };

    return (
        <Grid className="changePass">
            <form onSubmit={submitHandler}>
                <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            label="First Name"
                            name="facebook"
                            value={forms.facebook}
                            onChange={changeHandler}
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="formInput radiusNone bdbNone mb-10"
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            name="youtube"
                            value={forms.youtube}
                            onChange={changeHandler}
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="formInput radiusNone bdbNone mb-10"
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="Email"
                            name="Google"
                            value={forms.Google}
                            onChange={changeHandler}
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="formInput radiusNone bdbNone"
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="Phone"
                            name="phone"
                            value={forms.phone}
                            onChange={changeHandler}
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="formInput radiusNone bdbNone"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" className="cBtn cBtnLarge cBtnBlack">Save Changes</Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
};

export default GeneralChange;