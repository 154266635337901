import React, { Fragment } from 'react';
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

import icon1 from '../../../../images/user-account/sales.svg';
import icon2 from '../../../../images/user-account/visitors.svg';
import icon3 from '../../../../images/user-account/new_orders.svg';
import icon4 from '../../../../images/user-account/customers.svg';

import './style.scss';


const purchaseHistory = [
    {
        id: 1,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Paid',
        delivery_status: 'Delivered'
    }, {
        id: 2,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Paid',
        delivery_status: 'Delivered'
    }, {
        id: 3,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Unpaid',
        delivery_status: 'Delivered'
    }, {
        id: 4,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Paid',
        delivery_status: 'Pending'
    }, {
        id: 5,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Paid',
        delivery_status: 'Delivered'
    },
];

const AccountDashboard = (props) => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | User Dashboard</title>
            </Helmet>
            <Grid container spacing={3}>
                <Grid item md={3} sm={6} xs={6}>
                    <Grid className="featuredItem">
                        <Link to={props.match.path} />
                        <Grid className="icon">
                            <img src={icon1} alt="icon1" />
                        </Grid>
                        <Grid className="content">
                            <h4>Viewed Product</h4>
                            <h2>1</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                    <Grid className="featuredItem">
                        <Link to={props.match.path} />
                        <Grid className="icon">
                            <img src={icon2} alt="icon2" />
                        </Grid>
                        <Grid className="content">
                            <h4>Total Purchase</h4>
                            <h2>0</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                    <Grid className="featuredItem">
                        <Link to={props.match.path} />
                        <Grid className="icon">
                            <img src={icon3} alt="icon3" />
                        </Grid>
                        <Grid className="content">
                            <h4>In WIshlist</h4>
                            <h2>1</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                    <Grid className="featuredItem">
                        <Link to={props.match.path} />
                        <Grid className="icon">
                            <img src={icon4} alt="icon4" />
                        </Grid>
                        <Grid className="content">
                            <h4>In Cart</h4>
                            <h2>0</h2>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid className="cardWrap mt-30">
                <Grid className="cardTitle">
                    <h4 className="title">Latest Purchases</h4>
                    <Grid className="viewAll"><Link to="/">View All</Link></Grid>
                </Grid>
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell align="center">Delivery Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {purchaseHistory.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell><strong>{item.code}</strong></TableCell>
                                    <TableCell>{item.created_at}</TableCell>
                                    <TableCell>{item.amount}</TableCell>
                                    <TableCell>
                                        <span
                                            className={`badge ${item.payment_status.toLowerCase()}`}>{item.payment_status}</span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span
                                            className={`badge ${item.delivery_status.toLowerCase()}`}>{item.delivery_status}</span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default AccountDashboard;