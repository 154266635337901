import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import HeroArea from "../../components/HeroArea";
import FlashSale from "../../components/FlashSale";
import FeaturedItem from "../../components/FeaturedItem";
import Banner from "../../components/Banner";
import Service from "../../components/Service";
import BlogSection from "../../components/BlogSection";
import Subscription from "../../components/Subscription";

import './style.scss';

const HomePage = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce ecommerce</title>
            </Helmet>
            <HeroArea />
            <FlashSale />
            <Banner />
            <FeaturedItem />
            <Service />
            <BlogSection />
            <Subscription />
        </Fragment>
    )
};

export default HomePage;