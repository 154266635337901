import React, {useState} from 'react';
import {Grid} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Logo from "../UiStyle/Logo";
import {withRouter} from "react-router-dom";

// images
import logo from '../../images/icon/logo.png';

import './style.scss';

const HeaderMiddle = (props) => {

    const [formState, setCatState] = useState({
        search: '',
        cat: '0'
    });

    function changeHandler(event) {
        const dataState = {...formState};
        dataState[event.target.name] = event.target.value;
        setCatState(dataState);
    }

    function submitHandler(e) {
        e.preventDefault();
        props.history.push('/shop')
    }

    return (
        <Grid className="headerMiddle">
            <Grid className="container" alignItems="center" container spacing={3}>
                <Grid item sm={3} xs={12}>
                    <Logo
                        logo={logo}
                        alt="Logo"
                        className="logo"
                    />
                </Grid>
                <Grid item sm={9} xs={12}>
                    <form onSubmit={(e) => submitHandler(e)}>
                        <Grid className="headerSearch">
                            <TextField
                                fullWidth
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                placeholder="Search"
                                name="search"
                                value={formState.search}
                                onChange={changeHandler}
                                className="formInput"
                            />

                            <FormControl className="formSelect">
                                <Select
                                    disableUnderline
                                    name="cat"
                                    value={formState.cat}
                                    onChange={changeHandler}
                                >
                                    <MenuItem className="selectItem" value={0} disabled>All Categories</MenuItem>
                                    <MenuItem className="selectItem" value={10}>Cloths</MenuItem>
                                    <MenuItem className="selectItem" value={20}>Women</MenuItem>
                                    <MenuItem className="selectItem" value={30}>Men</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default withRouter(HeaderMiddle);