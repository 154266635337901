import React from 'react';
import {Grid} from "@material-ui/core";
import CountDown from 'reactjs-countdown';
import { Link} from 'react-router-dom'
// image
import cat1 from '../../images/deal/1.png';
import cat2 from '../../images/deal/2.jpg';
import cat3 from '../../images/deal/3.jpg';
import cat4 from '../../images/deal/4.jpg';

import './style.scss';

const DealArea = () => {
    return (
        <Grid className="Deal-area  pt-60 pb-30">
            <Grid container className="container" spacing={3}>
                <Grid item lg={6} xs={12}>
                    <Grid className="deal-left">
                        <h2>Deal Of The Day</h2>
                        <p>Don’t miss the offer on items chanch to get 40% discount on all off our products.</p>
                        <CountDown
                            end="August 22, 2022"
                        />
                        <Link className="cBtn cBtnWhite cBt-btn2" to="/shop">SHOP NOW</Link>
                    </Grid>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Grid container>
                        <Grid item lg={6} xs={12}>
                            <Grid className="deal-img">
                                <img src={cat1} alt=""/>
                            </Grid>
                            <Grid className="deal-img mb-0">
                                <img src={cat2} alt=""/>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Grid className="deal-img">
                                <img src={cat3} alt=""/>
                            </Grid>
                            <Grid className="deal-img mb-0">
                                <img src={cat4} alt=""/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        
    )
};

export default DealArea;