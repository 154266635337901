import React from 'react';
import { MDBDataTable } from 'mdbreact';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import {Link} from 'react-router-dom'
 
const VendorList = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

  const data = {
    columns: [
      {
        label: 'Full Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'position',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Country',
        field: 'office',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Phone',
        field: 'age',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Created At',
        field: 'date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Status',
        field: 'salary',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Vendor Verification',
        field: 'Vendor',
        sort: 'asc',
        width: 100
      }
    ],
    rows: [
      {
        name: 'Tiger Nixon',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015261',
        date: '2011/04/25',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Garrett Winters',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '015263',
        date: '2011/07/25',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Ashton Cox',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015266',
        date: '2009/01/12',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Cedric Kelly',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015222',
        date: '2012/03/29',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Airi Satou',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '015233',
        date: '2008/11/28',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Brielle Williamson',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015261',
        date: '2012/12/02',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Herrod Chandler',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015259',
        date: '2012/08/06',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Rhona Davidson',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '015255',
        date: '2010/10/14',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Colleen Hurst',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015239',
        date: '2009/09/15',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Sonya Frost',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015223',
        date: '2008/12/13',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Jena Gaines',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015230',
        date: '2008/12/19',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Quinn Flynn',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015222',
        date: '2013/03/03',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Charde Marshall',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015236',
        date: '2008/10/16',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Haley Kennedy',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015243',
        date: '2012/12/18',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Tatyana Fitzpatrick',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015219',
        date: '2010/03/17',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Michael Silva',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015266',
        date: '2012/11/27',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Paul Byrd',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015264',
        date: '2010/06/09',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Gloria Little',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015259',
        date: '2009/04/10',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Bradley Greer',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015241',
        date: '2012/10/13',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Dai Rios',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015235',
        date: '2012/09/26',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Jenette Caldwell',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015230',
        date: '2011/09/03',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Yuri Berry',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015240',
        date: '2009/06/25',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Caesar Vance',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015221',
        date: '2011/12/12',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Doris Wilder',
        position: 'md.shahinfb24@gmail.com',
        office: 'Sidney',
        age: '015223',
        date: '2010/09/20',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Angelica Ramos',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015247',
        date: '2009/10/09',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Gavin Joyce',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015242',
        date: '2010/12/22',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Jennifer Chang',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '015228',
        date: '2010/11/14',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Brenden Wagner',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015228',
        date: '2011/06/07',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Fiona Green',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015248',
        date: '2010/03/11',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Shou Itou',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '015220',
        date: '2011/08/14',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Michelle House',
        position: 'md.shahinfb24@gmail.com',
        office: 'Sidney',
        age: '015237',
        date: '2011/06/02',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Suki Burks',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015253',
        date: '2009/10/22',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Prescott Bartlett',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015227',
        date: '2011/05/07',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Gavin Cortez',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015222',
        date: '2008/10/26',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Martena Mccray',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015246',
        date: '2011/03/09',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Unity Butler',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015247',
        date: '2009/12/09',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Howard Hatfield',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015251',
        date: '2008/12/16',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Hope Fuentes',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015241',
        date: '2010/02/12',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Vivian Harrell',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015262',
        date: '2009/02/14',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Timothy Mooney',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015237',
        date: '2008/12/11',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Jackson Bradshaw',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015265',
        date: '2008/09/26',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Olivia Liang',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '015264',
        date: '2011/02/03',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Bruno Nash',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015238',
        date: '2011/05/03',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Sakura Yamamoto',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '015237',
        date: '2009/08/19',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Thor Walton',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015261',
        date: '2013/08/11',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Finn Camacho',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015247',
        date: '2009/07/07',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Serge Baldwin',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '015264',
        date: '2012/04/09',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Zenaida Frank',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015263',
        date: '2010/01/04',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Zorita Serrano',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015256',
        date: '2012/06/01',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Jennifer Acosta',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015243',
        date: '2013/02/01',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Cara Stevens',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015246',
        date: '2011/12/06',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Hermione Butler',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015247',
        date: '2011/03/21',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Lael Greer',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015221',
        date: '2009/02/27',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Jonas Alexander',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015230',
        date: '2010/07/14',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Shad Decker',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015251',
        date: '2008/11/13',
        salary: 'Inactive',
        Vendor: 'Verified'
      },
      {
        name: 'Michael Bruce',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '015229',
        date: '2011/06/27',
        salary: 'Active',
        Vendor: 'Not Verified'
      },
      {
        name: 'Donna Snider',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015227',
        date: '2011/01/25',
        salary: 'Inactive',
        Vendor: 'Verified'
      }
    ]
  };

  return (
      <div className="DataTableArea">
        <div className="DtableHeaderArea">
            <h2 className="DtableHeader">All Vendor</h2>
            <Link to='/admin/addvendor' className="cBtn cBtnTheme">Add New Vendor</Link>
        </div>
        <MDBDataTable className="user-table"
            striped
            bordered
            hover
            data={data}
            />
      </div>
    
  );
}

export default VendorList;