import React from 'react';
import Grid from "@material-ui/core/Grid";
import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({text}) => <div>{text}</div>;

const GoogleMap = () => {
    return (
        <Grid className="googleMap">
            <div style={{height: '100vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: 'AIzaSyBO_5h890WNShs_YLGivCBfs2U89qXR-7Y'}}
                    defaultCenter={{lat: 59.95, lng: 30.33}}
                    defaultZoom={11}
                >
                    {/*<AnyReactComponent*/}
                        {/*lat={59.955413}*/}
                        {/*lng={30.337844}*/}
                        {/*text="My Marker ffffffffffffffffffffffffffffffffffffffff"*/}
                    {/*/>*/}

                </GoogleMapReact>
            </div>
        </Grid>
    )
};

export default GoogleMap;