import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import BlogItem from "../../components/BlogItem";
import { Helmet } from "react-helmet";

// api
import blogs from '../../api/blogs';

class BlogNoSidebar extends Component {
    state = {
        currentPage: 1,
        rowsPerPage: 6,
    };

    paginateHandler = number => () => {
        this.setState({
            currentPage: number,
        })
    };

    render() {
        // get all the state
        const { currentPage, rowsPerPage } = this.state;

        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        const currentRows = blogs.slice(indexOfFirstRow, indexOfLastRow);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(blogs.length / rowsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => (
            <ListItem
                key={number}
                id={number}
                className={currentPage === number ? 'active' : null}
                onClick={this.paginateHandler(number)}
            >
                {number}
            </ListItem>
        ));
        return (
            <Grid className="blogPage ptb-60">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>rCommerce | Blog no sidebar</title>
                </Helmet>
                <Grid className="container" container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {currentRows.map((item, i) => {
                                return (
                                    <BlogItem md={4} key={i} item={item} />
                                )
                            })}
                            <Grid item xs={12}>
                                <List className="paginationWrapper">
                                    {renderPageNumbers}
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default BlogNoSidebar;