import React from 'react';
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Helmet } from "react-helmet";

import PendingOrder from '../components/ordermanagement/PendingOrder'
import ProcessingOrder from '../components/ordermanagement/ProcessingOrder'
import CompleteOrder from '../components/ordermanagement/CompleteOrder'
import CancelOrder from '../components/ordermanagement/CancelOrder'

function TabPanel(props) {
    const { children, value, index, ...other } = props;



    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const submitHandler = (e) => {
    e.preventDefault();
};

const OrderManagement = () => {
    const [tab, setTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };


    return (
        <Grid className="accountProfile cardWrap">
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | My Profile</title>
            </Helmet>
            <Tabs
                className="accountTabs"
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
                onChange={handleChange}
                aria-label="simple tabs example">
                <Tab label="Pending Orders" {...a11yProps(0)} />
                <Tab label="Processing Orders" {...a11yProps(1)} />
                <Tab label="Completed Orders" {...a11yProps(2)} />
                <Tab label="Cancelled Orders" {...a11yProps(3)} />
            </Tabs>

            <TabPanel value={tab} index={0}>
                <Grid className="changePass">
                    <PendingOrder />
                </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Grid className="changePass">
                    <ProcessingOrder />
                </Grid>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <CompleteOrder />
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <CancelOrder />
            </TabPanel>
        </Grid>
    )
};

export default OrderManagement;