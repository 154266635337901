import React from 'react';
import {Grid} from '@material-ui/core'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import './style.scss';

const data = [
    {
        name: 'Sent',
        value: '15',
        color: '#F1682C',
        id: 1,
    },
    {
        name: 'Process',
        value: '35',
        color: '#BD20D3',
        id: 2,
    },
    {
        name: 'Complete',
        value: '50',
        color: '#0EB7FE',
        id: 3
    },
];

const TotalOrders = () => {
    const options = {
        title: false,
        tooltip: {
            headerFormat: '',
            pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name} : {point.y}</b><br/>'
        },
        chart: {
            renderTo: 'container',
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            height: '212px',
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                }
            },
            showInLegend: false
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical'
        },
        series: [{
            minPointSize: 0,
            innerSize: '50%',
            zMin: 0,
            name: 'variablepie',
            type: 'pie',
            data: [{
                name: 'Sent',
                y: 15,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#F1682C']
                    ]
                },
            }, {
                name: 'Process',
                y: 35,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#BD20D3']
                    ]
                },
            }, {
                name: 'Complete',
                y: 50,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#0EB7FE']
                    ]
                },
            }]
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 'auto'
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    },
                    yAxis: {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -5
                        },
                        title: {
                            text: null
                        }
                    },
                    subtitle: {
                        text: null
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        }
    }

    return (
        <Grid>
            <HighchartsReact
                highcharts={Highcharts}
                allowChartUpdate
                immutable={false}
                updateArgs={[true, true, true]}
                containerProps={{className: 'chartContainer'}}
                options={options}
            />
            <Grid className="text-center">
                <ul className="sessionList">
                    {data.map((item, i) => (
                        <li key={i}>
                            <h3><span style={{background: item.color}}></span> {item.value}%</h3>
                            <span>{item.name}</span>
                        </li>
                    ))}
                </ul>
            </Grid>
        </Grid>
    );
}

export default TotalOrders;