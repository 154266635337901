import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import invoice from "../invoice.pdf";
import FontAwesome from "../../../../components/UiStyle/FontAwesome";
import Switch from "@material-ui/core/Switch";

const products = [
    {
        id: 1,
        name: 'Chicken Meat Ball',
        image: '/products/11.jpg',
        qty: 5,
        price: 150,
        sell_price: 160,
        featured: false,
        publication: true,
    }, {
        id: 2,
        name: 'Chicken Meat Ball',
        image: '/products/5.jpg',
        qty: 5,
        price: 150,
        sell_price: 160,
        featured: false,
        publication: true,
    },
];

const AllProducts = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <Grid className="cardWrap mt-30">
            <Grid className="cardTitle">
                <h4 className="title">Latest Orders</h4>
                <Grid className="viewAll"><Link to="/">View All</Link></Grid>
            </Grid>
            <Grid className="defaultTable">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Current Quantity</TableCell>
                            <TableCell>Regular Price</TableCell>
                            <TableCell>Sell Price</TableCell>
                            <TableCell>Featured</TableCell>
                            <TableCell>Publication Status</TableCell>
                            <TableCell>Option</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.length <= 0 ? <TableRow>
                            <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                        </TableRow> : <Fragment>
                            {products.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell><img width="60px" src={item.image} alt="picture"/></TableCell>
                                    <TableCell>${item.qty}</TableCell>
                                    <TableCell>${item.price}</TableCell>
                                    <TableCell>{item.sell_price}</TableCell>
                                    <TableCell>
                                        <Switch
                                            color="primary"
                                            inputProps={{'aria-label': 'primary checkbox'}}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Switch
                                            color="primary"
                                            inputProps={{'aria-label': 'primary checkbox'}}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                            name="ellipsis-v"/></span>
                                        <Popover
                                            className="optionsMenu"
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <Link to={props.match.path}>Duplicate</Link>
                                            <a href={invoice} download>Download Invoice</a>
                                        </Popover>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Fragment>}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
};

export default AllProducts;