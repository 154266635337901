import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import FontAwesome from "../../../../components/UiStyle/FontAwesome";
import Popover from "@material-ui/core/Popover";

import invoice from '../invoice.pdf';

const purchaseHistory = [
    {
        id: 1,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Paid',
        delivery_status: 'Delivered'
    }, {
        id: 2,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Paid',
        delivery_status: 'Delivered'
    }, {
        id: 3,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Unpaid',
        delivery_status: 'Delivered'
    }, {
        id: 4,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Paid',
        delivery_status: 'Pending'
    }, {
        id: 5,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Paid',
        delivery_status: 'Delivered'
    }, {
        id: 6,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Paid',
        delivery_status: 'Delivered'
    }, {
        id: 7,
        code: '#5845',
        created_at: '01 jul 2020',
        amount: '$857',
        payment_status: 'Paid',
        delivery_status: 'Delivered'
    },
];

const AccountPurchaseHistory = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | User Purchase History</title>
            </Helmet>
            <Grid className="cardWrap">
                <Grid className="cardTitle">
                    <h4 className="title">Latest Purchases</h4>
                </Grid>
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell align="center">Delivery Status</TableCell>
                                <TableCell align="right">Option</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {purchaseHistory.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell><strong>{item.code}</strong></TableCell>
                                    <TableCell>{item.created_at}</TableCell>
                                    <TableCell>{item.amount}</TableCell>
                                    <TableCell>
                                        <span
                                            className={`badge ${item.payment_status.toLowerCase()}`}>{item.payment_status}</span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span
                                            className={`badge ${item.delivery_status.toLowerCase()}`}>{item.delivery_status}</span>
                                    </TableCell>
                                    <TableCell align="right">
                                        <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                            name="ellipsis-v" /></span>
                                        <Popover
                                            className="optionsMenu"
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <Link to='/account/Pursessdetails'>View Details</Link>
                                            <a href={invoice} download>Download Invoice</a>
                                        </Popover>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default AccountPurchaseHistory;