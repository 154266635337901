import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Scrollbars} from 'react-custom-scrollbars';

import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Popover from '@material-ui/core/Popover';
import Button from "@material-ui/core/Button";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FontAwesome from "../UiStyle/FontAwesome";
import {removeFromCart} from "../../store/actions/action";
import {totalPrice} from "../../utils";

// images
import userIcon from '../../images/icon/user-icon.png'
import wishlistsIcon from '../../images/icon/wishlists-icon.png'
import cartIcon from '../../images/icon/cart-icon.png'

import './style.scss';

const HeaderTop = (props) => {
    const [currency, setCurrency] = useState(1);
    const [language, setLanguage] = useState(1);

    const handleCurrencyChange = event => {
        setCurrency(event.target.value);
    };
    const handleLanguageChange = event => {
        setLanguage(event.target.value);
    };


    const [anchors, setAnchor] = React.useState({
        anchorAcc: null,
        anchorCart: null,
    });

    const handleClick = anchorName => event => {

        const all_anchor = {...anchors};
        all_anchor[anchorName] = event.currentTarget;
        setAnchor(all_anchor);
    };

    const handleClose = anchorName => () => {
        const all_anchor = {...anchors};
        all_anchor[anchorName] = null;
        setAnchor(all_anchor);
    };


    const is_my_account = Boolean(anchors.anchorAcc);
    const my_acc_id = is_my_account ? 'simple-popover' : undefined;

    const is_wish_list = Boolean(anchors.anchorCart);
    const wish_list_id = is_wish_list ? 'simple-popover' : undefined;

    return (
        <Grid className="headerTop">
            <Grid container spacing={3} alignItems="center" className="container">
                <Grid item md={8} sm={4} xs={7}>
                    <Grid className="htLeft">
                        <FormControl className="formSelect">
                            <Select
                                disableUnderline
                                value={currency}
                                onChange={handleCurrencyChange}
                            >
                                <MenuItem value={1}>$ USD</MenuItem>
                                <MenuItem value={2}>$ BTC</MenuItem>
                                <MenuItem value={3}>$ ETH</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className="formSelect">
                            <Select
                                disableUnderline
                                value={language}
                                onChange={handleLanguageChange}
                            >
                                <MenuItem value={1}>English</MenuItem>
                                <MenuItem value={2}>Spanish</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item md={2} sm={4} xs={5}>
                    <Grid className="htProfile" onClick={handleClick('anchorAcc')}>
                        <img src={userIcon} alt="user icon"/>
                        <p>My Account</p>
                    </Grid>
                    <Popover
                        id={my_acc_id}
                        open={is_my_account}
                        anchorEl={anchors.anchorAcc}
                        onClose={handleClose('anchorAcc')}
                        classes={{root: 'csPopover', paper: 'csPopoverPaper myAccountPopover'}}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Button component={Link} to="/login" color="primary" fullWidth className="cBtn">Login</Button>
                        <Button component={Link} to="/signup" color="primary" fullWidth
                                className="cBtn">Register</Button>
                    </Popover>
                </Grid>
                <Grid item md={2} sm={4} xs={12}>
                    <Grid className="htRgihtBtn">
                        <List>
                            <ListItem><Link to="/wishlist"><img src={wishlistsIcon} alt="wishlist"/>
                                {props.wish_list.length > 0 ? <span>{props.wish_list.length}</span> : ''}
                            </Link></ListItem>
                            <ListItem onClick={handleClick('anchorCart')}><img src={cartIcon} alt="carts"/>
                                {props.cart_list.length > 0 ?
                                    <span className="number">{props.cart_list.length}</span> : ''}
                            </ListItem>
                        </List>
                        <Popover
                            id={wish_list_id}
                            open={is_wish_list}
                            anchorEl={anchors.anchorCart}
                            onClose={handleClose('anchorCart')}
                            classes={{root: 'csPopover', paper: 'csPopoverPaper'}}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={350}>
                                <List className="productLists">
                                    {props.cart_list.map(item => {
                                        return (
                                            <ListItem key={item.id}>
                                                <Link to={`/product/${item.id}`} className="producThumb"><img
                                                    src={item.pictures[0]} alt=""/></Link>
                                                <Grid className="productContent">
                                                    <h4>
                                                        <Link to={`/product/${item.id}`}>
                                                            {item.name}
                                                        </Link>
                                                        <span onClick={() => props.removeFromCart(item)}
                                                              className="cardRemove"><FontAwesome name="trash"/></span>
                                                    </h4>
                                                    <p>{item.selected_color}, {item.selected_size !== undefined ? `size ${item.selected_size}` : ''}</p>
                                                    <h5>{item.qty} x ${item.price}</h5>
                                                </Grid>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                                <Grid className="cartTotal">
                                    <h4>Total: <span>${totalPrice(props.cart_list)}</span></h4>
                                    <Button fullWidth component={Link} to="/checkout"
                                            className="cBtn cBtnRadius cBtnTheme">Proceed to checkout</Button>
                                    <Grid className="textCenter">
                                        <Button className="viewCartBtn" component={Link} to="/cart" disableRipple>View
                                            cart</Button>
                                    </Grid>
                                </Grid>
                            </Scrollbars>
                        </Popover>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => {
    return {
        cart_list: state.cartList.cart,
        wish_list: state.wishList.w_list
    }
};

export default connect(mapStateToProps, {removeFromCart})(HeaderTop);