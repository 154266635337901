
import React from 'react';
import Grid from "@material-ui/core/Grid";

import ShopSetting from '../../Vendor/pages/ShopSetting'

import {Link} from 'react-router-dom'

const ShopSettings = (props) => {
    return (
        <Grid className="accountProfile cardWrap">
            <div className="DtableHeaderArea">
                <h2 className="DtableHeader">Shop Settings</h2>
                <Link to='/shop' className="cBtn cBtnTheme">Visit Shop</Link>
            </div>
            <ShopSetting/>
        </Grid>
    )
};

export default ShopSettings;