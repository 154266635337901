import React from 'react';
import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";

import './style.scss';

const NotFoundPage = () => {
    return (
        <Grid className="errorPage">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Page Not Found</title>
            </Helmet>
            <h2>Page not found</h2>
        </Grid>
    )
};

export default NotFoundPage;