import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import Button from "@material-ui/core/Button";
import { searchFilter } from "../../utils";
import BlogItem from "../../components/BlogItem";

// api
import blogs from '../../api/blogs';

import './style.scss';

class BlogPage extends Component {
    state = {
        currentPage: 1,
        rowsPerPage: 6,
        search: '',
    };

    searchHandler = (e) => {
        this.setState({
            search: e.target.value
        })
    };

    paginateHandler = number => () => {
        this.setState({
            currentPage: number,
        })
    };

    render() {
        // get all the state
        const { currentPage, rowsPerPage, search } = this.state;

        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        const filterdRows = blogs.filter(row => searchFilter(row, search));
        const currentRows = filterdRows.slice(indexOfFirstRow, indexOfLastRow);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(filterdRows.length / rowsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => (
            <ListItem
                key={number}
                id={number}
                className={currentPage === number ? 'active' : null}
                onClick={this.paginateHandler(number)}
            >
                {number}
            </ListItem>
        ));
        return (
            <Grid className="blogPage reversSidebar ptb-60">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>rCommerce | Blog</title>
                </Helmet>
                <Grid className="container" container spacing={3}>
                    <Grid item md={9} xs={12}>
                        <Grid container spacing={3}>
                            {currentRows.map((item, i) => {
                                return (
                                    <BlogItem key={i} item={item} />
                                )
                            })}
                            <Grid item xs={12}>
                                <List className="paginationWrapper">
                                    {renderPageNumbers}
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Grid className="sidebarArea">
                            {/*widget search */}
                            <Grid className="widget widgetSearch">
                                <form onSubmit={this.submitHandler}>
                                    <input onChange={this.searchHandler} type="text"
                                        placeholder="Search" />
                                    <Button><FontAwesome name="search" /></Button>
                                </form>
                            </Grid>
                            {/*widget category*/}
                            <Grid className="widget widgetCategory">
                                <h3 className="widgetTitle">Categories</h3>
                                <ul className="widgetCatList">
                                    <li><Link to="/blog">Women<span>0</span></Link></li>
                                    <li><Link to="/blog">Men<span>03</span></Link></li>
                                    <li><Link to="/blog">Accessories<span>03</span></Link></li>
                                </ul>
                            </Grid>
                            {/*widget recent post*/}
                            <Grid className="widget widgetRecentPost">
                                <h3 className="widgetTitle">Recent Post</h3>
                                {blogs !== undefined ? blogs.slice(0, 4).map(item => {
                                    return (
                                        <Grid key={item.id} className="rcSinglePost">
                                            <div className="thumb">
                                                <img src={item.screens} alt="thumb" />
                                            </div>
                                            <div className="content">
                                                <h5><Link to={`blog-details/${item.id}`}>{item.title}</Link></h5>
                                                <p className="date"><FontAwesome name="calendar" />01 Jun 2020</p>
                                            </div>
                                        </Grid>
                                    )
                                }) : ''}
                            </Grid>
                            {/*widget keyword*/}
                            <Grid className="widget widgetKeywords">
                                <h3 className="widgetTitle">Keywords</h3>
                                <Link to="/">business</Link>
                                <Link to="/">Design</Link>
                                <Link to="/">Agency</Link>
                                <Link to="/">Infography</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default BlogPage;