import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import FontAwesome from "../../../../../components/UiStyle/FontAwesome";

const products = [
    {
        id: 1,
        code:'#10039',
        name: '	Rafael Fernando',
        total: '4.00',
        Payment:'Cash On Delivery',
        Payment_status:'Pending',
        Sell:'	2020-01-24 13:42:13',
        featured: false,
        Publication_Status:'Complete',
        publication: true,
    }, {
        id: 2,
        code:'#10052',
        name: '	Watson',
        total: '14.2',
        Payment:'Cash On Delivery',
        Payment_status:'Done',
        Sell:'	2020-01-17 13:42:13',
        featured: false,
        Publication_Status:'Complete',
        publication: true,
    }, {
        id: 3,
        code:'#10065',
        name: 'David Miller',
        total: '19.2',
        Payment:'Cash On Delivery',
        Payment_status:'Pending',
        Sell:'	2020-01-19 13:42:13',
        featured: false,
        Publication_Status:'Complete',
        publication: true,
    }, {
        id: 4,
        code:'#10058',
        name: '	Aliza anne',
        total: '6.2',
        Payment:'Cash On Delivery',
        Payment_status:'Done',
        Sell:'	2020-01-06 13:42:13',
        featured: false,
        Publication_Status:'Complete',
        publication: true,
    },{
        id: 5,
        code:'#10079',
        name: '	Lima isa',
        total: '3.02',
        Payment:'Cash On Delivery',
        Payment_status:'Pending',
        Sell:'	2020-01-10 13:42:13',
        featured: false,
        Publication_Status:'Complete',
        publication: true,
    },{
        id: 6,
        code:'#10080',
        name: 'Rashedul',
        total: '40.20',
        Payment:'Cash On Delivery',
        Payment_status:'Done',
        Sell:'	2020-01-18 13:42:13',
        featured: false,
        Publication_Status:'Complete',
        publication: true,
    },
    {
        id: 7,
        code:'#10055',
        name: 'Shahin',
        total: '9.05',
        Payment:'Cash On Delivery',
        Payment_status:'Pending',
        Sell:'	2020-01-25 13:42:13',
        featured: false,
        Publication_Status:'Complete',
        publication: true,
    },
];

const CompleteOrder = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div className="DataTableArea">
            <div className="DtableHeaderArea">
                <h2 className="DtableHeader">Complete Orders</h2>
            </div>
            <Grid className="mt-30">
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Order Code</TableCell>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Total Price</TableCell>
                                <TableCell>Payment By</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell>Delivery Status</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell align="right">Option</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.length <= 0 ? <TableRow>
                                <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                            </TableRow> : <Fragment>
                                {products.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.code}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.total}</TableCell>
                                        <TableCell>{item.Payment}</TableCell>
                                            <TableCell><span
                                                 className={`badge ${item.Payment_status}`}>{item.Payment_status}</span></TableCell>
                                            <TableCell><span
                                                 className={`badge ${item.Publication_Status}`}>{item.Publication_Status}</span></TableCell>
                                        <TableCell>{item.Sell}</TableCell>
                                        <TableCell align="right">
                                            <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                                name="ellipsis-v"/></span>
                                            <Popover
                                                className="optionsMenu"
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Link to={'/admin/vendororder'}>View Vendor Orders</Link>
                                                <Link to={'/admin/viewvendororder'}>View Order Details</Link>
                                                <Link to={'/admin/ordermanagement'}>Delete</Link>
                                            </Popover>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>
    )
};

export default CompleteOrder;