import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";


import './style.scss';

const WithdrawDetails = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - Withdraw Details</title>
            </Helmet>
            <Grid className="">
                <Grid className="container" container spacing={3} >
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <div className="w-details">
                                <h2>Withdraw Details</h2>
                                <p><strong>Request Code:</strong>  #10001</p>
                                <p><strong>Created At:</strong>  2020-01-02 13:04:41</p>
                                <p><strong>Amount:</strong>  $ 0.92</p>
                                <p><strong>Payment Status:</strong> <span>Done</span></p>
                                <p><strong>Payment By:</strong>   Bank</p>
                                <p><strong>Payment Receive Confirmation :</strong> <span>Confirmed</span></p>
                                <p><strong>Payment Method's Unique Code:</strong>   N/A</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <div className="w-details">
                                <h2>Bank Details</h2>
                                <p><strong>Bank Name:  </strong>NRB Bank</p>
                                <p>XYZ 1321320211321320</p>

                                <p><strong>Bank Name:  </strong>Trust Bank</p>
                                <p>XYZ 1321320211321320</p>

                                <p><strong>Bank Name:  </strong>Shonali Bank</p>
                                <p>XYZ 1321320211321320</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <div className="w-details">
                                <h2>Documents</h2>
                                <p>No documents available.</p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Fragment>
    )
};

export default WithdrawDetails;