import React from 'react';
import {BrowserRouter, Switch} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from "../__Routes";
import store from '../../store';
import {getAllProducts} from "../../store/actions/action";

import './App.scss';

function App() {
    store.dispatch(getAllProducts());

    return (
        <BrowserRouter>
            <div className="App">
                <Switch>
                    <Routes/>
                </Switch>
                <ToastContainer/>
            </div>
        </BrowserRouter>
    );
}

export default App;
