import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import FontAwesome from "../../../../components/UiStyle/FontAwesome";

const products = [
    {
        id: 1,
        title:'#74825',
        create:'2019-12-08 18:16:36',
        name: '	Lorem Ipsum',
        Publication_Status:'Solved',
        featured: false,
        publication: true,
    }, {
        id: 2,
        title:'#74826',
        create:'2019-12-08 18:16:36',
        name: 'Lorem Ipsum',
        Publication_Status:'Pending',
        featured: false,
        publication: true,
    }, {
        id: 3,
        title:'#74828',
        create:'2019-12-08 18:16:36',
        name: 'Lorem Ipsum',
        Publication_Status:'Solved',
        featured: false,
        publication: true,
    }, {
        id: 4,
        title:'#74830',
        create:'2019-12-08 18:16:36',
        name: 'Lorem Ipsum',
        Publication_Status:'Pending',
        featured: false,
        publication: true,
    },{
        id: 5,
        title:'#74841',
        create:'2019-12-08 18:16:36',
        name: 'Lorem Ipsum',
        Publication_Status:'Solved',
        featured: false,
        publication: true,
    },
];

const SupportTicket = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div className="DataTableArea cardWrap">
            <div className="DtableHeaderArea">
                <h2 className="DtableHeader">Support Ticket</h2>
            </div>
            <Grid className="mt-30">
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Ticket ID</TableCell>
                                <TableCell>Create At</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align="right">Option</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.length <= 0 ? <TableRow>
                                <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                            </TableRow> : <Fragment>
                                {products.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.title}</TableCell>
                                        <TableCell>{item.create}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell><span
                                             className={`badge ${item.Publication_Status}`}>{item.Publication_Status}</span></TableCell>
                                        <TableCell align="right">
                                            <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                                name="ellipsis-v"/></span>
                                            <Popover
                                                className="optionsMenu"
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Link to='/admin/Supportticketdetails'> View Details</Link>
                                            </Popover>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>
    )
};

export default SupportTicket;