import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import FontAwesome from "../../../../components/UiStyle/FontAwesome";
import Switch from "@material-ui/core/Switch";

const products = [
    {
        id: 1,
        name: '	Electronics Devices',
        Owner: 'Mr.Admin',
        customer: 'Rashedul islam',
        ratting:'5',
        comment:'good product',
        publication: true,
    }, {
        id: 2,
        name: '	TV and Home appliances',
        Owner: 'Lima isa',
        customer: 'Kazi hasib',
        ratting:'3.5',
        comment:'this product is good',
        publication: true,
    }, {
        id: 3,
        name: '	Men Fashion',
        Owner: 'Watson',
        customer: 'Ratul Kahan',
        ratting:'5',
        comment:'this product is very Nice',
        publication: true,
    }, {
        id: 4,
        name: '	Mobiles',
        Owner: 'David Miller',
        customer: 'Naim islam',
        ratting:'2.4',
        comment:'good product',
        publication: true,
    },{
        id: 5,
        name: '	Televisions',
        Owner: 'Mr.Admin',
        customer: 'Shahin shohan',
        ratting:'5',
        comment:'Nice',
        publication: true,
    },{
        id: 6,
        name: '	T-shirt',
        Owner: 'Mr.Admin',
        customer: 'Shohana Mitu',
        ratting:'4',
        comment:'looks good or test good',
        publication: true,
    },
    {
        id: 7,
        name: '	Jeans',
        Owner: 'Aliza anne',
        customer: 'Jony das',
        ratting:'5',
        comment:'good product',
        publication: true,
    },
];

const ProductReview = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div className="DataTableArea cardWrap">
            <div className="DtableHeaderArea">
                <h2 className="DtableHeader">All Product Reviews</h2>
            </div>
            <Grid className="mt-30">
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell>Owner</TableCell>
                                <TableCell>Customer</TableCell>
                                <TableCell>Rating</TableCell>
                                <TableCell>Comment</TableCell>
                                <TableCell align="right">Published</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.length <= 0 ? <TableRow>
                                <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                            </TableRow> : <Fragment>
                                {products.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.Owner}</TableCell>
                                        <TableCell>{item.customer}</TableCell>
                                        <TableCell>{item.ratting}</TableCell>
                                        <TableCell>{item.comment}</TableCell>
                                        <TableCell align="right">
                                            <Switch
                                                color="primary"
                                                inputProps={{'aria-label': 'primary checkbox'}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>
    )
};

export default ProductReview;