import React from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  
const AddCoupon = () => {
    const [forms, setForms] = React.useState({
        firstname: '',
        Lastname: '',
        email: '',
        phone: '',
        sale:'',
        Password: '',
        confirm_Password: '',
    });

    // forms handler
    const changeHandler = e => {
        setForms({...forms, [e.target.name]: e.target.value})
    };

    const submitHandler = (e) => {
        e.preventDefault();
        toast.success('Form Submitted!')
    };

    const classes = useStyles();
    const [age, setAge] = React.useState('');
  
    const inputLabel = React.useRef(null);
  
  
    const handleChange = event => {
      setAge(event.target.value);
    };
    return (
        <Grid className="DataTableArea cardWrap">
            <Grid className="DtableHeaderArea">
                <h2 className="DtableHeader">Add Coupon</h2>
            </Grid>
            <Grid className="changePass cbt-wrap">
                <form onSubmit={submitHandler}>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Ttile"
                                name="firstname"
                                value={forms.firstname}
                                onChange={changeHandler}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="End Date"
                                name="brand"
                                value={forms.brand}
                                onChange={changeHandler}
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                        <Grid item md={12} xs={12}>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-filled-label">Select</InputLabel>
                                <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={age}
                                onChange={handleChange}
                                className=" mb-20"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>Discount On Checkout</MenuItem>
                                <MenuItem value={20}>Discount On Checkout</MenuItem>
                                <MenuItem value={30}>Discount On Checkout</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Discount (%)"
                                name="email"
                                value={forms.email}
                                onChange={changeHandler}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Coupon Code"
                                name="sale"
                                value={forms.sale}
                                onChange={changeHandler}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid> 
                          
                        <Grid item lg={12} xs={12}>
                            <TextareaAutosize 
                            label="Short Description *"
                            aria-label="empty textarea" 
                            placeholder="Short Description *" 
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button type="submit" className="cBtn cBtnLarge cBtnBlack">Add Coupon</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
};

export default AddCoupon;