import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import FontAwesome from "../../../../components/UiStyle/FontAwesome";
import Switch from "@material-ui/core/Switch";

const products = [
    {
        id: 1,
        name: '	Electronics Devices',
        image: '/products/logo/7.jpg',
        featured: false,
        publication: true,
    }, {
        id: 2,
        name: '	TV and Home appliances',
        image: '/products/logo/10.jpg',
        featured: false,
        publication: true,
    }, {
        id: 3,
        name: '	Men Fashion',
        image: '/products/logo/11.jpg',
        featured: false,
        publication: true,
    }, {
        id: 4,
        name: '	Mobiles',
        image: '/products/logo/8.jpg',
        featured: false,
        publication: true,
    },{
        id: 5,
        name: '	Televisions',
        image: '/products/logo/9.jpg',
        featured: false,
        publication: true,
    },{
        id: 6,
        name: '	T-shirt',
        image: '/products/logo/12.jpg',
        featured: false,
        publication: true,
    },
    {
        id: 7,
        name: '	Jeans',
        image: '/products/logo/13.jpg',
        featured: false,
        publication: true,
    },
];

const AllCategory = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div className="DataTableArea cardWrap">
            <div className="DtableHeaderArea">
                <h2 className="DtableHeader">All Category</h2>
                <Link to='/admin/addcategory' className="cBtn cBtnTheme">Add New Category</Link>
            </div>
            <Grid className="mt-30">
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Categoy Image</TableCell>
                                <TableCell>Featured</TableCell>
                                <TableCell>Publication Status</TableCell>
                                <TableCell align="right">Option</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.length <= 0 ? <TableRow>
                                <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                            </TableRow> : <Fragment>
                                {products.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell><img width="60px" src={item.image} alt="picture"/></TableCell>
                                        <TableCell>
                                            <Switch
                                                color="primary"
                                                inputProps={{'aria-label': 'primary checkbox'}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Switch
                                                color="primary"
                                                inputProps={{'aria-label': 'primary checkbox'}}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                                name="ellipsis-v"/></span>
                                            <Popover
                                                className="optionsMenu"
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Link to='editcategory'>Edit</Link>
                                                <Link to={props.match.path}>View Details</Link>
                                            </Popover>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>
    )
};

export default AllCategory;