import React from 'react';
import { MDBDataTable } from 'mdbreact';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import {Link} from 'react-router-dom'
 
const WithdrawRequests = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

  const data = {
    columns: [
      {
        label: 'Request Code',
        field: 'code',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Vendor Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'position',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Country',
        field: 'office',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Phone',
        field: 'age',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Created At',
        field: 'date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Requested Amount',
        field: 'salary',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Payment Status',
        field: 'Vendor',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Due Amount',
        field: 'Due',
        sort: 'asc',
        width: 100
      }
    ],
    rows: [
      {
        code: '# 10001',
        name: 'Tiger Nixon',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015261',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending'
      },
      {
        code: '# 10001',
        name: 'Garrett Winters',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '015263',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code: '# 10001',
        name: 'Ashton Cox',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015266',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code: '# 10001',
        name: 'Cedric Kelly',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015222',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code: '# 10001',
        name: 'Airi Satou',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '015233',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code: '# 10001',
        name: 'Brielle Williamson',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015261',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code: '# 10001',
        name: 'Herrod Chandler',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015259',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code: '# 10001',
        name: 'Rhona Davidson',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '015255',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code: '# 10001',
        name: 'Colleen Hurst',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015239',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code: '# 10001',
        name: 'Sonya Frost',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015223',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code: '# 10001',
        name: 'Jena Gaines',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015230',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code: '# 10001',
        name: 'Quinn Flynn',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015222',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code: '# 10001',
        name: 'Charde Marshall',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015236',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code: '# 10001',
        name: 'Haley Kennedy',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015243',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code: '# 10001',
        name: 'Tatyana Fitzpatrick',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015219',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code: '# 10001',
        name: 'Michael Silva',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015266',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code: '# 10001',
        name: 'Paul Byrd',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015264',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code: '# 10001',
        name: 'Gloria Little',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015259',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Bradley Greer',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015241',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Dai Rios',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015235',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Jenette Caldwell',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015230',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Yuri Berry',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015240',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Caesar Vance',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015221',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Doris Wilder',
        position: 'md.shahinfb24@gmail.com',
        office: 'Sidney',
        age: '015223',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Angelica Ramos',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015247',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Gavin Joyce',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015242',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Jennifer Chang',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '015228',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Brenden Wagner',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015228',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Fiona Green',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015248',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Shou Itou',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '015220',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Michelle House',
        position: 'md.shahinfb24@gmail.com',
        office: 'Sidney',
        age: '015237',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Suki Burks',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015253',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Prescott Bartlett',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015227',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Gavin Cortez',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015222',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Martena Mccray',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015246',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Unity Butler',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015247',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Howard Hatfield',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015251',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Hope Fuentes',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015241',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Vivian Harrell',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015262',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Timothy Mooney',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015237',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Jackson Bradshaw',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015265',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Olivia Liang',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '015264',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Bruno Nash',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015238',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Sakura Yamamoto',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '015237',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Thor Walton',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015261',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Finn Camacho',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015247',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Serge Baldwin',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '015264',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Zenaida Frank',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015263',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Zorita Serrano',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015256',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Jennifer Acosta',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015243',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Cara Stevens',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015246',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Hermione Butler',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015247',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Lael Greer',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '015221',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Jonas Alexander',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '015230',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Shad Decker',
        position: 'md.shahinfb24@gmail.com',
        office: 'Edinburgh',
        age: '015251',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      },
      {
        code:'# 10001',
        name: 'Michael Bruce',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '015229',
        date: '2020-01-02 13:04:41',
        salary: '120.2',
        Vendor: 'Pending',
        Due:'Confirmed',
      },
      {
        code:'# 10001',
        name: 'Donna Snider',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015227',
        date: '2020-01-02 13:04:41',
        salary: '0',
        Vendor: 'Done',
        Due:'Pending',
      }
    ]
  };

  return (
      <div className="DataTableArea">
        <div className="DtableHeaderArea">
            <h2 className="DtableHeader">Withdraw Requests</h2>
        </div>
        <MDBDataTable className="user-table"
            striped
            bordered
            hover
            data={data}
            />
      </div>
    
  );
}

export default WithdrawRequests;