import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import { Link } from 'react-router-dom'

import './style.scss';

const ProcedCheckout = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce - order-received</title>
            </Helmet>
            <Grid className="">
                <Grid className="container" container spacing={3} >
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <h2>Order Received</h2>
                            <p>Thank you. Your order has been received</p>
                            <Link className="cBtn cBtnTheme cBtnRadius order-btn" to="/">Back To Home</Link>
                        </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <h2>Order History</h2>
                            <Grid className="defaultTable">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Product</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>	Unit Price</TableCell>
                                            <TableCell align='right'>Total Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>1</TableCell>
                                            <TableCell>Beet Root - (100% organic) - Freeze Dried Powder</TableCell>
                                            <TableCell>$ 3.25</TableCell>
                                            <TableCell>$ 3.25</TableCell>
                                            <TableCell align='right'>$ 11.88</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>2</TableCell>
                                            <TableCell>Beet Root - (100% organic) - Freeze Dried Powder</TableCell>
                                            <TableCell>$ 4.25</TableCell>
                                            <TableCell>$ 8.50</TableCell>
                                            <TableCell align='right'>$ 85.25</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <h2>Order Summary</h2>
                            <Grid className="defaultTable">
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Order Code:</TableCell>
                                            <TableCell align='right'>#10040</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Billing address:</TableCell>
                                            <TableCell align='right'>luxemburg</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Total order amount:</TableCell>
                                            <TableCell align='right'>$ 11.65</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Payment method:</TableCell>
                                            <TableCell align='right'>Cash On Delivery</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid className="purcessWrapper">
                            <h2>Order Amount</h2>
                            <Grid className="defaultTable">
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Subtotal:</TableCell>
                                            <TableCell align='right'>$ 11.65</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Shipping Cost:</TableCell>
                                            <TableCell align='right'>$ 0</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Tax:</TableCell>
                                            <TableCell align='right'>$ 0</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Discount:</TableCell>
                                            <TableCell align='right'>$ 0</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Grand Total:</TableCell>
                                            <TableCell align='right'>$ 11.65</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Fragment>
    )
};

export default ProcedCheckout;