import React, {useState} from 'react';
import List from "@material-ui/core/List";
import FontAwesome from "../UiStyle/FontAwesome";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import './style.scss';

const SingleProductDetails = ({item, addToWishList, addToCart, symbol}) => {

    const [preview, setPreview] = useState(0);
    const [size, setSize] = useState(0);
    const [qty, setQty] = useState(1);

    let ratingBack = [];
    let ratingFront = [];
    let i = 0;

    for (i = 0; i < 5; i++) {
        ratingBack.push(<li key={i}><i className="fa fa-star-o"/></li>)
    }
    for (i = 0; i < item.rating; i++) {
        ratingFront.push(<li key={i}><i className="fa fa-star"/></li>)
    }

    return (
        <Grid className="container" container spacing={3}>
            <Grid item sm={5} xs={12}>
                <Grid className="qcvPreview">
                <Zoom>
                        <img
                        alt="that wanaka tree"
                        src={item.pictures[preview]}
                        width="500"
                        />
                </Zoom>
                    <List className="previewProduct">
                        {item.pictures.map((img, i) => {
                            return (
                                <ListItem key={i} className={preview === i ? 'active' : ''}
                                          onClick={() => setPreview(i)}>
                                    <img src={img} alt="preview"/>
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
            </Grid>
            <Grid item sm={7} xs={12}>
                <Grid className="qcvContent">
                    <h2>{item.name}</h2>
                    <Grid className="pro_rating">
                        <List className="ratingFront">
                            {ratingFront}
                        </List>
                        <List className="ratingBack">
                            {ratingBack}
                        </List>

                        <h5>{item.rating} Review</h5>
                        <h3 className="oldPrice">
                            <del>{symbol}{item.salePrice}</del>
                            <span className="discount">{item.discount}% Off</span>
                        </h3>
                        <h2 className="currentPrice">{symbol}{item.price}</h2>
                        <p className="stock">{item.stock > 0
                            ? <>In Stock<strong> ({item.stock})</strong></>
                            : 'Out of Stock'}</p>
                    </Grid>
                    <p className="info">{item.shortDetails}</p>

                    <Grid className="selectColor">
                        <p className="qcvLabel">Select color :</p>
                        <List>
                            {item.variants.map((item, i) => {
                                return (
                                    <ListItem key={i} onClick={() => setPreview(i)}
                                              className={`${item.color} ${i === preview ? 'active' : ''}`}/>
                                )
                            })}
                        </List>
                    </Grid>

                    <Grid className="selectSize">
                        <p className="qcvLabel">Select size :</p>
                        <List>
                            {item.size.map((item, i) => {
                                return (
                                    <ListItem key={i} onClick={() => setSize(i)}
                                              className={size === i ? 'active' : ''}>{item}</ListItem>
                                )
                            })}
                        </List>
                    </Grid>

                    <Grid className="selectQty">
                        <p className="qcvLabel">Select Qty :</p>
                        <Button onClick={() => setQty(qty <= 1 ? 1 : qty - 1)}>-</Button>
                        <input
                            value={qty}
                            onChange={() => setQty(qty)}
                            type="text"/>
                        <Button onClick={() => setQty(qty + 1)}>+</Button>
                    </Grid>


                    <div className="qcvFtBtn">
                        <Button className="cBtn cBtnSquare cBtnBlack"
                                onClick={() => addToCart(item, qty, item.colors[preview], item.size[size])}>Add
                            to cart</Button>
                        <Button className="cBtn cBtnSquare cBtnBlack"
                                onClick={() => addToWishList(item)}><FontAwesome name="heart-o"/></Button>
                    </div>
                    <Grid className="socialLinkShare">
                        <h4>Social Shere</h4>
                        <Link to={`/product/${item.id}`}><FontAwesome name="facebook"/></Link>
                        <Link to={`/product/${item.id}`}><FontAwesome name="twitter"/></Link>
                        <Link to={`/product/${item.id}`}><FontAwesome name="linkedin"/></Link>
                        <Link to={`/product/${item.id}`}><FontAwesome name="instagram"/></Link>
                        <Link to={`/product/${item.id}`}><FontAwesome name="youtube-play"/></Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default SingleProductDetails;