import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import './style.scss';

import BankDetails from '../../../BankDetails'





const BankSettings = (props) => {
    return (
        <Fragment class="add-cart">
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | Bank Details</title>
            </Helmet>
            <BankDetails />
        </Fragment>
    )
};

export default BankSettings;