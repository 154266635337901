import React, { Fragment } from 'react';
import { Button, Dialog, Grid, } from '@material-ui/core'
import TextField from "@material-ui/core/TextField";

const DefaultModal = ({ maxWidth, button, buttonClass }) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    const submitHandler = (e) => {
        e.preventDefault();
    };
    return (
        <Fragment>
            <form onSubmit={submitHandler}>
                <Button align="right"
                    className={`btn ${buttonClass}`}
                    onClick={handleClickOpen}>
                    {button}
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    className="modalWrapper"
                    maxWidth={maxWidth}
                >
                    <Grid className="modalTitle">
                        <h3>Make Withdraw Request</h3>
                    </Grid>
                    <Grid className="modalBody">
                        <p>Enter Amount ($)</p>

                        <Grid xs={12}>
                            <TextField
                                fullWidth
                                name="Shop-Name"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="formInput radiusNone bdbNone mb-20"
                            />
                        </Grid>
                    </Grid>
                    <Grid className="modalFooter">
                        <Button className="bg-default bg-default-2" onClick={handleClose}>
                            Make Request
                        </Button>
                        <Button className="bg-cancel" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Grid>
                </Dialog>
            </form>
            
        </Fragment>
    );
}
export default DefaultModal