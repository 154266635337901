import React from 'react';
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import AccountDetails from "../components/AccountDetails";
import ChangePassword from "../components/ChangePassword";
import BillingAddress from "../components/BillingAddress";
import { Helmet } from "react-helmet";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Profile = () => {
    const [tab, setTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Grid className="accountProfile">
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | My Profile</title>
            </Helmet>
            <Tabs
                className="accountTabs"
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
                onChange={handleChange}
                aria-label="simple tabs example">
                <Tab label="My account" {...a11yProps(0)} />
                <Tab label="Edit Account" {...a11yProps(1)} />
                <Tab label="Change Password" {...a11yProps(2)} />
                <Tab label="Billing Address" {...a11yProps(3)} />
            </Tabs>

            <TabPanel value={tab} index={0}>
                <AccountDetails onlyReadAble={true} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <AccountDetails onlyReadAble={false} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <ChangePassword />
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <BillingAddress />
            </TabPanel>
        </Grid>
    )
};

export default Profile;