import React, {Fragment} from 'react';
import HeaderTop from "../../components/HeaderTop";
import HeaderMiddle from "../../components/HeaderMiddle";
import HeaderBottom from "../../components/HeaderBottom";

import './style.scss';

const Header = () => {
    return (
        <Fragment>
            <HeaderTop/>
            <HeaderMiddle/>
            <HeaderBottom/>
        </Fragment>
    )
};

export default Header;