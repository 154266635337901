import React from 'react';
import { MDBDataTable } from 'mdbreact';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import {Link} from 'react-router-dom'
 
const StockReport = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

  const data = {
    columns: [
      {
        label: 'Product Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Category Name',
        field: 'position',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Brand Name',
        field: 'office',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Stock',
        field: 'age',
        sort: 'asc',
        width: 100
      },

    ],
    rows: [
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '10',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '97',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '45',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '563',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '35',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '78',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '45',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '574',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '54',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '98',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '54',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '543',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '35',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '453',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '53',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '13',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '543',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '53',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '35',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '33',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '53',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '45',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '32',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '435',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '453',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '453',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '543',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '54',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '65',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '66',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '564',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '65',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '76',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '66',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '66',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '46',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '43',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '36',
      },
      {
        name: 'Audio SpeaKER',
        position: 'Home Audio',
        office: '	Sony',
        age: '98',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '33',
      },
      {
        name: 'Jackson Bradshaw',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '846',
      },
      {
        name: 'Olivia Liang',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '89',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '986',
      },
      {
        name: 'Sakura Yamamoto',
        position: 'md.shahinfb24@gmail.com',
        office: 'Tokyo',
        age: '89',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '86',
      },
      {
        name: 'Finn Camacho',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '63',
      },
      {
        name: 'Serge Baldwin',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '55',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '9',
      },
      {
        name: 'Zorita Serrano',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '48',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '44',
      },
      {
        name: 'Cara Stevens',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '015246',
      },
      {
        name: 'Hermione Butler',
        position: 'md.shahinfb24@gmail.com',
        office: 'London',
        age: '41',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '77',
      },
      {
        name: 'Jonas Alexander',
        position: 'md.shahinfb24@gmail.com',
        office: 'San Francisco',
        age: '55',
      },
      {
        name: 'Alovera jel',
        position: '	Hair Care',
        office: 'We are amazonians',
        age: '52',
      },
      {
        name: 'Michael Bruce',
        position: 'md.shahinfb24@gmail.com',
        office: 'Singapore',
        age: '25',
      },
      {
        name: 'Donna Snider',
        position: 'md.shahinfb24@gmail.com',
        office: 'New York',
        age: '20',
      }
    ]
  };

  return (
      <div className="DataTableArea cardWrap">
        <div className="DtableHeaderArea">
            <h2 className="DtableHeader">Product Wise Stock Report</h2>
        </div>
        <MDBDataTable className="user-table"
            title="kdhrfesrd"
            striped
            bordered
            hover
            data={data}
            />
      </div>
    
  );
}

export default StockReport;