import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import FontAwesome from "../../../../components/UiStyle/FontAwesome";
import Switch from "@material-ui/core/Switch";

const products = [
    {
        id: 1,
        name: '	Microwear W34 Smart Watch',
        image: '/products/logo/14.jpg',
        Quantity:'02',
        Regular:'87',
        Sell:'5',
        featured: false,
        Publication_Status:'Active',
        publication: true,
    }, {
        id: 2,
        name: '	Vivo 1812 Y81i (Black, 2GB RAM, 16GB Storage)',
        image: '/products/logo/15.jpg',
        Quantity:'04',
        Regular:'85',
        Sell:'15',
        featured: false,
        Publication_Status:'InActive',
        publication: true,
    }, {
        id: 3,
        name: 'SENSAI - BIOMIMESIS VEIL - BIOMIMESIS VEIL DIFFUSER',
        image: '/products/logo/16.jpg',
        Quantity:'07',
        Regular:'12.7',
        Sell:'25',
        featured: false,
        Publication_Status:'Active',
        publication: true,
    }, {
        id: 4,
        name: '	HP 15-da1007ca Touchscreen laptop',
        image: '/products/logo/19.jpg',
        Quantity:'10',
        Regular:'15',
        Sell:'18.9',
        featured: false,
        Publication_Status:'Active',
        publication: true,
    },{
        id: 5,
        name: '	Sport Bike, Folding, BD-8006, White',
        image: '/products/logo/20.jpg',
        Quantity:'03',
        Regular:'12',
        Sell:'390',
        featured: false,
        Publication_Status:'InActive',
        publication: true,
    },{
        id: 6,
        name: 'V - Neck woman party dress',
        image: '/products/logo/17.jpg',
        Quantity:'02',
        Regular:'9',
        Sell:'12.5',
        featured: false,
        Publication_Status:'Active',
        publication: true,
    },
    {
        id: 7,
        name: 'Dove Shampoo Nourishing Oil Care',
        image: '/products/logo/18.jpg',
        Quantity:'08',
        Regular:'12.5',
        Sell:'36',
        featured: false,
        Publication_Status:'InActive',
        publication: true,
    },
];

const VendorProduct = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div className="DataTableArea cardWrap">
            <div className="DtableHeaderArea">
                <h2 className="DtableHeader">Vendor Products</h2>
            </div>
            <Grid className="mt-30">
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Categoy Image</TableCell>
                                <TableCell>Current Quantity</TableCell>
                                <TableCell>Regular Price</TableCell>
                                <TableCell>Sell Price</TableCell>
                                <TableCell>Featured</TableCell>
                                <TableCell>Publication Status</TableCell>
                                <TableCell align="right">Option</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.length <= 0 ? <TableRow>
                                <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                            </TableRow> : <Fragment>
                                {products.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell><img width="60px" src={item.image} alt="picture"/></TableCell>
                                        <TableCell>{item.Quantity}</TableCell>
                                        <TableCell>{item.Regular}</TableCell>
                                        <TableCell>{item.Sell}</TableCell>
                                        <TableCell>
                                            <Switch
                                                color="primary"
                                                inputProps={{'aria-label': 'primary checkbox'}}
                                            />
                                        </TableCell>
                                        <TableCell><span
                                             className={`badge ${item.Publication_Status}`}>{item.Publication_Status}</span></TableCell>
                                        <TableCell align="right">
                                            <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                                name="ellipsis-v"/></span>
                                            <Popover
                                                className="optionsMenu"
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Link to='/admin/editproduct'> Duplicate</Link>
                                                <Link to='/admin/viewproduct'>View</Link>
                                                <Link to={`/product/${item.id}`}> View As Customer</Link>
                                                <Link to={props.match.path}>Delete</Link>
                                            </Popover>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>
    )
};

export default VendorProduct;