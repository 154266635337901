import React from 'react';
import {Grid} from "@material-ui/core";
import SectionTitle from "../SectionTitle";
import { Link} from 'react-router-dom'
// image
import cat1 from '../../images/category/1.png';
import cat2 from '../../images/category/2.png';
import cat3 from '../../images/category/3.png';
import cat4 from '../../images/category/4.png';
import cat5 from '../../images/category/5.png';
import cat6 from '../../images/category/6.png';

import './style.scss';

const CategoryArea = () => {
    return (
        <Grid className="category-area  pt-60">
            <Grid container className="container" spacing={3}>
                <Grid item xs={12}>
                    <SectionTitle
                        title="Category"
                    />
                </Grid>
                <Grid item lg={2} xs={12}>
                    <Grid className="CategoryWrapper">
                        <img src={cat1} alt=""/>
                        <Link to='/shop'>Clothing & Apparel</Link>
                    </Grid>
                </Grid>
                <Grid item lg={2} xs={12}>
                    <Grid className="CategoryWrapper">
                         <img src={cat2} alt=""/>
                        <Link to='/shop'>Jewellery & Watches</Link>
                    </Grid>
                </Grid>
                <Grid item lg={2} xs={12}>
                    <Grid className="CategoryWrapper">
                         <img src={cat3} alt=""/>
                        <Link to='/shop'>Health & Beauty</Link>
                    </Grid>
                </Grid>
                <Grid item lg={2} xs={12}>
                    <Grid className="CategoryWrapper">
                         <img src={cat4} alt=""/>
                        <Link to='/shop'>Bag & Purse</Link>
                    </Grid>
                </Grid>
                <Grid item lg={2} xs={12}>
                    <Grid className="CategoryWrapper">
                         <img src={cat5} alt=""/>
                        <Link to='/shop'>Accessories</Link>
                    </Grid>
                </Grid>
                <Grid item lg={2} xs={12}>
                    <Grid className="CategoryWrapper">
                         <img src={cat6} alt=""/>
                        <Link to='/shop'>Shoes &Sandles</Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        
    )
};

export default CategoryArea;