import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

import icon1 from '../../../../images/user-account/sales.svg';
import icon2 from '../../../../images/user-account/visitors.svg';
import icon3 from '../../../../images/user-account/new_orders.svg';
import icon4 from '../../../../images/user-account/customers.svg';

import './style.scss';
import RevenueChart from "../components/RevenueChart";
import TotalOrders from "../components/TotalOrders";
import Switch from "@material-ui/core/Switch";


const orders = [
    {
        id: 1,
        code: '#5845',
        created_at: '01 jul 2020',
        customer_name: 'Jane Doe',
        amount: '$857',
        delivered_to_admin: 'Delivered',
        payment_status: 'Paid',
        delivery_status: 'Delivered'
    }, {
        id: 2,
        code: '#5845',
        created_at: '01 jul 2020',
        customer_name: 'Jane Doe',
        amount: '$857',
        delivered_to_admin: 'Delivered',
        payment_status: 'Pending',
        delivery_status: 'Delivered'
    }, {
        id: 3,
        code: '#5845',
        created_at: '01 jul 2020',
        customer_name: 'Jane Doe',
        amount: '$857',
        delivered_to_admin: 'Delivered',
        payment_status: 'Paid',
        delivery_status: 'Delivered'
    }, {
        id: 4,
        code: '#5845',
        created_at: '01 jul 2020',
        customer_name: 'Jane Doe',
        amount: '$857',
        delivered_to_admin: 'Delivered',
        payment_status: 'Pending',
        delivery_status: 'Delivered'
    },
];

const latest_orders = [
    {
        id: 1,
        image: '/products/4.jpg',
        title: 'Multicolor T-shirt',
        brand: 'Supper foods',
        status: 'Active'
    }, {
        id: 4,
        image: '/products/9.jpg',
        title: 'Fashion T-shirt',
        brand: 'Supper foods',
        status: 'Pending'
    }, {
        id: 3,
        image: '/products/5.jpg',
        title: 'Women Fashion T-shirt',
        brand: 'Supper foods',
        status: 'Active'
    },
];

const recent_review = [
    {
        id: 1,
        image: '/products/4.jpg',
        title: 'Multicolor T-shirt',
        comment: 'superb test',
        created_at: '02/02/2020',
        status: 'Active'
    }, {
        id: 4,
        image: '/products/9.jpg',
        title: 'Fashion T-shirt',
        comment: 'superb test',
        created_at: '02/02/2020',
        status: 'Pending'
    }, {
        id: 3,
        image: '/products/5.jpg',
        title: 'Women Fashion T-shirt',
        comment: 'superb test',
        created_at: '02/02/2020',
        status: 'Active'
    },
];

const Dashboard = (props) => {
    const { wishList } = props;
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | Vendor Dashboard</title>
            </Helmet>
            <Grid container spacing={3}>
                <Grid item md={3} sm={6} xs={6}>
                    <Grid className="featuredItem">
                        <Link to={props.match.path} />
                        <Grid className="icon">
                            <img src={icon1} alt="icon1" />
                        </Grid>
                        <Grid className="content">
                            <h4>Total Sales</h4>
                            <h2>1</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                    <Grid className="featuredItem">
                        <Link to={props.match.path} />
                        <Grid className="icon">
                            <img src={icon2} alt="icon2" />
                        </Grid>
                        <Grid className="content">
                            <h4>Visitors</h4>
                            <h2>0</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                    <Grid className="featuredItem">
                        <Link to={props.match.path} />
                        <Grid className="icon">
                            <img src={icon3} alt="icon3" />
                        </Grid>
                        <Grid className="content">
                            <h4>Total Orders</h4>
                            <h2>1</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                    <Grid className="featuredItem">
                        <Link to={props.match.path} />
                        <Grid className="icon">
                            <img src={icon4} alt="icon4" />
                        </Grid>
                        <Grid className="content">
                            <h4>Total Products</h4>
                            <h2>0</h2>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                    <Grid className="cardWrap mt-30">
                        <Grid className="cardTitle">
                            <h4 className="title">Revenue <span>This Year</span></h4>
                        </Grid>
                        <RevenueChart />
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Grid className="cardWrap mt-30">
                        <Grid className="cardTitle">
                            <h4 className="title">Total Orders <span>By Status</span></h4>
                        </Grid>
                        <TotalOrders />
                    </Grid>
                </Grid>
            </Grid>

            <Grid className="cardWrap mt-30">
                <Grid className="cardTitle">
                    <h4 className="title">Latest Orders</h4>
                    <Grid className="viewAll"><Link to="/">View All</Link></Grid>
                </Grid>
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Total Amount</TableCell>
                                <TableCell>Delivered To Admin</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell align="center">Delivery Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell><strong>{item.code}</strong></TableCell>
                                    <TableCell>{item.created_at}</TableCell>
                                    <TableCell>{item.customer_name}</TableCell>
                                    <TableCell>{item.amount}</TableCell>
                                    <TableCell>
                                        <Switch
                                            color="primary"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <span
                                            className={`badge ${item.payment_status.toLowerCase()}`}>{item.payment_status}</span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span
                                            className={`badge ${item.delivery_status.toLowerCase()}`}>{item.delivery_status}</span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Grid className="cardWrap mt-30">
                        <Grid className="cardTitle">
                            <h4 className="title">Latest Orders</h4>
                            <Grid className="viewAll"><Link to="/">View All</Link></Grid>
                        </Grid>
                        <Grid className="productLists">
                            {latest_orders.map(item => (
                                <Grid className="lProductItem" key={item.id}>
                                    <Grid className="icon">
                                        <img src={item.image} alt="product" />
                                    </Grid>
                                    <Grid className="content">
                                        <h2><Link to="/product/2">{item.title}</Link></h2>
                                        <p>{item.brand}</p>
                                    </Grid>
                                    <span className={`badge ${item.status}`}>{item.status}</span>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid className="cardWrap mt-30">
                        <Grid className="cardTitle">
                            <h4 className="title">Recent Review</h4>
                            <Grid className="viewAll"><Link to="/">View All</Link></Grid>
                        </Grid>
                        <Grid className="productLists">
                            {recent_review.map(item => (
                                <Grid className="lProductItem" key={item.id}>
                                    <Grid className="icon">
                                        <img src={item.image} alt="product" />
                                    </Grid>
                                    <Grid className="content">
                                        <h2><Link to="/product/2">{item.title}</Link> <span>{item.created_at}</span>
                                        </h2>
                                        <p>{item.comment}</p>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid className="cardWrap mt-30">
                <Grid className="cardTitle">
                    <h4 className="title">Latest Orders</h4>
                    <Grid className="viewAll"><Link to="/">View All</Link></Grid>
                </Grid>
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Image</TableCell>
                                <TableCell>Product Name</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Stock Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {wishList.length <= 0 ? <TableRow>
                                <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                            </TableRow> : <Fragment>
                                    {wishList.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Grid className="thumb"><img src={item.pictures[0]} alt="picture" /></Grid>
                                            </TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>${item.price}</TableCell>
                                            <TableCell>{item.stock}</TableCell>
                                        </TableRow>
                                    ))}
                                </Fragment>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        wishList: state.wishList.w_list
    }
};

export default connect(mapStateToProps)(Dashboard);