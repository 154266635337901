import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import FontAwesome from "../../../../components/UiStyle/FontAwesome";
import Switch from "@material-ui/core/Switch";

const products = [
    {
        id: 1,
        order_code: '1',
        created_at: 'Bank',
        name: 'Jane Doe',
        total_amount: 150,
    }, {
        id: 2,
        order_code: '2',
        created_at: 'Bank',
        name: 'Jane Doe',
        total_amount: 150,
    },
];

const PaymentSetting = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <Grid className="cardWrap mt-30">
            <Grid className="cardTitle">
                <h4 className="title">Latest Orders</h4>
                <Grid className="viewAll"><Link to="/">View All</Link></Grid>
            </Grid>
            <Grid className="defaultTable">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>	Method</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="right">Option</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.length <= 0 ? <TableRow>
                            <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                        </TableRow> : <Fragment>
                            {products.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.order_code}</TableCell>
                                    <TableCell>{item.created_at}</TableCell>
                                    <TableCell>
                                        <Switch
                                            color="primary"
                                            inputProps={{'aria-label': 'primary checkbox'}}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                            name="ellipsis-v"/></span>
                                        <Popover
                                            className="optionsMenu"
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <Link to='/vendor/banksettings'>Go To Bank Settings</Link>
                                        </Popover>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Fragment>}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
};

export default PaymentSetting;