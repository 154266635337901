import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Popover from "@material-ui/core/Popover";
import FontAwesome from "../../../../components/UiStyle/FontAwesome";
import Switch from "@material-ui/core/Switch";

const products = [
    {
        id: 1,
        name: 'mobile',
        date:'27 December, 2019',
        discount:'20',
        publication: true,
    }, {
        id: 2,
        name: '	Audio',
        date:'25 December, 2019',
        discount:'16',
        publication: true,
    }, {
        id: 3,
        name: 'Audio Speaker',
        date:'22 December, 2019',
        discount:'30',
        publication: true,
    }, {
        id: 4,
        name: '	Flash sale on this product',
        date:'20 December, 2019',
        discount:'18',
        publication: true,
    },{
        id: 5,
        name: 'Laptop A526403',
        date:'17 December, 2019',
        discount:'20',
        publication: true,
    },{
        id: 6,
        name: 'Graviola (soursop) - 100% Organic Powder',
        date:'15 December, 2019',
        discount:'25',
        publication: true,
    },{
        id: 7,
        name: 'new offer',
        date:'10 December, 2019',
        discount:'12',
        publication: true,
    },
];

const FlashSale = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div className="DataTableArea cardWrap">
            <div className="DtableHeaderArea">
                <h2 className="DtableHeader">All Flash Sale</h2>
                <Link  to='/admin/addflash' className="cBtn cBtnTheme">Add New Flash Sale</Link>
            </div>
            <Grid className="mt-30">
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Discoount (%)</TableCell>
                                <TableCell>Publication Status</TableCell>
                                <TableCell align="right">Option</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.length <= 0 ? <TableRow>
                                <TableCell colSpan="4"><p className="noProdFound">No Results Found</p></TableCell>
                            </TableRow> : <Fragment>
                                {products.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.date}</TableCell>
                                        <TableCell>{item.discount}</TableCell>
                                        <TableCell>
                                            <Switch
                                                color="primary"
                                                inputProps={{'aria-label': 'primary checkbox'}}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <span className="option" onClick={handlePopoverOpen}><FontAwesome
                                                name="ellipsis-v"/></span>
                                            <Popover
                                                className="optionsMenu"
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Link to='/admin/editflash'>Edit </Link>
                                                <Link to={props.match.path}>Delete </Link>
                                            </Popover>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>
    )
};

export default FlashSale;