import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import icon1 from '../../images/service/1.png';
import icon2 from '../../images/service/2.png';
import icon3 from '../../images/service/3.png';

import './style.scss';

const Service2 = () => {
    return (
        <Grid className="serviceWrapper2 pt-60 pb-60">
            <Grid container className="container" spacing={3}>
                <Grid item xs={12} className='ser-title'>
                    <h3>Why Choose <span>rCommerce</span> ?</h3>
                    <p>Etiam semper risus in sodales tempor. Donec et turpis justo duis fermentum.</p>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Grid className="svcItem">
                        <Grid className="icon">
                            <img src={icon1} alt="icon1" />
                        </Grid>
                        <Grid className="content">
                            <h2><Link to="/home2">Free Delivery</Link></h2>
                            <p>Lorem Ipsum is simply text of the printingand typesetting industry. Lorem Ipsum has been industry's standard dummy text</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Grid className="svcItem">
                        <Grid className="icon">
                            <img src={icon2} alt="icon2" />
                        </Grid>
                        <Grid className="content">
                            <h2><Link to="/home2">90 Days Return</Link></h2>
                            <p>Lorem Ipsum is simply text of the printingand typesetting industry. Lorem Ipsum has been industry's standard dummy text</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Grid className="svcItem">
                        <Grid className="icon">
                            <img src={icon3} alt="icon3" />
                        </Grid>
                        <Grid className="content">
                            <h2><Link to="/home2">24/7 Hour Support</Link></h2>
                            <p>Lorem Ipsum is simply text of the printingand typesetting industry. Lorem Ipsum has been industry's standard dummy text</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default Service2;