import React from 'react';
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";

function valuetext(value) {
    return `$${value}`;
}

const WidgetPrice = ({price, priceHandler}) => {
    return (
        <Grid className="widget widgetPrice">
            <h2 className="widgetTitle">Filter by Price</h2>
            <Slider
                className="priceSlider"
                value={price}
                onChange={priceHandler}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                valueLabelFormat={valuetext}
                min={10}
                max={200}
            />
            <p>Price: <strong>${price[0]} - ${price[1]}</strong></p>
        </Grid>
    )
};

export default WidgetPrice;