import React from 'react';
import {Grid} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import {Link} from "react-router-dom";

import GeneralChange from '../ProfileSetting/GeneralChange'
import PasswordSetting from '../ProfileSetting/PasswordSetting'

const ProfileSetting = (props) => {
  return (
      <div className="DataTableArea">
        <div className="DtableHeaderArea">
            <h2 className="DtableHeader">Profile Setting</h2>
        </div>

        <Grid container spacing={3}>
            <Grid item lg={6}>
                <Grid className="payment-card">
                  <Grid>
                     <h2 align="left">General change</h2>
                     <GeneralChange/>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item lg={6}>
                <Grid className="payment-card">
                  <Grid>
                     <h2 align="left">Password change</h2>
                     <PasswordSetting/>
                  </Grid>
                </Grid>
            </Grid>
        </Grid>

      </div>
    
  );
}

export default ProfileSetting;