import React from 'react';
import Slider from "react-slick";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import FontAwesome from "../UiStyle/FontAwesome";
import Button from "@material-ui/core/Button";

// images
import heroBg1 from '../../images/bg/hero-bg1.jpg';
import heroBg2 from '../../images/bg/hero-bg2.jpg';
import heroBg3 from '../../images/bg/hero-bg3.jpg';

import './style.scss';


function SampleNextArrow(props) {
    const {onClick} = props;
    return (
        <Button onClick={onClick} className="slick-arrow slick-next">
            <FontAwesome name="angle-right"/>
        </Button>
    );
}

function SamplePrevArrow(props) {
    const {onClick} = props;
    return (
        <Button onClick={onClick} className="slick-arrow slick-prev">
            <FontAwesome name="angle-left"/>
        </Button>
    );
}

const HeroArea = () => {
    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>
    };

    return (
        <Slider className="heroSlider" {...settings}>
            <div>
                <Grid className="heroItem" style={{background: `url(${heroBg1}) right center /cover no-repeat`}}>
                    <Grid className="container" container spacing={3} alignItems="center">
                        <Grid item md={7} xs={12}>
                            <Grid className="heroContent">
                                <h2>Trendy Collections</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt
                                    ut labore et dolore magna aliqua facilisis. </p>
                                <Link className="cBtn cBtnWhite cBtnRadius" to="/shop">SHOP NOW</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div>
                <Grid className="heroItem" style={{background: `url(${heroBg2}) right center /cover no-repeat`}}>
                    <Grid className="container" container spacing={3} alignItems="center">
                        <Grid item md={7} xs={12}>
                            <Grid className="heroContent">
                                <h2>New arrivals</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua facilisis. </p>
                                <Link className="cBtn cBtnWhite cBtnRadius" to="/shop">SHOP NOW</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div>
                <Grid className="heroItem" style={{background: `url(${heroBg3}) right center /cover no-repeat`}}>
                    <Grid className="container" container spacing={3} alignItems="center">
                        <Grid item md={7} xs={12}>
                            <Grid className="heroContent">
                                <h2>New arrivals</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua facilisis. </p>
                                <Link className="cBtn cBtnWhite cBtnRadius" to="/shop">SHOP NOW</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Slider>
    )
};

export default HeroArea;