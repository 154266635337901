import React, { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

const reviews = [
    {
        id: 1,
        code: '# 10009',
        title: '# 1040',
        rating: '	$ 11.65',
        comment: '	10(%)',
        income: '	$ 12.48',
        create: '	2020-02-17 07:54:55',
    }, {
        id: 2,
        code: '# 10007',
        title: '# 1035',
        rating: '	$ 9.25',
        comment: '	10(%)',
        income: '	$ 9.48',
        create: '	2020-02-17 07:54:55',
    }, {
        id: 3,
        code: '# 10006',
        title: '# 1030',
        rating: '	$ 14.65',
        comment: '	11(%)',
        income: '	$ 12.48',
        create: '	2020-02-17 07:54:55',
    }, {
        id: 4,
        code: '# 10005',
        title: '# 1025',
        rating: '	$ 18.20',
        comment: '	19(%)',
        income: '	$ 81.48',
        create: '	2020-02-17 07:54:55',
    }, {
        id: 5,
        code: '# 10004',
        title: '# 1010',
        rating: '	$ 20.35',
        comment: '	7(%)',
        income: '	$ 15.48',
        create: '	2020-02-17 07:54:55',
    },
];

const IncomeHistory = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>rCommerce | My Reviews</title>
            </Helmet>
            <Grid className="cardWrap">
                <Grid className="cardTitle">
                    <h4 className="title">Income History</h4>
                </Grid>
                <Grid className="defaultTable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Income History Code</TableCell>
                                <TableCell>Order Code</TableCell>
                                <TableCell>Order Total</TableCell>
                                <TableCell>Company Commision</TableCell>
                                <TableCell>Income</TableCell>
                                <TableCell align="right">Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reviews.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell><strong>{item.code}</strong></TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.rating}</TableCell>
                                    <TableCell>{item.comment}</TableCell>
                                    <TableCell>{item.income}</TableCell>
                                    <TableCell align="right">{item.create}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default IncomeHistory;