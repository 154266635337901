import React, {useState} from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import List from "@material-ui/core/List";
import FontAwesome from "../UiStyle/FontAwesome";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {isWishListed} from "../../utils";



import './style.scss';

const ProductQuickView = ({item, handleClose, open, addToWishList, addToCart, isWishListed}) => {

    const [preview, setPreview] = useState(0);
    const [size, setSize] = useState(0);
    const [qty, setQty] = useState(1);

    let ratingBack = [];
    let ratingFront = [];
    let i = 0;

    for (i = 0; i < 5; i++) {
        ratingBack.push(<li key={i}><i className="fa fa-star-o"/></li>)
    }
    for (i = 0; i < item.rating; i++) {
        ratingFront.push(<li key={i}><i className="fa fa-star"/></li>)
    }


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="qcvModal"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Grid className="quickViewModal">
                    <span onClick={handleClose} className="closeModal"><FontAwesome name="times"/></span>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            
                            <Grid className="qcvPreview">
                            <Link to={`/${item.id}`}><img src={item.pictures[preview]} alt="preview"/></Link>
                                
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Grid className="qcvContent">
                                <h2>{item.name}</h2>
                                <Grid className="pro_rating">
                                    <List className="ratingFront">
                                        {ratingFront}
                                    </List>
                                    <List className="ratingBack">
                                        {ratingBack}
                                    </List>

                                    <h5>{item.rating} Review</h5>
                                </Grid>
                                <p className="info">{item.shortDetails}</p>

                                <Grid className="selectColor">
                                    <p className="qcvLabel">Select color :</p>
                                    <List>
                                        {item.variants.map((item, i) => {
                                            return (
                                                <ListItem key={i} onClick={() => setPreview(i)}
                                                          className={`${item.color} ${i === preview ? 'active' : ''}`}/>
                                            )
                                        })}
                                    </List>
                                </Grid>

                                <Grid className="selectSize">
                                    <p className="qcvLabel">Select size :</p>
                                    <List>
                                        {item.size.map((item, i) => {
                                            return (
                                                <ListItem key={i} onClick={() => setSize(i)}
                                                          className={size === i ? 'active' : ''}>{item}</ListItem>
                                            )
                                        })}
                                    </List>
                                </Grid>

                                <Grid className="selectQty">
                                    <p className="qcvLabel">Select Qty :</p>
                                    <Button onClick={() => setQty(qty <= 1 ? 1 : qty - 1)}>-</Button>
                                    <input
                                        value={qty}
                                        onChange={() => setQty(qty)}
                                        type="text"/>
                                    <Button onClick={() => setQty(qty + 1)}>+</Button>
                                </Grid>


                                <div className="qcvFtBtn">
                                    <Button className="cBtn cBtnSquare cBtnBlack"
                                            onClick={() => addToCart(item, qty, item.colors[preview], item.size[size])}>Add
                                        to cart</Button>
                                    <Button className="cBtn cBtnSquare cBtnBlack" onClick={() => addToWishList(item)}>
                                        <FontAwesome name={isWishListed ? 'heart' : 'heart-o'}/>
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        </Modal>
    )
};

export default ProductQuickView;