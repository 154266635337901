import React from 'react';
import FontAwesome from "../UiStyle/FontAwesome";
import Grid from "@material-ui/core/Grid";

import './style.scss';

const ScrollToTop = () => {
    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <Grid className="scrollToTop" onClick={scrollTop}>
            <FontAwesome name="angle-up"/>
        </Grid>
    )
};

export default ScrollToTop;