import React, { Fragment } from 'react';
import { Button, Dialog, Grid, } from '@material-ui/core'

const DefaultModal = ({ maxWidth, button, buttonClass }) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    const submitHandler = (e) => {
        e.preventDefault();
    };
    return (
        <Fragment>
            <form onSubmit={submitHandler}>
                <Button align="right"
                    className={`btn ${buttonClass}`}
                    onClick={handleClickOpen}>
                    {button}
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    className="modalWrapper"
                    maxWidth={maxWidth}
                >
                    <Grid className="modalTitle">
                        <h3>Make Payment Done</h3>
                    </Grid>
                    <Grid className="modalBody">
                        <p>Are you sure? If you confirm you can't revert it.</p>
                    </Grid>
                    <Grid className="modalFooter">
                        <Button className="bg-cancel" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button className="bg-default bg-default-2" onClick={handleClose}>
                            Done
                        </Button>
                    </Grid>
                </Dialog>
            </form>
            
        </Fragment>
    );
}
export default DefaultModal