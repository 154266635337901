import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";

import icon1 from '../../images/icon/fast-deliver.png';
import icon2 from '../../images/icon/money-return.png';
import icon3 from '../../images/icon/support.png';

import './style.scss';

const Service = ({className = ''}) => {
    return (
        <Grid className={`serviceWrapper ptb-30 ${className}`}>
            <Grid container className="container" spacing={3}>
                <Grid item md={4} sm={6} xs={12}>
                    <Grid className="svcItem">
                        <Grid className="icon">
                            <img src={icon1} alt="icon1"/>
                        </Grid>
                        <Grid className="content">
                            <h2><Link to="/">Free & Fast Delivery</Link></h2>
                            <p>There are many variations of available, but the majority have suffered.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Grid className="svcItem">
                        <Grid className="icon">
                            <img src={icon2} alt="icon2"/>
                        </Grid>
                        <Grid className="content">
                            <h2><Link to="/">Within 15 Days Return</Link></h2>
                            <p>There are many variations of available, but the majority have suffered.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Grid className="svcItem">
                        <Grid className="icon">
                            <img src={icon3} alt="icon3"/>
                        </Grid>
                        <Grid className="content">
                            <h2><Link to="/">24/7 support Available</Link></h2>
                            <p>There are many variations of available, but the majority have suffered.</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default Service;